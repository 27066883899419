import React from 'react';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router';
import Button from '../ui/form/Button';
import Page, { PageTitle } from '../ui/Page';
import List from '../team/List';
import Create from '../team/Create';

export default function Teams() {
  const match = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  return (
    <Page title={'Teams'}>
      <PageTitle>
        {location.pathname === '/teams' && (
          <Button
            label="New Team +"
            onClick={() => {
              history.push('/teams/create');
            }}
          />
        )}
      </PageTitle>
      <Switch>
        <Route path={`${match.path}/create`}>
          <Create />
        </Route>
        <Route path={`${match.path}/:userid`}></Route>
        <Route path={match.path}>
          <List />
        </Route>
      </Switch>
    </Page>
  );
}
