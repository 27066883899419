import React from 'react';
import styled from 'styled-components';
import HyperLogo from '../../images/HyperLogo.png';
const Main = styled.div`
  border-bottom: 1px solid #e1e5eb !important;
  height: 3.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  height: 20px;
`;
function NavigationLogo() {
  return (
    <Main>
      <Logo src={HyperLogo} alt="Hyper Logo" />
    </Main>
  );
}

export default NavigationLogo;
