import React from 'react';
import styled from 'styled-components';

const Main = styled.div`
  display: flex;
  padding-left: 2em;
  background-color: #fff;
  width: 100%;
  margin-left: -15px;
  border-bottom: 1px solid #d9e9f9;
`;
export default function NavigationBar({ children }) {
  return <Main>{children}</Main>;
}
