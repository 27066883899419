import { useLazyQuery, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ALL_PROJECTS, ENGINES_BY_USERS, USERS } from '../../api/GraphQL';
import Panel from '../ui/Panel';
import Row from '../ui/Row';
import Table from '../ui/Table';
import Button from '../ui/form/Button';
import Column from '../ui/Column';
import moment from 'moment';

function List() {
  const { loading, data } = useQuery(USERS);
  const [getEngines, { data: engines }] = useLazyQuery(ENGINES_BY_USERS);
  const [getProjects, { data: projects }] = useLazyQuery(ALL_PROJECTS);
  const history = useHistory();
  const [showDisabled, setShowDisabled] = useState(false);

  useEffect(() => {
    if (data) {
      getEngines({ variables: { ids: data.users.map((u) => u._id) } });
    }
  }, [data, getEngines]);

  useEffect(() => {
    if (data) {
      getProjects();
    }
  }, [data, getProjects]);

  return (
    <Row>
      <Column>
        <Row>
          Show disabled accounts
          <Button
            primary={showDisabled}
            onClick={() => {
              setShowDisabled(true);
            }}
            label="Yes"
          />
          <Button
            primary={!showDisabled}
            onClick={() => {
              setShowDisabled(false);
            }}
            label="No"
          />
        </Row>
        <Row>
          <Panel title={'User list'}>
            {loading && <div>Loading</div>}
            {!loading && data && (
              <Table
                data={data.users?.filter(
                  (user) => user.disabled === showDisabled
                )}
                columns={[
                  { Header: 'ID', accessor: '_id' },
                  { Header: 'Username', accessor: 'username' },
                  { Header: 'Email', accessor: 'email' },
                  { Header: 'License', accessor: 'license_type' },
                  {
                    Header: 'Roles',
                    accessor: (data) => {
                      let output = [];
                      data.roles.forEach((item) => {
                        output.push(item.name);
                      });
                      return output.join(', ');
                    },
                  },
                  {
                    Header: 'Engines',
                    accessor: (data) => {
                      return (
                        engines?.enginesByUsers?.filter(
                          (e) => e.user._id === data._id
                        ).length || ''
                      );
                    },
                  },
                  {
                    Header: 'Projects',
                    accessor: (data) => {
                      return (
                        projects?.projects?.filter(
                          (e) => e.user._id === data._id
                        ).length || ''
                      );
                    },
                  },
                  {
                    Header: 'Last login',
                    accessor: (data) => {
                      if (data?.last_login) {
                        debugger;
                        return moment(parseInt(data.last_login))?.format(
                          'MMM D, YYYY'
                        );
                      }
                      return '';
                    },
                  },
                ]}
                onRowSelect={(item) => {
                  history.push('/users/' + item._id);
                }}
              />
            )}
          </Panel>
        </Row>
      </Column>
    </Row>
  );
}

export default List;
