import React from 'react';
import styled from 'styled-components';
import NavigationItem from './NavigationItem';
import NavigationLogo from './NavigationLogo';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 315px;
  width: 315px;
  min-height: 100vh;
  top: 0;
  position: fixed;
  height: 100vh;
  background: #fff;
  z-index: 1070;
  box-shadow: 0 0.125rem 1rem rgb(90 97 105 / 10%),
    0 0.25rem 0.5rem rgb(90 97 105 / 12%), 0 0.9375rem 0rem rgb(90 97 105 / 10%),
    0 0.4375rem 2.1875rem rgb(165 182 201 / 10%);
`;

const UL = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  flex-direction: column;
`;
function Navigation() {
  return (
    <Main>
      <NavigationLogo />
      <UL>
        <NavigationItem title={'Dashboard'} />
        <NavigationItem title={'Users'} />
        <NavigationItem title={'Teams'} />
      </UL>
    </Main>
  );
}

export default Navigation;
