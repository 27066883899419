import React, { useEffect, useState } from 'react';
import Column from '../../ui/Column';
import ProgressBar from '../../ui/ProgressBar';
import Row from '../../ui/Row';
import Table from '../../ui/Table';
import styled from 'styled-components';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
`;

export default function Storage({ user }) {
  const [data, setData] = useState([]);

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  useEffect(() => {
    fetch(
      (window.ENV?.REACT_APP_PROJECT_SERVER ||
        process.env.REACT_APP_PROJECT_SERVER) +
        '/api/assets/images/' +
        user._id
    )
      .then((res) => res.json())
      .then((data) => {
        setData(data);
        fetch(
          (window.ENV?.REACT_APP_PROJECT_SERVER ||
            process.env.REACT_APP_PROJECT_SERVER) +
            '/api/assets/fonts/' +
            user._id
        )
          .then((res) => res.json())
          .then((fonts) => {
            setData([...data, ...fonts]);
            fetch(
              (window.ENV?.REACT_APP_PROJECT_SERVER ||
                process.env.REACT_APP_PROJECT_SERVER) +
                '/api/assets/videos/' +
                user._id
            )
              .then((res) => res.json())
              .then((videos) => {
                setData([...data, ...videos]);
              });
          });
      })
      .catch((err) => {
        debugger;
      });
  }, [user]);

  let total = data?.reduce((previous, current) => {
    return parseFloat(previous) + parseFloat(current.size);
  }, 0);
  return (
    <Main>
      <Row>
        <Column size="12">
          <Row>
            <Column size="2">
              <ProgressBar percent={(total / (1024 * 1024 * 1024)) * 100} />
            </Column>
            {formatBytes(total)} / {formatBytes(1024 * 1024 * 1024)}
          </Row>
        </Column>
      </Row>
      {data && (
        <Table
          data={data}
          columns={[
            { Header: 'Name', accessor: 'name' },
            {
              Header: 'Size',
              accessor: (data) => {
                return formatBytes(data.size);
              },
            },
          ]}
        />
      )}
    </Main>
  );
}
