import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { ADD_TEAM, USERS } from '../../api/GraphQL';
import Column from '../ui/Column';
import Button from '../ui/form/Button';
import Input from '../ui/form/Input';
import Panel from '../ui/Panel';
import Row from '../ui/Row';

function Create() {
  const history = useHistory();
  const { data: users } = useQuery(USERS);
  const [createTeam, { data, loading, error }] = useMutation(ADD_TEAM, {
    onCompleted: () => {
      history.push('/teams');
    },
    refetchQueries: ['GetTeams'],
  });
  const [name, setName] = useState('');
  const [owner, setOwner] = useState('');
  const [members, setMembers] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  return (
    <Row>
      <Panel title={'Create Team'} border_title={true}>
        <Column size="12">
          <Row>
            <Column size="4">
              <Input
                id="new_name"
                label={'Name'}
                placeholder={'Team Name'}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Column>
          </Row>
          <Row>
            <Column size="4">
              <select
                value={owner}
                onChange={(e) => {
                  setOwner(e.currentTarget.value);
                }}
              >
                {users?.users?.map((user) => {
                  return <option value={user?._id}>{user.username}</option>;
                })}
              </select>
            </Column>
          </Row>
          <Row>
            <Column size="4">
              <select
                value={selectedUser}
                onChange={(e) => {
                  setSelectedUser(e.currentTarget.value);
                }}
              >
                {users?.users
                  ?.filter((u) => members?.findIndex((m) => m === u._id) === -1)
                  .map((user) => {
                    return <option value={user?._id}>{user.username}</option>;
                  })}
              </select>
              <Button
                label="Add to team"
                onClick={() => {
                  setMembers([...members, selectedUser]);
                }}
              />
            </Column>
          </Row>
          <Row>
            {members.map((member) => {
              return <div>{member}</div>;
            })}
          </Row>
          <Row>
            <Column size={12}>
              <Button
                primary
                label={'Create New Team'}
                onClick={() => {
                  createTeam({
                    variables: {
                      name,
                      owner,
                      members,
                    },
                  });
                }}
              />
            </Column>
          </Row>
        </Column>
      </Panel>
    </Row>
  );
}

export default Create;
