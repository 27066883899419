import React, { useContext } from 'react';
import styled from 'styled-components';
import { UserContext } from '../../contexts/UserContext';
import Button from '../ui/form/Button';

const Main = styled.div`
  background-color: #fff;
  width: 100%;
  box-shadow: 0 0.125rem 0.625rem rgb(90 97 105 / 12%);
  position: sticky;
  top: 0;
  margin-right: auto;
  margin-left: auto;
  z-index: 1020;
  height: 3.75rem;
  justify-content: space-between;
  align-items: center;
  display: flex;
`;

const Form = styled.form`
  min-width: 7.5rem;
`;
const Profile = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 2em;
  align-items: center;
`;

const Name = styled.div`
  margin-right: 0.5em;
  color: #868e96;
  font-size: 0.8em;
`;
function Header() {
  const { user, signOut } = useContext(UserContext);

  return (
    <Main>
      <Form />
      <Profile>
        <Name>{user?.username}</Name>
        <Button
          onClick={() => {
            signOut();
          }}
          label="Sign Out"
        />
      </Profile>
    </Main>
  );
}

export default Header;
