import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import Header from './components/header/Header';
import Navigation from './components/navigation/Navigation';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Users from './components/views/Users';
import Teams from './components/views/Teams';
import Login from './components/Authentication/Login';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  gql,
  useQuery,
} from '@apollo/client';

import { setContext } from '@apollo/client/link/context';
import { UserContext, UserProvider } from './contexts/UserContext';

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : '',
    },
  };
});

const httpLink = createHttpLink({
  uri:
    (window.ENV?.REACT_APP_PROJECT_SERVER ||
      process.env.REACT_APP_PROJECT_SERVER) + '/graphql',
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

const LOGGED_IN = gql`
  query LoggedIn {
    me {
      username
      token
      features {
        name
      }
    }
  }
`;

const Main = styled.div`
  display: flex;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 315px;
  width: 100%;
`;
function App() {
  return (
    <ApolloProvider client={client}>
      <Router basename={process.env.PUBLIC_URL}>
        <UserProvider>
          <Routes />
        </UserProvider>
      </Router>
    </ApolloProvider>
  );
}

function Routes() {
  const { setUserToken } = useContext(UserContext);
  const {
    loading,
    error,
    data,
    refetch: refetchLogin,
  } = useQuery(LOGGED_IN, {
    fetchPolicy: 'network-first',
    client: client,
  });

  useEffect(() => {
    if (data) {
      setUserToken(data?.me?.token);
    }
  }, [data]);

  return (
    <>
      {!data?.me?.token && (
        <Login
          loggedIn={() => {
            refetchLogin();
          }}
        />
      )}
      {data?.me?.token && (
        <Main>
          <Navigation />
          <Content>
            <Header></Header>
            <Switch>
              <Route path="/users">
                <Users />
              </Route>
              <Route path="/teams">
                <Teams />
              </Route>
            </Switch>
          </Content>
        </Main>
      )}
    </>
  );
}
export default App;
