import { useLazyQuery, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { ENGINES_BY_USERS, TEAMS } from '../../api/GraphQL';
import Panel from '../ui/Panel';
import Row from '../ui/Row';
import Table from '../ui/Table';

function List() {
  const { loading, data } = useQuery(TEAMS);
  const history = useHistory();

  return (
    <Row>
      <Panel title={'Team list'}>
        {loading && <div>Loading</div>}
        {!loading && data && (
          <Table
            data={data.teams}
            columns={[
              { Header: 'ID', accessor: '_id' },
              { Header: 'Name', accessor: 'name' },
              {
                Header: 'Owner',
                accessor: (item) => {
                  return item.owner?.username;
                },
              },
            ]}
            onRowSelect={(item) => {
              history.push('/teams/' + item._id);
            }}
          />
        )}
      </Panel>
    </Row>
  );
}

export default List;
