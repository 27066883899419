import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import styled from 'styled-components';

import Column from '../../ui/Column';
import ButtonGroup from '../../ui/form/ButtonGroup';
import Row from '../../ui/Row';
import { FEATURES, UPDATE_USER } from '../../../api/GraphQL';

const Main = styled.div`
  display: flex;
  background-color: #fff;
  width: 100%;
  height: 100%;
  margin-left: -15px;
  padding: 15px;
  flex-direction: column;
`;

export default function Features({ user }) {
  const { data } = useQuery(FEATURES);
  const [updateUser, { data: updateData }] = useMutation(UPDATE_USER, {
    refetchQueries: ['GetUsers', 'GetUser'],
  });

  return (
    <Main>
      {data?.features?.map((feature) => {
        return (
          <Row bottom_border>
            <Column size="4">{feature.name}</Column>
            <Column size="4">
              <Row>
                <ButtonGroup
                  label="Enabled"
                  value={
                    user?.features?.findIndex(
                      (f) => f?.name === feature?.name
                    ) > -1
                  }
                  items={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                  onChange={(value) => {
                    let new_features = [...(user?.features || [])];
                    new_features = new_features?.filter(
                      (f) => f._id !== feature._id
                    );
                    if (value) {
                      new_features.push(feature);
                    }
                    debugger;
                    updateUser({
                      variables: {
                        _id: user._id,
                        features: new_features,
                      },
                    });
                  }}
                />
              </Row>
            </Column>
          </Row>
        );
      })}
    </Main>
  );
}
