import React from 'react';
import styled from 'styled-components';
const ButtonElement = styled.button`
  width: fit-content;
  color: #8f8f8f;
  box-shadow: none;
  font-weight: 400;
  border: 1px solid transparent;
  border-color: #c0c0c0;
  padding: 0.5625rem 1rem;
  font-size: 0.75rem;
  line-height: 1.125;
  border-radius: 0.25rem;
  transition: all 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
  cursor: pointer;
  :hover {
    color: #fff;
    background-color: #006fe6;
    border-color: #006fe6;
    box-shadow: 0 5px 15px rgb(0 0 0 / 5%), 0 4px 10px rgb(0 123 255 / 25%);
    border: 1px solid transparent;
  }
`;

const PrimaryButton = styled(ButtonElement)`
  background-color: #007bff;
  color: #fff;
  border: 1px solid transparent;
  :hover {
    color: #fff;
    background-color: #006fe6;
    border-color: #006fe6;
    box-shadow: 0 5px 15px rgb(0 0 0 / 5%), 0 4px 10px rgb(0 123 255 / 25%);
  }
`;
export default function Button({ label = 'Button', onClick, primary, style }) {
  return (
    <>
      {primary && (
        <PrimaryButton onClick={onClick} style={style}>
          {label}
        </PrimaryButton>
      )}
      {!primary && (
        <ButtonElement onClick={onClick} style={style}>
          {label}
        </ButtonElement>
      )}
    </>
  );
}
