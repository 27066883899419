const data = {
    "project": {
        "_id": "6182817751b7a0dd4843297a",
        "name": "MAN CITY",
        "user": "6182817751b7a0dd48432978",
        "colour": "#ffffff",
        "__v": 0
    },
    "scenes": [
        {
            "_id": "61828976a4737fae26880f90",
            "name": "FF_MatchIdent",
            "project": "6182817751b7a0dd4843297a",
            "__v": 0,
            "data": {
                "name": "FF_MatchIdent",
                "football": {
                    "templates": [
                        {
                            "name": "MatchID"
                        }
                    ]
                },
                "width": 1920,
                "height": 1080,
                "folder": "UNIVERSAL",
                "aspect": "16x9",
                "timelines": [
                    {
                        "name": "IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": [
                                    {
                                        "property": "TIMELINE",
                                        "keyframes": [
                                            {
                                                "frame": 2,
                                                "name": "LOOP",
                                                "action": "PLAY"
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "1987f1c3-edbc-42d4-9122-4a0202129268",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1850,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 25,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.386978759765625,
                                                    1.00107666015625
                                                ],
                                                "type": "ease-out",
                                                "time": 500
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "6f40f6fd-5592-4d81-b321-fd9443350065",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 478,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 22,
                                                "value": 478,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 440
                                            },
                                            {
                                                "frame": 45,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.446978759765625,
                                                    1.07107666015625
                                                ],
                                                "type": "ease-out",
                                                "time": 900
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "e892c063-49e7-41a0-b8c8-3dc70bca1b67",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -466,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 22,
                                                "value": -466,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 440
                                            },
                                            {
                                                "frame": 45,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.376978759765625,
                                                    1.07107666015625
                                                ],
                                                "type": "ease-out",
                                                "time": 900
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "ba9c3f7d-6f7a-4cb5-acc7-7fc1043b539b",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 251,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 22,
                                                "value": 251,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 440
                                            },
                                            {
                                                "frame": 45,
                                                "value": 951,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.44703125,
                                                    0.9671875
                                                ],
                                                "type": "ease-out",
                                                "time": 900
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "0aecd03b-562b-4e3a-946b-2c7f95d35802",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1582,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 22,
                                                "value": 1582,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 440
                                            },
                                            {
                                                "frame": 45,
                                                "value": 924,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.35703125,
                                                    0.9671875
                                                ],
                                                "type": "ease-out",
                                                "time": 900
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "1055199f-f33f-4f09-95ff-db0e24c3c80b",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 28,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 560
                                            },
                                            {
                                                "frame": 45,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 900
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "02955ae7-3f01-40b9-bba1-5a7fee98fa5a",
                                "animations": [
                                    {
                                        "property": "TRIGGER",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": "play"
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "11acf033-78d1-4f11-aaec-d23d18c713b9",
                                "animations": [
                                    {
                                        "property": "TRIGGER",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": "play"
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 50,
                        "loop": false,
                        "fps": 50,
                        "id": "6d692671-a346-488f-bbb1-dd178f7ec038"
                    },
                    {
                        "name": "OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": [
                                    {
                                        "property": "TIMELINE",
                                        "keyframes": [
                                            {
                                                "frame": 25,
                                                "name": "LOOP",
                                                "action": "STOP"
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "6f40f6fd-5592-4d81-b321-fd9443350065",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 19,
                                                "value": 466,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.876978759765625,
                                                    0.07107666015625003
                                                ],
                                                "type": "ease-out",
                                                "time": 380
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "e892c063-49e7-41a0-b8c8-3dc70bca1b67",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 19,
                                                "value": -464,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.766978759765625,
                                                    0.11107666015624995
                                                ],
                                                "type": "ease-out",
                                                "time": 380
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "1987f1c3-edbc-42d4-9122-4a0202129268",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            },
                                            {
                                                "frame": 30,
                                                "value": 1549,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.846978759765625,
                                                    0.07107666015625003
                                                ],
                                                "type": "ease-out",
                                                "time": 600
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 30,
                        "loop": false,
                        "fps": 50,
                        "id": "02aeab0b-f1ea-476d-9993-451bd1b8700d"
                    },
                    {
                        "name": "LOOP",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -48,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 200,
                                                "value": 45,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 4000
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "b119725a-d468-4e03-a5c0-cd3959fb23cf",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -93,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 200,
                                                "value": 23,
                                                "curve": [
                                                    0.42,
                                                    0,
                                                    0.58,
                                                    1
                                                ],
                                                "type": "ease-in-out",
                                                "time": 4000
                                            },
                                            {
                                                "frame": 400,
                                                "value": -93,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 8000
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "6a5f4d1a-f3fe-4f82-bd49-12f48bdad7bf",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 52,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 225,
                                                "value": -11,
                                                "curve": [
                                                    0.42,
                                                    0,
                                                    0.58,
                                                    1
                                                ],
                                                "type": "ease-in-out",
                                                "time": 4500
                                            },
                                            {
                                                "frame": 400,
                                                "value": 52,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 8000
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 400,
                        "loop": true,
                        "fps": 50,
                        "id": "de1dcb41-990b-480f-b3ee-b1c93bfc3974"
                    }
                ],
                "scene": {
                    "children": [
                        {
                            "children": [
                                {
                                    "children": [],
                                    "uuid": "b4015c0b-1022-49da-a685-a551b1c08cc7",
                                    "type": "IMAGE",
                                    "name": "REF",
                                    "tint": 16777215,
                                    "x": "0",
                                    "y": "0",
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1926,
                                    "height": 1075.3675027262805,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchor": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1.0501635768811342,
                                        "y": 1.0501635768811333
                                    },
                                    "blendMode": 0,
                                    "mask": null,
                                    "visible": false,
                                    "src": "Screenshot_2021-09-08_at_14.48.28.png"
                                },
                                {
                                    "children": [
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "3cf365da-bdbe-4575-b3d1-f172e99f2454",
                                                    "type": "RECTANGLE",
                                                    "name": "Stroke_Mask",
                                                    "x": 580,
                                                    "y": 97,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 984,
                                                    "height": 312,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 984,
                                                        "height": 312,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 14355009,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "8b737f22-cbc6-438e-838b-b3b45cd65e50",
                                                    "type": "IMAGE",
                                                    "name": "Stroke",
                                                    "tint": 16777215,
                                                    "x": 637,
                                                    "y": 212,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 755.7072829131627,
                                                    "height": 565,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.7913165266106416,
                                                        "y": 0.7913165266106442
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "3cf365da-bdbe-4575-b3d1-f172e99f2454",
                                                    "visible": true,
                                                    "src": "LargeSTROKE2.png"
                                                }
                                            ],
                                            "uuid": "b119725a-d468-4e03-a5c0-cd3959fb23cf",
                                            "type": "GROUP",
                                            "name": "Stroke_GRP",
                                            "x": 17.76103844408398,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 755.707275390625,
                                            "height": 197,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "0ec858fc-3657-4d47-859c-169e05bc1baa",
                                                    "type": "RECTANGLE",
                                                    "name": "Grad_MASK",
                                                    "x": 336,
                                                    "y": 21,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 1134,
                                                    "height": 798,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 1134,
                                                        "height": 798,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 14355009,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "2a3ad2cc-ee1e-40b8-b9fc-1298a5170740",
                                                    "type": "IMAGE",
                                                    "name": "Grad",
                                                    "tint": 16777215,
                                                    "x": 455,
                                                    "y": 309,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 798.7838479809974,
                                                    "height": 678,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.8052256532066506,
                                                        "y": 0.8052256532066508
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "0ec858fc-3657-4d47-859c-169e05bc1baa",
                                                    "visible": true,
                                                    "src": "GradientBlock.png"
                                                }
                                            ],
                                            "uuid": "6a5f4d1a-f3fe-4f82-bd49-12f48bdad7bf",
                                            "type": "GROUP",
                                            "name": "Grad_GRP",
                                            "x": -3.178400580911628,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 798.7838134765625,
                                            "height": 510,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "faa703cc-d004-4865-907c-f81c5df6a056",
                                                    "type": "RECTANGLE",
                                                    "name": "AwayFlagMASK",
                                                    "x": 1324,
                                                    "y": 331,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 448,
                                                    "height": 415,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 448,
                                                        "height": 415,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 7122399,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.266,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "2ae7f21b-ae82-42b3-83c3-03db1392de06",
                                                    "type": "IMAGE",
                                                    "name": "Away Flag",
                                                    "tint": 16777215,
                                                    "x": 1141,
                                                    "y": 187,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 670,
                                                    "height": 670,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.6203703703703703,
                                                        "y": 0.6203703703703703
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "faa703cc-d004-4865-907c-f81c5df6a056",
                                                    "visible": false,
                                                    "src": "LeicesterCityFlag.png"
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "02955ae7-3f01-40b9-bba1-5a7fee98fa5a",
                                                    "type": "VIDEO",
                                                    "name": "AWAY VIDEO",
                                                    "x": 923,
                                                    "y": 0,
                                                    "control": {
                                                        "id": "999",
                                                        "name": "AWAY VIDEO BADGE",
                                                        "editables": [],
                                                        "data": {
                                                            "variable": "Away Team Video Badge",
                                                            "variables": [
                                                                {
                                                                    "template": "MatchID",
                                                                    "field": "Away Team Video Badge"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 1080,
                                                    "height": 1080,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1080,
                                                        "y": 1080
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "faa703cc-d004-4865-907c-f81c5df6a056",
                                                    "visible": true,
                                                    "loop": true,
                                                    "src": "https://mancity.hyper.live/project/api/assets/videos/6182817751b7a0dd48432978/LEICESTER_SQUARE.mp4"
                                                }
                                            ],
                                            "uuid": "e892c063-49e7-41a0-b8c8-3dc70bca1b67",
                                            "type": "GROUP",
                                            "name": "AwayFlag_GRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 557.0928052569975,
                                            "height": 400.4044952809081,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "474e32a8-96fd-478f-8505-2eb31d7fa66e",
                                                    "type": "IMAGE",
                                                    "name": "Home Flag",
                                                    "tint": 16777215,
                                                    "x": 36,
                                                    "y": 187,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 670,
                                                    "height": 670,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.6203703703703703,
                                                        "y": 0.6203703703703703
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "dc08bed5-3e7e-4c45-acf2-bc75bb946ecd",
                                                    "visible": false,
                                                    "src": "ManCityFlag.png"
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "dc08bed5-3e7e-4c45-acf2-bc75bb946ecd",
                                                    "type": "RECTANGLE",
                                                    "name": "HomeFlagMASK",
                                                    "x": 217,
                                                    "y": 331,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 448,
                                                    "height": 415,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 448,
                                                        "height": 415,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 7122399,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.266,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "11acf033-78d1-4f11-aaec-d23d18c713b9",
                                                    "type": "VIDEO",
                                                    "name": "HOME VIDEO",
                                                    "x": -137,
                                                    "y": 0,
                                                    "control": {
                                                        "id": "9999",
                                                        "name": "HOME VIDEO BADGE",
                                                        "editables": [],
                                                        "data": {
                                                            "variable": "Home Team Video Badge",
                                                            "variables": [
                                                                {
                                                                    "template": "MatchID",
                                                                    "field": "Home Team Video Badge"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 1080,
                                                    "height": 1080,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "dc08bed5-3e7e-4c45-acf2-bc75bb946ecd",
                                                    "visible": true,
                                                    "loop": true,
                                                    "src": "https://mancity.hyper.live/project/api/assets/videos/6182817751b7a0dd48432978/CITY_SQUARE.mp4"
                                                }
                                            ],
                                            "uuid": "6f40f6fd-5592-4d81-b321-fd9443350065",
                                            "type": "GROUP",
                                            "name": "HomeFlag_GRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 557.0928052569975,
                                            "height": 400.4044952809081,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "8e79e192-bd64-44f2-ada9-faf259e4a628",
                                            "type": "RECTANGLE",
                                            "name": "LightBlueBG",
                                            "x": 676,
                                            "y": 285,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 660,
                                            "height": 515,
                                            "alpha": 1,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 660,
                                                "height": 515,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 7122399,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.266,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "345de8f2-fe0b-4ebf-872f-324039d39a98",
                                            "type": "RECTANGLE",
                                            "name": "DarkBlueBG",
                                            "x": 703,
                                            "y": 331,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 574,
                                            "height": 83,
                                            "alpha": 1,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 574,
                                                "height": 83,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 10334,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.266,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "99227738-1ad1-402d-8d07-ce40fe6be4f5",
                                                    "type": "TEXT",
                                                    "name": "Competition",
                                                    "x": 979,
                                                    "y": 350,
                                                    "control": {
                                                        "id": "1",
                                                        "name": "Competition",
                                                        "data": {
                                                            "variable": "Competition",
                                                            "variables": [
                                                                {
                                                                    "template": "MatchID",
                                                                    "field": "Competition"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 330,
                                                    "height": 44,
                                                    "alpha": 1,
                                                    "maxWidth": "554",
                                                    "anchor": {
                                                        "x": "0.5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9969788519637462,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 61,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "white",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Medium.otf",
                                                        "_fontSize": 42,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "PREMIER LEAGUE",
                                                    "text_transform": {
                                                        "uppercase": true
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "ba9c3f7d-6f7a-4cb5-acc7-7fc1043b539b",
                                                    "type": "TEXT",
                                                    "name": "Home Team",
                                                    "x": 951,
                                                    "y": 446,
                                                    "control": {
                                                        "id": "2",
                                                        "name": "Home Team",
                                                        "data": {
                                                            "variable": "Home Team Name",
                                                            "variables": [
                                                                {
                                                                    "template": "MatchID",
                                                                    "field": "Home Team Name"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 231,
                                                    "height": 57,
                                                    "alpha": 1,
                                                    "maxWidth": "608",
                                                    "anchor": {
                                                        "x": "0.5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9956896551724138,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 52,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "white",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Bold.otf",
                                                        "_fontSize": 55,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "HYPER XI",
                                                    "text_transform": {
                                                        "uppercase": true
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "0aecd03b-562b-4e3a-946b-2c7f95d35802",
                                                    "type": "TEXT",
                                                    "name": "Away Team",
                                                    "x": 924,
                                                    "y": 559,
                                                    "control": {
                                                        "id": "3",
                                                        "name": "Away Team",
                                                        "data": {
                                                            "variable": "Away Team Name",
                                                            "variables": [
                                                                {
                                                                    "template": "MatchID",
                                                                    "field": "Away Team Name"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 282,
                                                    "height": 57,
                                                    "alpha": 1,
                                                    "maxWidth": "608",
                                                    "anchor": {
                                                        "x": "0.5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9964664310954063,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 52,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "white",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Bold.otf",
                                                        "_fontSize": 55,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "STUDIOS XI",
                                                    "text_transform": {
                                                        "uppercase": true
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "1055199f-f33f-4f09-95ff-db0e24c3c80b",
                                                    "type": "TEXT",
                                                    "name": "Versus",
                                                    "x": 933,
                                                    "y": 501,
                                                    "control": {
                                                        "id": "4",
                                                        "name": "Versus"
                                                    },
                                                    "width": 35,
                                                    "height": 57,
                                                    "alpha": 1,
                                                    "maxWidth": "608",
                                                    "anchor": {
                                                        "x": "0.5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9722222222222222,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 51,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "white",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Medium.otf",
                                                        "_fontSize": 55,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0,
                                                        "original_size": 55
                                                    },
                                                    "text": "V",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "38f21f76-a08b-4678-9eab-b37ec9c956c9",
                                                    "type": "TEXT",
                                                    "name": "Stadium",
                                                    "x": 897,
                                                    "y": 648,
                                                    "control": {
                                                        "id": "5",
                                                        "name": "Stadium",
                                                        "data": {
                                                            "variable": "Stadium",
                                                            "variables": [
                                                                {
                                                                    "template": "MatchID",
                                                                    "field": "Stadium"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 305,
                                                    "height": 46,
                                                    "alpha": 1,
                                                    "maxWidth": "608",
                                                    "anchor": {
                                                        "x": "0.5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9967320261437909,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 94,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#00285e",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Medium.otf",
                                                        "_fontSize": 42,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "ASM STADIUM",
                                                    "text_transform": {
                                                        "uppercase": true
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "0b9ae835-a89e-43ed-bae3-a4fd1f944037",
                                                    "type": "TEXT",
                                                    "name": "Date",
                                                    "x": 897,
                                                    "y": 705,
                                                    "control": {
                                                        "id": "6",
                                                        "name": "Date/Time",
                                                        "data": {
                                                            "variable": "Kick-Off",
                                                            "variables": [
                                                                {
                                                                    "template": "MatchID",
                                                                    "field": "Kick-Off"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 110,
                                                    "height": 46,
                                                    "alpha": 1,
                                                    "maxWidth": "608",
                                                    "anchor": {
                                                        "x": "0.5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.990990990990991,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 90,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#00285e",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Medium.otf",
                                                        "_fontSize": 42,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "19:45",
                                                    "text_transform": {
                                                        "uppercase": true
                                                    },
                                                    "maxHeight": 0
                                                }
                                            ],
                                            "uuid": "662b8436-54e0-4bd8-8137-34daba62accf",
                                            "type": "GROUP",
                                            "name": "Text_GRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 409.468505859375,
                                            "height": 411,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": "8cf4e64e-a5dc-4907-8a50-2fc0b2637cfd",
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "8cf4e64e-a5dc-4907-8a50-2fc0b2637cfd",
                                            "type": "RECTANGLE",
                                            "name": "TextMASK",
                                            "x": 676,
                                            "y": 285,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 660,
                                            "height": 515,
                                            "alpha": 1,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 660,
                                                "height": 515,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 7122399,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.266,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        }
                                    ],
                                    "uuid": "1987f1c3-edbc-42d4-9122-4a0202129268",
                                    "type": "GROUP",
                                    "name": "FF_GRP",
                                    "x": 0,
                                    "y": 0,
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1664.0928052569975,
                                    "height": 607,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": true
                                }
                            ],
                            "uuid": "26fa6b58-b618-410d-be47-5d3e33d4146a",
                            "type": "GROUP",
                            "name": "Root",
                            "x": 0,
                            "y": 0,
                            "control": {
                                "id": 0,
                                "name": ""
                            },
                            "width": 1664.0928052569975,
                            "height": 607,
                            "alpha": 1,
                            "maxWidth": 0,
                            "anchorX": 0,
                            "anchorY": 0,
                            "skew": {
                                "x": 0,
                                "y": 0
                            },
                            "scale": {
                                "x": 1,
                                "y": 1
                            },
                            "mask": null,
                            "visible": true
                        }
                    ],
                    "uuid": "74b5eb30-c704-49cb-8f58-21528df72b5e",
                    "type": "SCENE",
                    "name": "Root",
                    "x": 0,
                    "y": 0,
                    "control": {
                        "id": 0,
                        "name": ""
                    },
                    "alpha": 1,
                    "maxWidth": 0,
                    "skew": {
                        "x": 0,
                        "y": 0
                    },
                    "scale": {
                        "x": 1,
                        "y": 1
                    },
                    "mask": null,
                    "visible": false
                }
            }
        },
        {
            "_id": "61828976a4737fae26880fb5",
            "name": "FF_TeamSheet",
            "project": "6182817751b7a0dd4843297a",
            "__v": 0,
            "data": {
                "name": "FF_TeamSheet",
                "football": {
                    "templates": [
                        {
                            "name": "Lineup"
                        },
                        {
                            "name": "Lineup Subs"
                        }
                    ]
                },
                "width": 1920,
                "height": 1080,
                "folder": "UNIVERSAL",
                "aspect": "16x9",
                "timelines": [
                    {
                        "name": "IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": [
                                    {
                                        "property": "TIMELINE",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "name": "LOOPINGBG",
                                                "action": "PLAY"
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "15da8077-f37b-466b-9287-cea58f2466f1",
                                "animations": [
                                    {
                                        "property": "width",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 6,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 35,
                                                "value": 757,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.129000244140625,
                                                    0.5435003662109374
                                                ],
                                                "type": "ease-out",
                                                "time": 700
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "a02ed0ff-db0d-408a-a43b-ea5a578ff728",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 5,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 100
                                            },
                                            {
                                                "frame": 23,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.259000244140625,
                                                    0.8535003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 460
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "59d0a657-7cc5-40c6-b2be-a3e92b59f761",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 9,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 180
                                            },
                                            {
                                                "frame": 27,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.259000244140625,
                                                    0.8535003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 540
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "0ec58780-15af-4800-8cc4-2e6a0642f285",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 12,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 240
                                            },
                                            {
                                                "frame": 30,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.259000244140625,
                                                    0.8535003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 600
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "7cb69989-7b8b-4317-aa1e-2601781db918",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 300
                                            },
                                            {
                                                "frame": 33,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.259000244140625,
                                                    0.8535003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 660
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "f4f925da-fb3b-41c8-9b83-c1df498b8c6b",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 18,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 360
                                            },
                                            {
                                                "frame": 36,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.259000244140625,
                                                    0.8535003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 720
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "fcb7431f-91fe-4798-9627-2e8146309947",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 21,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 420
                                            },
                                            {
                                                "frame": 39,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.259000244140625,
                                                    0.8535003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 780
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "3cea66f9-33df-4901-8053-f2948ad85613",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 24,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 480
                                            },
                                            {
                                                "frame": 42,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.259000244140625,
                                                    0.8535003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 840
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "596bd15d-b7d0-45c9-86ce-1edf30ffceda",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 27,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 540
                                            },
                                            {
                                                "frame": 45,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.259000244140625,
                                                    0.8535003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 900
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "39c8aa95-fbdb-4881-886f-7321ec9f20d6",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 30,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 600
                                            },
                                            {
                                                "frame": 48,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.259000244140625,
                                                    0.8535003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 960
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "fa2c9f9f-5943-4630-8197-7f47d389de42",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 33,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 660
                                            },
                                            {
                                                "frame": 51,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.259000244140625,
                                                    0.8535003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 1020
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "f49a14dd-1e8c-4f30-a72d-6752e7b71432",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 36,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 720
                                            },
                                            {
                                                "frame": 54,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.259000244140625,
                                                    0.8535003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 1080
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "ad5b6543-e2af-4ffa-a1aa-24f54ec67306",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "70369faa-1782-48dc-bc59-3400741a2141",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "3728f9dc-587c-473a-b16e-d2916d3a97da",
                                "animations": [
                                    {
                                        "property": "TRIGGER",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": "play"
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 60,
                        "loop": false,
                        "fps": 50,
                        "id": "49a9ac71-edbf-449e-89e8-6ddeffdf6b6d"
                    },
                    {
                        "name": "OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "ad5b6543-e2af-4ffa-a1aa-24f54ec67306",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 13,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 260
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 13,
                        "loop": false,
                        "fps": 50,
                        "id": "205686fe-a1c8-4f4b-baab-b5289ae2acd1"
                    },
                    {
                        "name": "LOOPINGBG",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": [
                                    {
                                        "property": "TIMELINE",
                                        "keyframes": []
                                    }
                                ]
                            },
                            {
                                "object": "4ba73975-b2eb-44e8-add6-549036b1b9da",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 957,
                                                "curve": [
                                                    0.42,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "ease-in",
                                                "time": 20
                                            },
                                            {
                                                "frame": 200,
                                                "value": 1071,
                                                "curve": [
                                                    0.42,
                                                    0,
                                                    0.58,
                                                    1
                                                ],
                                                "type": "ease-in-out",
                                                "time": 4000
                                            },
                                            {
                                                "frame": 400,
                                                "value": 957,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 8000
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "b3327c7a-dc06-48a5-94ec-c988f83db3ec",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1544,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.469000244140625,
                                                    0.8435003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 20
                                            },
                                            {
                                                "frame": 200,
                                                "value": 1307,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 4000
                                            },
                                            {
                                                "frame": 400,
                                                "value": 1544,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.309000244140625,
                                                    0.8435003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 8000
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "884e3bd4-9033-4de3-945e-ab8f2ea52d93",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1064,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 240,
                                                "value": 901,
                                                "curve": [
                                                    0.42,
                                                    0,
                                                    0.58,
                                                    1
                                                ],
                                                "type": "ease-in-out",
                                                "time": 4800
                                            },
                                            {
                                                "frame": 400,
                                                "value": 1064,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 8000
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "fffdd74c-5f43-443c-af92-a27577626d37",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 817,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 400,
                                                "value": 1512,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 8000
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 25,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 500
                                            },
                                            {
                                                "frame": 375,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 7500
                                            },
                                            {
                                                "frame": 400,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 8000
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 400,
                        "loop": true,
                        "fps": 50,
                        "id": "d0caadb2-46dd-4991-aa3d-ab612342666c"
                    },
                    {
                        "name": "**DELETE",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 2,
                        "loop": false,
                        "fps": 50,
                        "id": "3b4cf27c-1fe0-4d91-9c95-14d6ca18dacb"
                    },
                    {
                        "name": "UPDATE-OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "70369faa-1782-48dc-bc59-3400741a2141",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 12,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 240
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "ad5b6543-e2af-4ffa-a1aa-24f54ec67306",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 12,
                        "loop": false,
                        "fps": 50,
                        "id": "f2411885-7873-4361-8010-83a69fc6686b"
                    },
                    {
                        "name": "UPDATE-IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "70369faa-1782-48dc-bc59-3400741a2141",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "15da8077-f37b-466b-9287-cea58f2466f1",
                                "animations": [
                                    {
                                        "property": "width",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 6,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 35,
                                                "value": 757,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.129000244140625,
                                                    0.5435003662109374
                                                ],
                                                "type": "ease-out",
                                                "time": 700
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "a02ed0ff-db0d-408a-a43b-ea5a578ff728",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 5,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 100
                                            },
                                            {
                                                "frame": 23,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.259000244140625,
                                                    0.8535003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 460
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "59d0a657-7cc5-40c6-b2be-a3e92b59f761",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 9,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 180
                                            },
                                            {
                                                "frame": 27,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.259000244140625,
                                                    0.8535003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 540
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "0ec58780-15af-4800-8cc4-2e6a0642f285",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 12,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 240
                                            },
                                            {
                                                "frame": 30,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.259000244140625,
                                                    0.8535003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 600
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "7cb69989-7b8b-4317-aa1e-2601781db918",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 300
                                            },
                                            {
                                                "frame": 33,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.259000244140625,
                                                    0.8535003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 660
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "f4f925da-fb3b-41c8-9b83-c1df498b8c6b",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 18,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 360
                                            },
                                            {
                                                "frame": 36,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.259000244140625,
                                                    0.8535003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 720
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "fcb7431f-91fe-4798-9627-2e8146309947",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 21,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 420
                                            },
                                            {
                                                "frame": 39,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.259000244140625,
                                                    0.8535003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 780
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "3cea66f9-33df-4901-8053-f2948ad85613",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 24,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 480
                                            },
                                            {
                                                "frame": 42,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.259000244140625,
                                                    0.8535003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 840
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "596bd15d-b7d0-45c9-86ce-1edf30ffceda",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 27,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 540
                                            },
                                            {
                                                "frame": 45,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.259000244140625,
                                                    0.8535003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 900
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "39c8aa95-fbdb-4881-886f-7321ec9f20d6",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 30,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 600
                                            },
                                            {
                                                "frame": 48,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.259000244140625,
                                                    0.8535003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 960
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "fa2c9f9f-5943-4630-8197-7f47d389de42",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 33,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 660
                                            },
                                            {
                                                "frame": 51,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.259000244140625,
                                                    0.8535003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 1020
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "f49a14dd-1e8c-4f30-a72d-6752e7b71432",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 36,
                                                "value": -1018,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 720
                                            },
                                            {
                                                "frame": 54,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.259000244140625,
                                                    0.8535003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 1080
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "ad5b6543-e2af-4ffa-a1aa-24f54ec67306",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 100,
                        "loop": false,
                        "fps": 50,
                        "id": "fc4c6a96-efa4-4513-a25b-6f2fb24e93eb"
                    }
                ],
                "scene": {
                    "children": [
                        {
                            "children": [
                                {
                                    "children": [],
                                    "uuid": "5ecf1a6a-2040-4e18-863a-c9fef8bf60a8",
                                    "type": "IMAGE",
                                    "name": "REF",
                                    "tint": 16777215,
                                    "x": "0",
                                    "y": "0",
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1920,
                                    "height": 1080,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchor": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "blendMode": 0,
                                    "mask": null,
                                    "visible": false,
                                    "src": "TeamsheetREF.jpg"
                                },
                                {
                                    "children": [
                                        {
                                            "children": [],
                                            "uuid": "ecab778e-3eef-4fe6-9608-344855538812",
                                            "type": "RECTANGLE",
                                            "name": "BG",
                                            "x": "0",
                                            "y": "0",
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 1920,
                                            "height": 1080,
                                            "alpha": 1,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 1920,
                                                "height": 1080,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 7122399,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "b3327c7a-dc06-48a5-94ec-c988f83db3ec",
                                            "type": "IMAGE",
                                            "name": "SmallGrad",
                                            "tint": 16777215,
                                            "x": 1544,
                                            "y": 742,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 468.90261282660373,
                                            "height": 398,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 0.47268408551068924,
                                                "y": 0.47268408551068886
                                            },
                                            "blendMode": 0,
                                            "mask": null,
                                            "visible": true,
                                            "src": "GradientBlock.png"
                                        },
                                        {
                                            "children": [],
                                            "uuid": "4ba73975-b2eb-44e8-add6-549036b1b9da",
                                            "type": "IMAGE",
                                            "name": "BigGrad",
                                            "tint": 16777215,
                                            "x": 957,
                                            "y": -291,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 995.5344418052258,
                                            "height": 844.9999999999999,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1.0035629453681711,
                                                "y": 1.003562945368171
                                            },
                                            "blendMode": 0,
                                            "mask": null,
                                            "visible": true,
                                            "src": "GradientBlock.png"
                                        },
                                        {
                                            "children": [],
                                            "uuid": "884e3bd4-9033-4de3-945e-ab8f2ea52d93",
                                            "type": "IMAGE",
                                            "name": "BigGrad",
                                            "tint": 16777215,
                                            "x": 1064,
                                            "y": 185,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 995.5344418052258,
                                            "height": 844.9999999999999,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1.0035629453681711,
                                                "y": 1.003562945368171
                                            },
                                            "blendMode": 0,
                                            "mask": null,
                                            "visible": true,
                                            "src": "GradientBlock.png"
                                        },
                                        {
                                            "children": [],
                                            "uuid": "fffdd74c-5f43-443c-af92-a27577626d37",
                                            "type": "IMAGE",
                                            "name": "SmallStroke",
                                            "tint": 16777215,
                                            "x": 1512,
                                            "y": 722,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 435,
                                            "height": 370,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "blendMode": 0,
                                            "mask": null,
                                            "visible": true,
                                            "src": "SmallStrokeFF.png"
                                        },
                                        {
                                            "children": [],
                                            "uuid": "da31daa1-d28b-403b-8605-9e0321dc59a3",
                                            "type": "RECTANGLE",
                                            "name": "Background",
                                            "x": 28,
                                            "y": 112,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 1035,
                                            "height": 951,
                                            "alpha": 1,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 1035,
                                                "height": 951,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 7122399,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.26,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "a418a679-4b38-45cc-923b-f8541e1748c8",
                                            "type": "IMAGE",
                                            "name": "TeamStroke",
                                            "tint": 16777215,
                                            "x": -218,
                                            "y": 108,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 1281,
                                            "height": 927,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "blendMode": 0,
                                            "mask": null,
                                            "visible": true,
                                            "src": "TeamSheetSTROKE.png"
                                        }
                                    ],
                                    "uuid": "00ae60de-9b13-48a5-a06d-36bfb67296be",
                                    "type": "GROUP",
                                    "name": "BG_GRP",
                                    "x": 0,
                                    "y": 0,
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 2277.534423828125,
                                    "height": 1431,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": true
                                },
                                {
                                    "children": [],
                                    "uuid": "688f0c08-eaf6-486a-897e-8e8f7f1e77c9",
                                    "type": "IMAGE",
                                    "name": "Badge",
                                    "tint": 16777215,
                                    "x": 969,
                                    "y": 50,
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1112,
                                    "height": 945,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchor": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "blendMode": 0,
                                    "mask": null,
                                    "visible": false,
                                    "src": "Placeholder.png"
                                },
                                {
                                    "children": [],
                                    "uuid": "3728f9dc-587c-473a-b16e-d2916d3a97da",
                                    "type": "VIDEO",
                                    "name": "VIDEO",
                                    "x": 958,
                                    "y": 0,
                                    "control": {
                                        "id": "999",
                                        "name": "VIDEO BADGE",
                                        "editables": [],
                                        "data": {
                                            "variable": "Team Video Badge",
                                            "variables": [
                                                {
                                                    "template": "Lineup",
                                                    "field": "Team Video Badge"
                                                },
                                                {
                                                    "template": "Lineup Subs",
                                                    "field": "Team Video Badge"
                                                }
                                            ]
                                        }
                                    },
                                    "width": 1080,
                                    "height": 1080,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchor": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "blendMode": 0,
                                    "mask": "034a0f16-21de-43a9-b4b2-a7ef6cc79b0f",
                                    "visible": true,
                                    "loop": true,
                                    "src": "CITY_SQUARE.mp4"
                                },
                                {
                                    "children": [],
                                    "uuid": "034a0f16-21de-43a9-b4b2-a7ef6cc79b0f",
                                    "type": "RECTANGLE",
                                    "name": "VIDEO MASK",
                                    "x": 1209,
                                    "y": 50,
                                    "control": {
                                        "id": 0,
                                        "name": "",
                                        "editables": []
                                    },
                                    "width": 867,
                                    "height": 982,
                                    "alpha": 1,
                                    "graphicsData": {
                                        "x": 0,
                                        "y": 0,
                                        "width": 867,
                                        "height": 982,
                                        "radius": 0,
                                        "type": 4
                                    },
                                    "fillColor": 14355009,
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": -0.25,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": true
                                },
                                {
                                    "children": [
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "051c36a1-4557-4640-bdd4-e259517d4b76",
                                                    "type": "IMAGE",
                                                    "name": "XI",
                                                    "tint": 16777215,
                                                    "x": 603,
                                                    "y": 43,
                                                    "control": {
                                                        "id": "36",
                                                        "name": "XI ON/OFF"
                                                    },
                                                    "width": 180,
                                                    "height": 135,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "15da8077-f37b-466b-9287-cea58f2466f1",
                                                    "visible": false,
                                                    "src": "XI.png"
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "15da8077-f37b-466b-9287-cea58f2466f1",
                                                    "type": "RECTANGLE",
                                                    "name": "HeaderMASK",
                                                    "x": 80,
                                                    "y": 32,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 757,
                                                    "height": 151,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 757,
                                                        "height": 151,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 14355009,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "0033f5c4-9965-4541-b95b-5c6d0e8e670e",
                                                    "type": "TEXT",
                                                    "name": "Header",
                                                    "x": 98,
                                                    "y": 50,
                                                    "control": {
                                                        "id": "40",
                                                        "name": "HEADER TEXT",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Lineup",
                                                                    "field": "Title"
                                                                },
                                                                {
                                                                    "template": "Lineup Subs",
                                                                    "field": "Title"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 708,
                                                    "height": 108,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.998589562764457,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "15da8077-f37b-466b-9287-cea58f2466f1",
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 99,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "white",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 100,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "SUBSTITUTES",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                }
                                            ],
                                            "uuid": "9a4076be-e456-4224-8c6a-91157951fad8",
                                            "type": "GROUP",
                                            "name": "HeaderGRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 717.9858703613281,
                                            "height": 118,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "ef3f979d-bbb6-4d55-abe2-868455dd62ce",
                                                    "type": "TEXT",
                                                    "name": "Number1",
                                                    "x": 178,
                                                    "y": 215,
                                                    "control": {
                                                        "id": "1",
                                                        "name": "Player1 Number",
                                                        "data": {
                                                            "variable": "Shirt Number 1",
                                                            "variables": [
                                                                {
                                                                    "template": "Lineup",
                                                                    "field": "Shirt Number 1"
                                                                },
                                                                {
                                                                    "template": "Lineup Subs",
                                                                    "field": "Shirt Number 1"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 37,
                                                    "height": 65,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "1",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9736842105263158,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 55,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "white",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 60,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "3",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "de05f66d-d62c-4649-beaa-b5daa30dc999",
                                                    "type": "TEXT",
                                                    "name": "Family1",
                                                    "x": 213,
                                                    "y": 215,
                                                    "control": {
                                                        "id": "2",
                                                        "name": "Player1 Name",
                                                        "data": {
                                                            "variable": "Last Name 1",
                                                            "variables": [
                                                                {
                                                                    "template": "Lineup",
                                                                    "field": "Last Name 1"
                                                                },
                                                                {
                                                                    "template": "Lineup Subs",
                                                                    "field": "Last Name Uppercase 1"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 208,
                                                    "height": 65,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "0",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9952153110047847,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 54,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "white",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 60,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "THREE",
                                                    "text_transform": {
                                                        "uppercase": true
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "49fc4c3d-6bb3-4eb5-b6b5-3d540598bec6",
                                                    "type": "IMAGE",
                                                    "name": "Captain1",
                                                    "tint": 16777215,
                                                    "x": 443,
                                                    "y": 228,
                                                    "control": {
                                                        "id": "3",
                                                        "name": "Player1 Captain ON/OFF"
                                                    },
                                                    "width": 76,
                                                    "height": 43,
                                                    "alpha": 1,
                                                    "autoFollow": "de05f66d-d62c-4649-beaa-b5daa30dc999",
                                                    "autoFollowPadding": 22,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": false,
                                                    "src": "CaptainSymbol.png"
                                                }
                                            ],
                                            "uuid": "a02ed0ff-db0d-408a-a43b-ea5a578ff728",
                                            "type": "GROUP",
                                            "name": "Player_1",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 289.84449768066406,
                                            "height": 75,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "1a54d73c-cadf-4d8f-b7fe-9e203e156609",
                                                    "type": "TEXT",
                                                    "name": "Number2",
                                                    "x": 178,
                                                    "y": 215,
                                                    "control": {
                                                        "id": "4",
                                                        "name": "Player2 Number",
                                                        "data": {
                                                            "variable": "Shirt Number 2",
                                                            "variables": [
                                                                {
                                                                    "template": "Lineup",
                                                                    "field": "Shirt Number 2"
                                                                },
                                                                {
                                                                    "template": "Lineup Subs",
                                                                    "field": "Shirt Number 2"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 0,
                                                    "height": 65,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "1",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 55,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "white",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 60,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "ecf7570f-9d69-4c03-8cb4-24d891a0df95",
                                                    "type": "TEXT",
                                                    "name": "Family2",
                                                    "x": 213,
                                                    "y": 215,
                                                    "control": {
                                                        "id": "5",
                                                        "name": "Player2 Name",
                                                        "data": {
                                                            "variable": "Last Name 2",
                                                            "variables": [
                                                                {
                                                                    "template": "Lineup",
                                                                    "field": "Last Name 2"
                                                                },
                                                                {
                                                                    "template": "Lineup Subs",
                                                                    "field": "Last Name Uppercase 2"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 0,
                                                    "height": 65,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "0",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 54,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "white",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 60,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "",
                                                    "text_transform": {
                                                        "uppercase": true
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "18b8785b-6708-443a-97ee-9f783372b250",
                                                    "type": "IMAGE",
                                                    "name": "Captain2",
                                                    "tint": 16777215,
                                                    "x": 235,
                                                    "y": 228,
                                                    "control": {
                                                        "id": "6",
                                                        "name": "Player2 Captain ON/OFF"
                                                    },
                                                    "width": 76,
                                                    "height": 43,
                                                    "alpha": 1,
                                                    "autoFollow": "ecf7570f-9d69-4c03-8cb4-24d891a0df95",
                                                    "autoFollowPadding": 22,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": false,
                                                    "src": "CaptainSymbol.png"
                                                }
                                            ],
                                            "uuid": "59d0a657-7cc5-40c6-b2be-a3e92b59f761",
                                            "type": "GROUP",
                                            "name": "Player_2",
                                            "x": 0,
                                            "y": "70",
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 35,
                                            "height": 75,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "48f0946e-e512-44c3-b58f-c1e5d451963b",
                                                    "type": "TEXT",
                                                    "name": "Number3",
                                                    "x": 178,
                                                    "y": 215,
                                                    "control": {
                                                        "id": "7",
                                                        "name": "Player3 Number",
                                                        "data": {
                                                            "variable": "Shirt Number 3",
                                                            "variables": [
                                                                {
                                                                    "template": "Lineup",
                                                                    "field": "Shirt Number 3"
                                                                },
                                                                {
                                                                    "template": "Lineup Subs",
                                                                    "field": "Shirt Number 3"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 0,
                                                    "height": 65,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "1",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 55,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "white",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 60,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "7187a0b4-81d3-46e6-a667-be289260d459",
                                                    "type": "TEXT",
                                                    "name": "Family3",
                                                    "x": 213,
                                                    "y": 215,
                                                    "control": {
                                                        "id": "8",
                                                        "name": "Player3 Name",
                                                        "data": {
                                                            "variable": "Last Name 3",
                                                            "variables": [
                                                                {
                                                                    "template": "Lineup",
                                                                    "field": "Last Name 3"
                                                                },
                                                                {
                                                                    "template": "Lineup Subs",
                                                                    "field": "Last Name Uppercase 3"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 0,
                                                    "height": 65,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "0",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 54,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "white",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 60,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "",
                                                    "text_transform": {
                                                        "uppercase": true
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "80676472-1d64-4906-a347-bebc493ed189",
                                                    "type": "IMAGE",
                                                    "name": "Captain3",
                                                    "tint": 16777215,
                                                    "x": 235,
                                                    "y": 228,
                                                    "control": {
                                                        "id": "9",
                                                        "name": "Player3 Captain ON/OFF"
                                                    },
                                                    "width": 76,
                                                    "height": 43,
                                                    "alpha": 1,
                                                    "autoFollow": "7187a0b4-81d3-46e6-a667-be289260d459",
                                                    "autoFollowPadding": 22,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "src": "CaptainSymbol.png"
                                                }
                                            ],
                                            "uuid": "0ec58780-15af-4800-8cc4-2e6a0642f285",
                                            "type": "GROUP",
                                            "name": "Player_3",
                                            "x": 0,
                                            "y": 140,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 133,
                                            "height": 75,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "9dfac1db-30f4-4039-ade9-0e28579a8f50",
                                                    "type": "TEXT",
                                                    "name": "Number4",
                                                    "x": 178,
                                                    "y": 215,
                                                    "control": {
                                                        "id": "10",
                                                        "name": "Player4 Number",
                                                        "data": {
                                                            "variable": "Shirt Number 4",
                                                            "variables": [
                                                                {
                                                                    "template": "Lineup",
                                                                    "field": "Shirt Number 4"
                                                                },
                                                                {
                                                                    "template": "Lineup Subs",
                                                                    "field": "Shirt Number 4"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 0,
                                                    "height": 65,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "1",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 55,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "white",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 60,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "6f3e02e2-ac7d-493b-8000-dfbf069071f0",
                                                    "type": "TEXT",
                                                    "name": "Family4",
                                                    "x": 213,
                                                    "y": 215,
                                                    "control": {
                                                        "id": "11",
                                                        "name": "Player4 Name",
                                                        "data": {
                                                            "variable": "Last Name 4",
                                                            "variables": [
                                                                {
                                                                    "template": "Lineup",
                                                                    "field": "Last Name 4"
                                                                },
                                                                {
                                                                    "template": "Lineup Subs",
                                                                    "field": "Last Name Uppercase 4"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 0,
                                                    "height": 65,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "0",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 54,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "white",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 60,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "",
                                                    "text_transform": {
                                                        "uppercase": true
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "e8bc0c03-0546-4943-942e-412fb820d969",
                                                    "type": "IMAGE",
                                                    "name": "Captain4",
                                                    "tint": 16777215,
                                                    "x": 235,
                                                    "y": 228,
                                                    "control": {
                                                        "id": "12",
                                                        "name": "Player4 Captain ON/OFF"
                                                    },
                                                    "width": 76,
                                                    "height": 43,
                                                    "alpha": 1,
                                                    "autoFollow": "6f3e02e2-ac7d-493b-8000-dfbf069071f0",
                                                    "autoFollowPadding": 22,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": false,
                                                    "src": "CaptainSymbol.png"
                                                }
                                            ],
                                            "uuid": "7cb69989-7b8b-4317-aa1e-2601781db918",
                                            "type": "GROUP",
                                            "name": "Player_4",
                                            "x": 0,
                                            "y": 210,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 35,
                                            "height": 75,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "8e451ba1-15a6-40d5-a74d-615aca038234",
                                                    "type": "TEXT",
                                                    "name": "Number5",
                                                    "x": 178,
                                                    "y": 215,
                                                    "control": {
                                                        "id": "13",
                                                        "name": "Player5 Number",
                                                        "data": {
                                                            "variable": "Shirt Number 5",
                                                            "variables": [
                                                                {
                                                                    "template": "Lineup",
                                                                    "field": "Shirt Number 5"
                                                                },
                                                                {
                                                                    "template": "Lineup Subs",
                                                                    "field": "Shirt Number 5"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 0,
                                                    "height": 65,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "1",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 55,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "white",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 60,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "ead287f8-9c01-4d97-aafc-ccdde7c40d41",
                                                    "type": "TEXT",
                                                    "name": "Family5",
                                                    "x": 213,
                                                    "y": 215,
                                                    "control": {
                                                        "id": "14",
                                                        "name": "Player5 Name",
                                                        "data": {
                                                            "variable": "Last Name 5",
                                                            "variables": [
                                                                {
                                                                    "template": "Lineup",
                                                                    "field": "Last Name 5"
                                                                },
                                                                {
                                                                    "template": "Lineup Subs",
                                                                    "field": "Last Name Uppercase 5"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 0,
                                                    "height": 65,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "0",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 54,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "white",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 60,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "",
                                                    "text_transform": {
                                                        "uppercase": true
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "73c492e9-04f8-463d-aac7-c41410db89c9",
                                                    "type": "IMAGE",
                                                    "name": "Captain5",
                                                    "tint": 16777215,
                                                    "x": 235,
                                                    "y": 228,
                                                    "control": {
                                                        "id": "15",
                                                        "name": "Player5 Captain ON/OFF"
                                                    },
                                                    "width": 76,
                                                    "height": 43,
                                                    "alpha": 1,
                                                    "autoFollow": "ead287f8-9c01-4d97-aafc-ccdde7c40d41",
                                                    "autoFollowPadding": 22,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": false,
                                                    "src": "CaptainSymbol.png"
                                                }
                                            ],
                                            "uuid": "f4f925da-fb3b-41c8-9b83-c1df498b8c6b",
                                            "type": "GROUP",
                                            "name": "Player_5",
                                            "x": 0,
                                            "y": "280",
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 35,
                                            "height": 75,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "b1d7ab0c-e3c9-48e2-907c-0155954d5036",
                                                    "type": "TEXT",
                                                    "name": "Number6",
                                                    "x": 178,
                                                    "y": 215,
                                                    "control": {
                                                        "id": "16",
                                                        "name": "Player6 Number",
                                                        "data": {
                                                            "variable": "Shirt Number 6",
                                                            "variables": [
                                                                {
                                                                    "template": "Lineup",
                                                                    "field": "Shirt Number 6"
                                                                },
                                                                {
                                                                    "template": "Lineup Subs",
                                                                    "field": "Shirt Number 6"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 0,
                                                    "height": 65,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "1",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 55,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "white",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 60,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "71ae4ca6-2abe-4995-aac0-40467a59d7fb",
                                                    "type": "TEXT",
                                                    "name": "Family6",
                                                    "x": 213,
                                                    "y": 215,
                                                    "control": {
                                                        "id": "17",
                                                        "name": "Player6 Name",
                                                        "data": {
                                                            "variable": "Last Name 6",
                                                            "variables": [
                                                                {
                                                                    "template": "Lineup",
                                                                    "field": "Last Name 6"
                                                                },
                                                                {
                                                                    "template": "Lineup Subs",
                                                                    "field": "Last Name Uppercase 6"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 0,
                                                    "height": 65,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "0",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 54,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "white",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 60,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "",
                                                    "text_transform": {
                                                        "uppercase": true
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "5e3f6dfb-06d6-449a-990d-a261770f33ac",
                                                    "type": "IMAGE",
                                                    "name": "Captain6",
                                                    "tint": 16777215,
                                                    "x": 235,
                                                    "y": 228,
                                                    "control": {
                                                        "id": "18",
                                                        "name": "Player6 Captain ON/OFF"
                                                    },
                                                    "width": 76,
                                                    "height": 43,
                                                    "alpha": 1,
                                                    "autoFollow": "71ae4ca6-2abe-4995-aac0-40467a59d7fb",
                                                    "autoFollowPadding": 22,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": false,
                                                    "src": "CaptainSymbol.png"
                                                }
                                            ],
                                            "uuid": "fcb7431f-91fe-4798-9627-2e8146309947",
                                            "type": "GROUP",
                                            "name": "Player_6",
                                            "x": 0,
                                            "y": "350",
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 35,
                                            "height": 75,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "9d843658-3b39-4475-9d3c-e19cb714735c",
                                                    "type": "TEXT",
                                                    "name": "Number7",
                                                    "x": 178,
                                                    "y": 215,
                                                    "control": {
                                                        "id": "19",
                                                        "name": "Player7 Number",
                                                        "data": {
                                                            "variable": "Shirt Number 7",
                                                            "variables": [
                                                                {
                                                                    "template": "Lineup",
                                                                    "field": "Shirt Number 7"
                                                                },
                                                                {
                                                                    "template": "Lineup Subs",
                                                                    "field": "Shirt Number 7"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 0,
                                                    "height": 65,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "1",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 55,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "white",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 60,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "64a08310-8f5e-414d-9b72-8c0a56ab12d8",
                                                    "type": "TEXT",
                                                    "name": "Family7",
                                                    "x": 213,
                                                    "y": 215,
                                                    "control": {
                                                        "id": "20",
                                                        "name": "Player7 Name",
                                                        "data": {
                                                            "variable": "Last Name 7",
                                                            "variables": [
                                                                {
                                                                    "template": "Lineup",
                                                                    "field": "Last Name 7"
                                                                },
                                                                {
                                                                    "template": "Lineup Subs",
                                                                    "field": "Last Name Uppercase 7"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 0,
                                                    "height": 65,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "0",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 54,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "white",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 60,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "",
                                                    "text_transform": {
                                                        "uppercase": true
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "d722af9c-63f8-4046-8cd5-0d3bd817fd5d",
                                                    "type": "IMAGE",
                                                    "name": "Captain7",
                                                    "tint": 16777215,
                                                    "x": 235,
                                                    "y": 228,
                                                    "control": {
                                                        "id": "21",
                                                        "name": "Player7 Captain ON/OFF"
                                                    },
                                                    "width": 76,
                                                    "height": 43,
                                                    "alpha": 1,
                                                    "autoFollow": "64a08310-8f5e-414d-9b72-8c0a56ab12d8",
                                                    "autoFollowPadding": 22,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": false,
                                                    "src": "CaptainSymbol.png"
                                                }
                                            ],
                                            "uuid": "3cea66f9-33df-4901-8053-f2948ad85613",
                                            "type": "GROUP",
                                            "name": "Player_7",
                                            "x": 0,
                                            "y": "420",
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 35,
                                            "height": 75,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "8cb9a436-93b9-44d1-9a0b-ec85ca8a14a3",
                                                    "type": "TEXT",
                                                    "name": "Number8",
                                                    "x": 178,
                                                    "y": 215,
                                                    "control": {
                                                        "id": "22",
                                                        "name": "Player8 Number",
                                                        "data": {
                                                            "variable": "Shirt Number 8",
                                                            "variables": [
                                                                {
                                                                    "template": "Lineup",
                                                                    "field": "Shirt Number 8"
                                                                },
                                                                {
                                                                    "template": "Lineup Subs",
                                                                    "field": "Shirt Number 8"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 0,
                                                    "height": 65,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "1",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 55,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "white",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 60,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "5a77286d-c32a-42d3-bd0c-bc9b75d305cb",
                                                    "type": "TEXT",
                                                    "name": "Family8",
                                                    "x": 213,
                                                    "y": 215,
                                                    "control": {
                                                        "id": "23",
                                                        "name": "Player8 Name",
                                                        "data": {
                                                            "variable": "Last Name 8",
                                                            "variables": [
                                                                {
                                                                    "template": "Lineup",
                                                                    "field": "Last Name 8"
                                                                },
                                                                {
                                                                    "template": "Lineup Subs",
                                                                    "field": "Last Name Uppercase 8"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 0,
                                                    "height": 65,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "0",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 54,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "white",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 60,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "",
                                                    "text_transform": {
                                                        "uppercase": true
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "f67fa33e-6cbd-4a3b-a5ee-2694ce7ef159",
                                                    "type": "IMAGE",
                                                    "name": "Captain8",
                                                    "tint": 16777215,
                                                    "x": 235,
                                                    "y": 228,
                                                    "control": {
                                                        "id": "24",
                                                        "name": "Player8 Captain ON/OFF"
                                                    },
                                                    "width": 76,
                                                    "height": 43,
                                                    "alpha": 1,
                                                    "autoFollow": "5a77286d-c32a-42d3-bd0c-bc9b75d305cb",
                                                    "autoFollowPadding": 22,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": false,
                                                    "src": "CaptainSymbol.png"
                                                }
                                            ],
                                            "uuid": "596bd15d-b7d0-45c9-86ce-1edf30ffceda",
                                            "type": "GROUP",
                                            "name": "Player_8",
                                            "x": 0,
                                            "y": "490",
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 35,
                                            "height": 75,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "2e34de0b-2417-445b-a3ef-ae0a6e2047cf",
                                                    "type": "TEXT",
                                                    "name": "Number9",
                                                    "x": 178,
                                                    "y": 215,
                                                    "control": {
                                                        "id": "25",
                                                        "name": "Player9 Number",
                                                        "data": {
                                                            "variable": "Shirt Number 9",
                                                            "variables": [
                                                                {
                                                                    "template": "Lineup",
                                                                    "field": "Shirt Number 9"
                                                                },
                                                                {
                                                                    "template": "Lineup Subs",
                                                                    "field": "Shirt Number 9"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 0,
                                                    "height": 65,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "1",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 55,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "white",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 60,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "75d9e4b9-5bab-4c36-a7ab-aeb8f8807f44",
                                                    "type": "TEXT",
                                                    "name": "Family9",
                                                    "x": 213,
                                                    "y": 215,
                                                    "control": {
                                                        "id": "26",
                                                        "name": "Player9 Name",
                                                        "data": {
                                                            "variable": "Last Name 9",
                                                            "variables": [
                                                                {
                                                                    "template": "Lineup",
                                                                    "field": "Last Name 9"
                                                                },
                                                                {
                                                                    "template": "Lineup Subs",
                                                                    "field": "Last Name Uppercase 9"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 0,
                                                    "height": 65,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "0",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 54,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "white",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 60,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "",
                                                    "text_transform": {
                                                        "uppercase": true
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "a816abba-ddc8-4c74-84e4-ca7175300f81",
                                                    "type": "IMAGE",
                                                    "name": "Captain9",
                                                    "tint": 16777215,
                                                    "x": 235,
                                                    "y": 228,
                                                    "control": {
                                                        "id": "27",
                                                        "name": "Player9 Captain ON/OFF"
                                                    },
                                                    "width": 76,
                                                    "height": 43,
                                                    "alpha": 1,
                                                    "autoFollow": "75d9e4b9-5bab-4c36-a7ab-aeb8f8807f44",
                                                    "autoFollowPadding": 22,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": false,
                                                    "src": "CaptainSymbol.png"
                                                }
                                            ],
                                            "uuid": "39c8aa95-fbdb-4881-886f-7321ec9f20d6",
                                            "type": "GROUP",
                                            "name": "Player_9",
                                            "x": 0,
                                            "y": "560",
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 35,
                                            "height": 75,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "254690a9-67e6-4107-8da1-69949eccc17c",
                                                    "type": "TEXT",
                                                    "name": "Number10",
                                                    "x": 178,
                                                    "y": 215,
                                                    "control": {
                                                        "id": "28",
                                                        "name": "Player10 Number",
                                                        "data": {
                                                            "variable": "Shirt Number 10",
                                                            "variables": [
                                                                {
                                                                    "template": "Lineup",
                                                                    "field": "Shirt Number 10"
                                                                },
                                                                {
                                                                    "template": "Lineup Subs",
                                                                    "field": "Shirt Number 10"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 0,
                                                    "height": 65,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "1",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 55,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "white",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 60,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "eb0fdfc0-ef3a-4985-82d0-1a6b16f62db2",
                                                    "type": "TEXT",
                                                    "name": "Family10",
                                                    "x": 213,
                                                    "y": 215,
                                                    "control": {
                                                        "id": "29",
                                                        "name": "Player10 Name",
                                                        "data": {
                                                            "variable": "Last Name 10",
                                                            "variables": [
                                                                {
                                                                    "template": "Lineup",
                                                                    "field": "Last Name 10"
                                                                },
                                                                {
                                                                    "template": "Lineup Subs",
                                                                    "field": "Last Name Uppercase 10"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 0,
                                                    "height": 65,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "0",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 54,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "white",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 60,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "",
                                                    "text_transform": {
                                                        "uppercase": true
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "ded11d8a-d719-4777-a418-645348f7ad86",
                                                    "type": "IMAGE",
                                                    "name": "Captain10",
                                                    "tint": 16777215,
                                                    "x": 235,
                                                    "y": 228,
                                                    "control": {
                                                        "id": "30",
                                                        "name": "Player10 Captain ON/OFF"
                                                    },
                                                    "width": 76,
                                                    "height": 43,
                                                    "alpha": 1,
                                                    "autoFollow": "eb0fdfc0-ef3a-4985-82d0-1a6b16f62db2",
                                                    "autoFollowPadding": 22,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": false,
                                                    "src": "CaptainSymbol.png"
                                                }
                                            ],
                                            "uuid": "fa2c9f9f-5943-4630-8197-7f47d389de42",
                                            "type": "GROUP",
                                            "name": "Player_10",
                                            "x": 0,
                                            "y": "630",
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 35,
                                            "height": 75,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "5bf39ed0-abfc-423d-a359-6e7cc64b6cb7",
                                                    "type": "TEXT",
                                                    "name": "Number11",
                                                    "x": 178,
                                                    "y": 215,
                                                    "control": {
                                                        "id": "31",
                                                        "name": "Player11 Number",
                                                        "data": {
                                                            "variable": "Shirt Number 11",
                                                            "variables": [
                                                                {
                                                                    "template": "Lineup",
                                                                    "field": "Shirt Number 11"
                                                                },
                                                                {
                                                                    "template": "Lineup Subs",
                                                                    "field": "Shirt Number 11"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 0,
                                                    "height": 65,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "1",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 55,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "white",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 60,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "179b9796-e2cd-446f-acc0-b01b02a7cc5e",
                                                    "type": "TEXT",
                                                    "name": "Family11",
                                                    "x": 213,
                                                    "y": 215,
                                                    "control": {
                                                        "id": "32",
                                                        "name": "Player11 Name",
                                                        "data": {
                                                            "variable": "Last Name 11",
                                                            "variables": [
                                                                {
                                                                    "template": "Lineup",
                                                                    "field": "Last Name 11"
                                                                },
                                                                {
                                                                    "template": "Lineup Subs",
                                                                    "field": "Last Name Uppercase 11"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 0,
                                                    "height": 65,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "0",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 54,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "white",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 60,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "",
                                                    "text_transform": {
                                                        "uppercase": true
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "49dbcc53-e585-4658-bd7d-23438aaa2a29",
                                                    "type": "IMAGE",
                                                    "name": "Captain11",
                                                    "tint": 16777215,
                                                    "x": 235,
                                                    "y": 228,
                                                    "control": {
                                                        "id": "33",
                                                        "name": "Player11 Captain ON/OFF"
                                                    },
                                                    "width": 76,
                                                    "height": 43,
                                                    "alpha": 1,
                                                    "autoFollow": "179b9796-e2cd-446f-acc0-b01b02a7cc5e",
                                                    "autoFollowPadding": 22,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": false,
                                                    "src": "CaptainSymbol.png"
                                                }
                                            ],
                                            "uuid": "f49a14dd-1e8c-4f30-a72d-6752e7b71432",
                                            "type": "GROUP",
                                            "name": "Player_11",
                                            "x": 0,
                                            "y": "700",
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 35,
                                            "height": 75,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        }
                                    ],
                                    "uuid": "70369faa-1782-48dc-bc59-3400741a2141",
                                    "type": "GROUP",
                                    "name": "First11_GRP",
                                    "x": 0,
                                    "y": 0,
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 717.9858703613281,
                                    "height": 940,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": true
                                }
                            ],
                            "uuid": "ad5b6543-e2af-4ffa-a1aa-24f54ec67306",
                            "type": "GROUP",
                            "name": "Root",
                            "x": 0,
                            "y": 0,
                            "control": {
                                "id": 0,
                                "name": ""
                            },
                            "width": 2277.534423828125,
                            "height": 1431,
                            "alpha": 1,
                            "maxWidth": 0,
                            "anchorX": 0,
                            "anchorY": 0,
                            "skew": {
                                "x": 0,
                                "y": 0
                            },
                            "scale": {
                                "x": 1,
                                "y": 1
                            },
                            "mask": null,
                            "visible": true
                        },
                        {
                            "children": [],
                            "uuid": "648eea5f-7b6e-41e1-8c9e-9efa5eef2bae",
                            "type": "GROUP",
                            "name": "SCRIPT",
                            "x": 0,
                            "y": 0,
                            "control": {
                                "id": 0,
                                "name": "",
                                "editables": []
                            },
                            "width": 0,
                            "height": 0,
                            "alpha": 1,
                            "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(item, frame, scene) {\n  let XI = hyperAPI.getItemByName(scene.name, 'XI');\n  let Header = hyperAPI.getItemByName(scene.name, 'Header');\n  \n  if (Header.text === 'STARTING'){\n   XI.visible = true; \n  } else {\n   XI.visible = false; \n  }\n}",
                            "maxWidth": 0,
                            "anchorX": 0,
                            "anchorY": 0,
                            "skew": {
                                "x": 0,
                                "y": 0
                            },
                            "scale": {
                                "x": 1,
                                "y": 1
                            },
                            "mask": null,
                            "visible": true
                        }
                    ],
                    "uuid": "7b4d25d0-5137-4da2-bed4-7ff9b4208ec5",
                    "type": "SCENE",
                    "name": "Root",
                    "x": 0,
                    "y": 0,
                    "control": {
                        "id": 0,
                        "name": ""
                    },
                    "alpha": 1,
                    "maxWidth": 0,
                    "skew": {
                        "x": 0,
                        "y": 0
                    },
                    "scale": {
                        "x": 1,
                        "y": 1
                    },
                    "mask": null,
                    "visible": false
                }
            }
        },
        {
            "_id": "61828977a4737fae2688102d",
            "name": "L3_BadgeName",
            "project": "6182817751b7a0dd4843297a",
            "__v": 0,
            "data": {
                "name": "L3_BadgeName",
                "football": {
                    "templates": [
                        {
                            "name": "Name"
                        },
                        {
                            "name": "Player Scored"
                        }
                    ]
                },
                "width": 1920,
                "height": 1080,
                "folder": "UNIVERSAL",
                "aspect": "16x9",
                "timelines": [
                    {
                        "name": "IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": [
                                    {
                                        "property": "TIMELINE",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "name": "LOOP",
                                                "action": "PLAY"
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "2a803f10-57c8-49cc-81a2-317d2db11aff",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1445,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 23,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.269000244140625,
                                                    0.9235003662109376
                                                ],
                                                "type": "ease-out",
                                                "time": 460
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "37ae6058-ee1f-4cf2-992e-816ecef0d9ab",
                                "animations": [
                                    {
                                        "property": "width",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            },
                                            {
                                                "frame": 26,
                                                "value": 105,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 520
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "64bcd3c4-a3a5-4ad1-9001-423629561f9a",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 46,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 24,
                                                "value": 46,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 480
                                            },
                                            {
                                                "frame": 39,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 780
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "720e61c2-d2b4-4ebd-a35a-fb607cac149c",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 166,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 24,
                                                "value": 166,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 480
                                            },
                                            {
                                                "frame": 39,
                                                "value": 776,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.029000244140625,
                                                    0.9235003662109376
                                                ],
                                                "type": "ease-out",
                                                "time": 780
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "7b3ef1f0-d97d-4d7c-a41d-6a878cba3faa",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 973,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "8a92fc42-4288-4146-84aa-0bafd89a0a5a",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 968,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "a1ce6338-0ad6-4550-8076-b4499b0704b6",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "c3d7204b-0b38-4163-9069-acec29c1736c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "bc6a08dd-efc8-4640-a04b-36a670b34302",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 550,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "ffa001f2-6a0a-44e6-87aa-6fdf50b76aaa",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1479,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 24,
                                                "value": 1479,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 480
                                            },
                                            {
                                                "frame": 39,
                                                "value": 1388,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 780
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 39,
                        "loop": false,
                        "fps": 50,
                        "id": "cf8688eb-caf7-4db4-8d01-a9e409906d59"
                    },
                    {
                        "name": "OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "64bcd3c4-a3a5-4ad1-9001-423629561f9a",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 17,
                                                "value": 46,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.699000244140625,
                                                    0.20350036621093748
                                                ],
                                                "type": "ease-out",
                                                "time": 340
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "2a803f10-57c8-49cc-81a2-317d2db11aff",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 300
                                            },
                                            {
                                                "frame": 34,
                                                "value": 1453,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.779000244140625,
                                                    0.053500366210937456
                                                ],
                                                "type": "ease-out",
                                                "time": 680
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 35,
                        "loop": false,
                        "fps": 50,
                        "id": "aec68638-f32d-4df8-a99e-48f84ac48db4"
                    },
                    {
                        "name": "CARD-IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "7b3ef1f0-d97d-4d7c-a41d-6a878cba3faa",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 300
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 49,
                                                "value": 1336,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.189000244140625,
                                                    0.9235003662109376
                                                ],
                                                "type": "ease-out",
                                                "time": 980
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 15,
                        "loop": false,
                        "fps": 50,
                        "id": "0f797418-f366-4402-801b-bd2f6fd62f14"
                    },
                    {
                        "name": "CARD-OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "7b3ef1f0-d97d-4d7c-a41d-6a878cba3faa",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 980,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.749000244140625,
                                                    0.04350036621093745
                                                ],
                                                "type": "ease-out",
                                                "time": 300
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 15,
                        "loop": false,
                        "fps": 50,
                        "id": "08229be5-8855-46d3-874c-4af2fc4c8ca9"
                    },
                    {
                        "name": "2CARD-iN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "8a92fc42-4288-4146-84aa-0bafd89a0a5a",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 25,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.109000244140625,
                                                    0.9835003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 500
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1277,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 29,
                                                "value": 1277,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 580
                                            },
                                            {
                                                "frame": 35,
                                                "value": 1277,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 700
                                            },
                                            {
                                                "frame": 49,
                                                "value": 1336,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.189000244140625,
                                                    0.9235003662109376
                                                ],
                                                "type": "ease-out",
                                                "time": 980
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 62,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 1240
                                            },
                                            {
                                                "frame": 63,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 1260
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "7b3ef1f0-d97d-4d7c-a41d-6a878cba3faa",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 13,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 260
                                            },
                                            {
                                                "frame": 29,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.109000244140625,
                                                    0.9835003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 580
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 62,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 1240
                                            },
                                            {
                                                "frame": 63,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 1260
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "a1ce6338-0ad6-4550-8076-b4499b0704b6",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 52,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 1040
                                            },
                                            {
                                                "frame": 62,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 1240
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 75,
                        "loop": false,
                        "fps": 50,
                        "id": "b2978066-04c1-4f4d-9155-1a5519583a60"
                    },
                    {
                        "name": "2CARD-OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "a1ce6338-0ad6-4550-8076-b4499b0704b6",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 12,
                                                "value": 958,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 240
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 12,
                        "loop": false,
                        "fps": 50,
                        "id": "78b04f6d-424d-47e7-8617-19c05a99f845"
                    },
                    {
                        "name": "UPDATE-IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "bc6a08dd-efc8-4640-a04b-36a670b34302",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 656,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 6,
                                                "value": 764,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 120
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "720e61c2-d2b4-4ebd-a35a-fb607cac149c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 88,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 22,
                                                "value": 776,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.279000244140625,
                                                    0.8435003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 440
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "c3d7204b-0b38-4163-9069-acec29c1736c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "ffa001f2-6a0a-44e6-87aa-6fdf50b76aaa",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1474,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 13,
                                                "value": 1386,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 260
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 25,
                        "loop": false,
                        "fps": 50,
                        "id": "e1863af5-61f6-456c-9aaa-309221d38da9"
                    },
                    {
                        "name": "UPDATE-OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "bc6a08dd-efc8-4640-a04b-36a670b34302",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 550,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 9,
                                                "value": 550,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 180
                                            },
                                            {
                                                "frame": 14,
                                                "value": 656,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "720e61c2-d2b4-4ebd-a35a-fb607cac149c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "c3d7204b-0b38-4163-9069-acec29c1736c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "ffa001f2-6a0a-44e6-87aa-6fdf50b76aaa",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1388,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 1474,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 14,
                        "loop": false,
                        "fps": 50,
                        "id": "cd9f1efc-5819-4a67-9710-4c3b9bc0d0fa"
                    },
                    {
                        "name": "LOOP",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "c619cc80-c7c1-416b-8236-acc05ca399c5",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 515,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 150,
                                                "value": 533,
                                                "curve": [
                                                    0.42,
                                                    0,
                                                    0.58,
                                                    1
                                                ],
                                                "type": "ease-in-out",
                                                "time": 3000
                                            },
                                            {
                                                "frame": 300,
                                                "value": 515,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 6000
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "6fc539ea-4678-440a-b242-8df4992b2937",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1224,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 175,
                                                "value": 1207,
                                                "curve": [
                                                    0.42,
                                                    0,
                                                    0.58,
                                                    1
                                                ],
                                                "type": "ease-in-out",
                                                "time": 3500
                                            },
                                            {
                                                "frame": 300,
                                                "value": 1224,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 6000
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 300,
                        "loop": true,
                        "fps": 50,
                        "id": "08ab12e4-44fe-44e1-b966-6e69644b9eee"
                    }
                ],
                "scene": {
                    "children": [
                        {
                            "children": [
                                {
                                    "children": [],
                                    "uuid": "170bf835-10e7-4284-80d2-096637bf36a1",
                                    "type": "IMAGE",
                                    "name": "REF",
                                    "tint": 16777215,
                                    "x": "0",
                                    "y": "0",
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1920,
                                    "height": 1080,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchor": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "blendMode": 0,
                                    "mask": null,
                                    "visible": false,
                                    "src": "LowerThirdREF.jpg"
                                },
                                {
                                    "children": [],
                                    "uuid": "81981809-83ec-4e06-b646-5e80f0067b3b",
                                    "type": "RECTANGLE",
                                    "name": "Background",
                                    "x": "0",
                                    "y": "0",
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1920,
                                    "height": 1080,
                                    "alpha": 1,
                                    "graphicsData": {
                                        "x": 0,
                                        "y": 0,
                                        "width": 1920,
                                        "height": 1080,
                                        "radius": 0,
                                        "type": 4
                                    },
                                    "fillColor": 14355009,
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": false
                                },
                                {
                                    "children": [
                                        {
                                            "children": [],
                                            "uuid": "6fc539ea-4678-440a-b242-8df4992b2937",
                                            "type": "IMAGE",
                                            "name": "Stroke",
                                            "tint": 16777215,
                                            "x": 1224,
                                            "y": 884,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 207,
                                            "height": 78,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "blendMode": 0,
                                            "mask": null,
                                            "visible": true,
                                            "src": "L3Stroke3.png"
                                        },
                                        {
                                            "children": [],
                                            "uuid": "c619cc80-c7c1-416b-8236-acc05ca399c5",
                                            "type": "IMAGE",
                                            "name": "Grad",
                                            "tint": 16777215,
                                            "x": 515,
                                            "y": 891,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 874,
                                            "height": 77,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "blendMode": 0,
                                            "mask": null,
                                            "visible": true,
                                            "src": "L3_Grad_name.png"
                                        },
                                        {
                                            "children": [],
                                            "uuid": "6f9a2488-e5f8-4549-892d-20815a20e20d",
                                            "type": "RECTANGLE",
                                            "name": "HeaderMASK",
                                            "x": 766.3385149351064,
                                            "y": 837,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 646.9999999999999,
                                            "height": 42,
                                            "alpha": 1,
                                            "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}",
                                            "autoFollow": "43204316-ec4e-471d-af2f-7169c8540630",
                                            "autoFollowPadding": 536,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 646.9999999999999,
                                                "height": 42,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 10334,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.27,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "91ff368e-2550-4dc5-8ddc-6ec7aadbff0a",
                                                    "type": "RECTANGLE",
                                                    "name": "DarkBlueBG",
                                                    "x": 770,
                                                    "y": 837,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 266,
                                                    "height": 42,
                                                    "alpha": 1,
                                                    "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n  const padding = 20; \n   const text_node_name = 'Header'\n   \n// Executed when graphic\n// updated each frame\nfunction update(item, frame, scene) { \nlet text = hyperAPI.getItemByName(scene.name, text_node_name); \nitem.width = (text.x - item.x) + text.width + padding;\n}\n",
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 266,
                                                        "height": 42,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 10334,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.27,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "624b4d39-ebb5-4669-b6ca-e88bc9ca0bd4",
                                                    "type": "TEXT",
                                                    "name": "Header",
                                                    "x": 777,
                                                    "y": 842,
                                                    "control": {
                                                        "id": "5",
                                                        "name": "Header Text",
                                                        "data": {
                                                            "variable": "Subtitle",
                                                            "variables": [
                                                                {
                                                                    "template": "Name",
                                                                    "field": "Subtitle"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 0,
                                                    "height": 29,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "0",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 75,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#fcfcfc",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Medium.otf",
                                                        "_fontSize": 26,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                }
                                            ],
                                            "uuid": "64bcd3c4-a3a5-4ad1-9001-423629561f9a",
                                            "type": "GROUP",
                                            "name": "HeaderGRP",
                                            "x": -0.6614850648935544,
                                            "y": 46,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 277.2027203409309,
                                            "height": 40.47837764736744,
                                            "alpha": 1,
                                            "autoFollow": "43204316-ec4e-471d-af2f-7169c8540630",
                                            "autoFollowPadding": -231,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": "6f9a2488-e5f8-4549-892d-20815a20e20d",
                                            "visible": false
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "617531fc-62d4-4b9f-b2b0-299fdfcaabc8",
                                                    "type": "RECTANGLE",
                                                    "name": "BadgeMask",
                                                    "x": 554,
                                                    "y": 877,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 103,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 103,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.27,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "1f4db8da-ca3d-447d-b6bd-45cf9fe7b288",
                                                    "type": "RECTANGLE",
                                                    "name": "BadgeBG",
                                                    "x": 554,
                                                    "y": 877,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 103,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 103,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.27,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "95b56385-3579-4293-b3fb-2c8778156186",
                                                    "type": "IMAGE",
                                                    "name": "BADGE",
                                                    "tint": 16777215,
                                                    "x": 422,
                                                    "y": 856,
                                                    "control": {
                                                        "id": "1",
                                                        "name": "Team Badge",
                                                        "data": {
                                                            "variable": "Team Badge",
                                                            "variables": [
                                                                {
                                                                    "template": "Name",
                                                                    "field": "Team Badge"
                                                                },
                                                                {
                                                                    "template": "Player Scored",
                                                                    "field": "Team Badge"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 281.1503267973856,
                                                    "height": 283,
                                                    "alpha": 1,
                                                    "rotation": -0.3490657255854705,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.41962735342893376,
                                                        "y": 0.4223880597014925
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "617531fc-62d4-4b9f-b2b0-299fdfcaabc8",
                                                    "visible": true,
                                                    "src": "Arsenal.png"
                                                }
                                            ],
                                            "uuid": "b3386003-e1be-41cc-a227-a93aa6cf2358",
                                            "type": "GROUP",
                                            "name": "BadgeGRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 124.33851493510645,
                                            "height": 77.10167170927127,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "b7e9b022-7d19-472b-86b7-b9eff513c856",
                                            "type": "RECTANGLE",
                                            "name": "BlueBG",
                                            "x": 657,
                                            "y": 877,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 751,
                                            "height": 80,
                                            "alpha": 1,
                                            "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}",
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 751,
                                                "height": 80,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 7122399,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.27,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "720e61c2-d2b4-4ebd-a35a-fb607cac149c",
                                                    "type": "TEXT",
                                                    "name": "FirstName",
                                                    "x": 776,
                                                    "y": 896,
                                                    "control": {
                                                        "id": "3",
                                                        "name": "First Name",
                                                        "data": {
                                                            "variable": "First Name Uppercase",
                                                            "variables": [
                                                                {
                                                                    "template": "Name",
                                                                    "field": "First Name Uppercase"
                                                                },
                                                                {
                                                                    "template": "Player Scored",
                                                                    "field": "First Name Uppercase"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 139,
                                                    "height": 42,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "0",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9928571428571429,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 73,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#fcfcfc",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Medium.otf",
                                                        "_fontSize": 40,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "PLAYER",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "c3d7204b-0b38-4163-9069-acec29c1736c",
                                                    "type": "TEXT",
                                                    "name": "LastName",
                                                    "x": 927,
                                                    "y": 896,
                                                    "control": {
                                                        "id": "4",
                                                        "name": "Last Name",
                                                        "data": {
                                                            "variable": "Last Name Uppercase",
                                                            "variables": [
                                                                {
                                                                    "template": "Name",
                                                                    "field": "Last Name Uppercase"
                                                                },
                                                                {
                                                                    "template": "Player Scored",
                                                                    "field": "Last Name Uppercase"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 88,
                                                    "height": 42,
                                                    "alpha": 1,
                                                    "autoFollow": "720e61c2-d2b4-4ebd-a35a-fb607cac149c",
                                                    "autoFollowPadding": 12,
                                                    "maxWidth": "418",
                                                    "anchor": {
                                                        "x": "0",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9887640449438202,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 75,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#fcfcfc",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Bold.otf",
                                                        "_fontSize": 40,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "TWO",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                                    "type": "RECTANGLE",
                                                    "name": "NameMASK",
                                                    "x": 657,
                                                    "y": 877,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 759,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}",
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 759,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 7122399,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.27,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "7b3ef1f0-d97d-4d7c-a41d-6a878cba3faa",
                                                    "type": "RECTANGLE",
                                                    "name": "YELLOWCARD1",
                                                    "x": 1336,
                                                    "y": 973,
                                                    "control": {
                                                        "id": "5",
                                                        "name": "Card"
                                                    },
                                                    "width": 39,
                                                    "height": 55,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 39,
                                                        "height": 55,
                                                        "radius": 8,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16312092,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "8a92fc42-4288-4146-84aa-0bafd89a0a5a",
                                                    "type": "RECTANGLE",
                                                    "name": "YELLOWCARD2",
                                                    "x": 1336,
                                                    "y": 968,
                                                    "control": {
                                                        "id": "5",
                                                        "name": "Card"
                                                    },
                                                    "width": 39,
                                                    "height": 55,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 39,
                                                        "height": 55,
                                                        "radius": 8,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16312092,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "a1ce6338-0ad6-4550-8076-b4499b0704b6",
                                                    "type": "RECTANGLE",
                                                    "name": "REDCARD",
                                                    "x": 1336,
                                                    "y": 888,
                                                    "control": {
                                                        "id": "5",
                                                        "name": "Card"
                                                    },
                                                    "width": 39,
                                                    "height": 55,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 39,
                                                        "height": 55,
                                                        "radius": 8,
                                                        "type": 4
                                                    },
                                                    "fillColor": 13632027,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                                    "visible": true
                                                }
                                            ],
                                            "uuid": "0f133c67-ba17-4030-86ee-31fd9e44b1da",
                                            "type": "GROUP",
                                            "name": "NameGRP",
                                            "x": -8.661485064893554,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 603.9642944335938,
                                            "height": 55,
                                            "alpha": 1,
                                            "autoFollow": "43204316-ec4e-471d-af2f-7169c8540630",
                                            "autoFollowPadding": -239,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "7b6c42a2-cdb0-4500-99b0-26715edfba47",
                                                    "type": "RECTANGLE",
                                                    "name": "WhiteBG",
                                                    "x": 656,
                                                    "y": 877,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 103,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 103,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.27,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "5d8112ee-d410-46ba-8680-c930380fa55a",
                                                    "type": "TEXT",
                                                    "name": "NUMBER",
                                                    "x": 700,
                                                    "y": "890",
                                                    "control": {
                                                        "id": "2",
                                                        "name": "Shirt Number",
                                                        "data": {
                                                            "variable": "Shirt Number",
                                                            "variables": [
                                                                {
                                                                    "template": "Name",
                                                                    "field": "Shirt Number"
                                                                },
                                                                {
                                                                    "template": "Player Scored",
                                                                    "field": "Shirt Number"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 29,
                                                    "height": 53,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "0.5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9666666666666667,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 52,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#000000",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 48,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "2",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "bc6a08dd-efc8-4640-a04b-36a670b34302",
                                                    "type": "RECTANGLE",
                                                    "name": "BlueWipe",
                                                    "x": 550,
                                                    "y": 877,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 103,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 103,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 10334,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.27,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                }
                                            ],
                                            "uuid": "43204316-ec4e-471d-af2f-7169c8540630",
                                            "type": "GROUP",
                                            "name": "NumberGRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 230.33851493510645,
                                            "height": 77.10167170927127,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": "37ae6058-ee1f-4cf2-992e-816ecef0d9ab",
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "37ae6058-ee1f-4cf2-992e-816ecef0d9ab",
                                            "type": "RECTANGLE",
                                            "name": "NumberMASK",
                                            "x": 656,
                                            "y": 877,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 105,
                                            "height": 80,
                                            "alpha": 1,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 105,
                                                "height": 80,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 8311585,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.27,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "ffa001f2-6a0a-44e6-87aa-6fdf50b76aaa",
                                            "type": "TEXT",
                                            "name": "GoalTime",
                                            "x": 1388,
                                            "y": 896,
                                            "control": {
                                                "id": "6",
                                                "name": "Goal Time",
                                                "data": {
                                                    "variables": [
                                                        {
                                                            "template": "Player Scored",
                                                            "field": "Goal Time"
                                                        }
                                                    ]
                                                }
                                            },
                                            "width": 35,
                                            "height": 43,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": "1",
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 0.9722222222222222,
                                                "y": 1
                                            },
                                            "blendMode": 0,
                                            "mask": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                            "visible": true,
                                            "style": {
                                                "styleID": 41,
                                                "_align": "left",
                                                "_breakWords": false,
                                                "_dropShadow": false,
                                                "_dropShadowAlpha": 1,
                                                "_dropShadowAngle": 0.5235987755982988,
                                                "_dropShadowBlur": 0,
                                                "_dropShadowColor": "black",
                                                "_dropShadowDistance": 5,
                                                "_fill": "#00285e",
                                                "_fillGradientType": 0,
                                                "_fillGradientStops": [],
                                                "_fontFamily": "Gotham-Medium.otf",
                                                "_fontSize": 40,
                                                "_fontStyle": "normal",
                                                "_fontVariant": "normal",
                                                "_fontWeight": "normal",
                                                "_letterSpacing": 0,
                                                "_lineHeight": 0,
                                                "_lineJoin": "miter",
                                                "_miterLimit": 10,
                                                "_padding": 5,
                                                "_stroke": "black",
                                                "_strokeThickness": 0,
                                                "_textBaseline": "alphabetic",
                                                "_trim": false,
                                                "_whiteSpace": "pre",
                                                "_wordWrap": false,
                                                "_wordWrapWidth": 100,
                                                "_leading": 0
                                            },
                                            "text": "2'",
                                            "text_transform": {
                                                "uppercase": false
                                            },
                                            "maxHeight": 0
                                        }
                                    ],
                                    "uuid": "2a803f10-57c8-49cc-81a2-317d2db11aff",
                                    "type": "GROUP",
                                    "name": "L3_GRP",
                                    "x": 1453,
                                    "y": 0,
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 916,
                                    "height": 91,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": true
                                }
                            ],
                            "uuid": "fc5c538c-86c7-4c96-bcc4-beaf0bfd9b9c",
                            "type": "GROUP",
                            "name": "Root",
                            "x": 0,
                            "y": 0,
                            "control": {
                                "id": 0,
                                "name": ""
                            },
                            "width": 916,
                            "height": 91,
                            "alpha": 1,
                            "maxWidth": 0,
                            "anchorX": 0,
                            "anchorY": 0,
                            "skew": {
                                "x": 0,
                                "y": 0
                            },
                            "scale": {
                                "x": 1,
                                "y": 1
                            },
                            "mask": null,
                            "visible": true
                        },
                        {
                            "children": [],
                            "uuid": "1d98b135-c776-420d-8b94-260a145e1cb6",
                            "type": "GROUP",
                            "name": "SCRIPT",
                            "x": 0,
                            "y": 0,
                            "control": {
                                "id": 0,
                                "name": "",
                                "editables": []
                            },
                            "width": 0,
                            "height": 0,
                            "alpha": 1,
                            "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(item, frame, scene) {\n  checkHeader(scene);\n  checkNumber(scene);\n}\n\nfunction checkHeader(scene) {\n    let HEADER = hyperAPI.getItemByName(scene.name, 'Header');\n  let HEADER_GRP = hyperAPI.getItemByName(scene.name, 'HeaderGRP');\n  HEADER_GRP.visible = true; \n  if (HEADER.text === '') {\n   HEADER_GRP.visible = false; \n  }\n}\n\nfunction checkNumber(scene) {\n   let WHITE_BG = hyperAPI.getItemByName(scene.name, 'WhiteBG');\n  let NUMBER = hyperAPI.getItemByName(scene.name, 'NUMBER');\n  let NUMBER_GRP = hyperAPI.getItemByName(scene.name, 'NumberGRP');\n  NUMBER_GRP.visible = true; \n  NUMBER.visible = true;\n  WHITE_BG.visible = true;\n  if (NUMBER.text === '') {\n   NUMBER_GRP.visible = false; \n    NUMBER.visible = false;\n    WHITE_BG.visible = false;\n  }\n}",
                            "maxWidth": 0,
                            "anchorX": 0,
                            "anchorY": 0,
                            "skew": {
                                "x": 0,
                                "y": 0
                            },
                            "scale": {
                                "x": 1,
                                "y": 1
                            },
                            "mask": null,
                            "visible": true
                        }
                    ],
                    "uuid": "d5e9b400-21b0-4f14-8e64-33e541586d9e",
                    "type": "SCENE",
                    "name": "Root",
                    "x": 0,
                    "y": 0,
                    "control": {
                        "id": 0,
                        "name": ""
                    },
                    "alpha": 1,
                    "maxWidth": 0,
                    "skew": {
                        "x": 0,
                        "y": 0
                    },
                    "scale": {
                        "x": 1,
                        "y": 1
                    },
                    "mask": null,
                    "visible": false
                }
            }
        },
        {
            "_id": "61828977a4737fae26881059",
            "name": "L3_HalfFullTime",
            "project": "6182817751b7a0dd4843297a",
            "__v": 0,
            "data": {
                "name": "L3_HalfFullTime",
                "football": {
                    "templates": [
                        {
                            "name": "HT Score"
                        },
                        {
                            "name": "FT Score"
                        }
                    ]
                },
                "width": 1920,
                "height": 1080,
                "folder": "UNIVERSAL",
                "aspect": "16x9",
                "timelines": [
                    {
                        "name": "IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": [
                                    {
                                        "property": "TIMELINE",
                                        "keyframes": [
                                            {
                                                "frame": 5,
                                                "action": "PLAY",
                                                "name": "T3"
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "938f89bb-e7fa-4ae0-abd8-9d7b10f79eec",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1629,
                                                "curve": [
                                                    0.65703125,
                                                    0.40718750000000004,
                                                    1,
                                                    1
                                                ],
                                                "type": "ease-in",
                                                "time": 20
                                            },
                                            {
                                                "frame": 25,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.43703125,
                                                    1.0271875
                                                ],
                                                "type": "ease-out",
                                                "time": 500
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "8c31881b-8aab-436b-8c9e-31d36903e883",
                                "animations": [
                                    {
                                        "property": "width",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0.00980392156862745,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 19,
                                                "value": 0.00980392156862745,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 380
                                            },
                                            {
                                                "frame": 33,
                                                "value": 102,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 660
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 492,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "a2827d9e-46ce-445a-b993-e2bc4d06d0a6",
                                "animations": [
                                    {
                                        "property": "width",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0.00980392156862745,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 19,
                                                "value": 0.00980392156862745,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 380
                                            },
                                            {
                                                "frame": 33,
                                                "value": 102,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 660
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1450,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "d19a4852-4e24-4ac3-a0b8-c2ae6f8850ec",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 49,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 19,
                                                "value": 49,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 380
                                            },
                                            {
                                                "frame": 33,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.316978759765625,
                                                    0.93107666015625
                                                ],
                                                "type": "ease-out",
                                                "time": 660
                                            },
                                            {
                                                "frame": 175,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 3500
                                            },
                                            {
                                                "frame": 185,
                                                "value": 50,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 3700
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "e7cc1160-af45-468a-b896-fb4de06f6869",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1316,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 19,
                                                "value": 1316,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 380
                                            },
                                            {
                                                "frame": 33,
                                                "value": 886,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.209000244140625,
                                                    0.8135003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 660
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "668c8d56-1229-4fdc-867c-bdc8281fce3c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 603,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 19,
                                                "value": 603,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 380
                                            },
                                            {
                                                "frame": 33,
                                                "value": 1035,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.229000244140625,
                                                    0.8635003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 660
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "75a22d85-b516-4f6d-a8c8-fa61b5d79649",
                                "animations": [
                                    {
                                        "property": "width",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 9,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 180
                                            },
                                            {
                                                "frame": 21,
                                                "value": 128,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 420
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "c7370d80-7884-42dc-91e4-9d719fc114de",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "34187d8f-13cb-4eb1-b75f-93ec63044393",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 774,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "8aa0b812-e10c-4849-b882-dbcbdec0fdae",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 50,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 186,
                                                "value": 50,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 3720
                                            },
                                            {
                                                "frame": 197,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 3940
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "7d52c16b-c405-4ed0-945d-1f9676a0aac8",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 197,
                        "loop": false,
                        "fps": 50,
                        "id": "917bd875-42f3-42c3-a9f4-9ade18f5b7f6"
                    },
                    {
                        "name": "OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "938f89bb-e7fa-4ae0-abd8-9d7b10f79eec",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 7,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.039000244140625,
                                                    0.9035003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 140
                                            },
                                            {
                                                "frame": 25,
                                                "value": 1609,
                                                "curve": [
                                                    1,
                                                    0.06350036621093746,
                                                    1,
                                                    1
                                                ],
                                                "type": "ease-in",
                                                "time": 500
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "a2827d9e-46ce-445a-b993-e2bc4d06d0a6",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1450,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 10,
                                                "value": 1343,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 200
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "e7cc1160-af45-468a-b896-fb4de06f6869",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 19,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 380
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "668c8d56-1229-4fdc-867c-bdc8281fce3c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 19,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 380
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "8c31881b-8aab-436b-8c9e-31d36903e883",
                                "animations": [
                                    {
                                        "property": "width",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 102,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 10,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 200
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "75a22d85-b516-4f6d-a8c8-fa61b5d79649",
                                "animations": [
                                    {
                                        "property": "width",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 128,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "7d52c16b-c405-4ed0-945d-1f9676a0aac8",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 50,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 30,
                        "loop": false,
                        "fps": 50,
                        "id": "66e077b8-be40-4d65-a748-112c39441005"
                    },
                    {
                        "name": "T3",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "0c1c41a0-e90f-4ee6-b746-5738e21e849b",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1380,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 150,
                                                "value": 1351,
                                                "curve": [
                                                    0.42,
                                                    0,
                                                    0.58,
                                                    1
                                                ],
                                                "type": "ease-in-out",
                                                "time": 3000
                                            },
                                            {
                                                "frame": 300,
                                                "value": 1380,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 6000
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "af7d68fe-572c-4e1f-8058-2986005852ef",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 340,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 150,
                                                "value": 375,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 3000
                                            },
                                            {
                                                "frame": 300,
                                                "value": 340,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 6000
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 300,
                        "loop": true,
                        "fps": 50,
                        "id": "d3ff1df9-82eb-4bda-b4bc-a2e3107bb85d"
                    },
                    {
                        "name": "UPDATE-IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "c7370d80-7884-42dc-91e4-9d719fc114de",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "34187d8f-13cb-4eb1-b75f-93ec63044393",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 905,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 8,
                                                "value": 1039,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 160
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "e7cc1160-af45-468a-b896-fb4de06f6869",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1256,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 17,
                                                "value": 886,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.199000244140625,
                                                    0.7035003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 340
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "668c8d56-1229-4fdc-867c-bdc8281fce3c",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 604,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 17,
                                                "value": 1035,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.229000244140625,
                                                    0.8035003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 340
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "7d52c16b-c405-4ed0-945d-1f9676a0aac8",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 50,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 17,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 340
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 25,
                        "loop": false,
                        "fps": 50,
                        "id": "37e32c5f-6515-4ded-9979-24f6afc1be40"
                    },
                    {
                        "name": "UPDATE-OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "c7370d80-7884-42dc-91e4-9d719fc114de",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "34187d8f-13cb-4eb1-b75f-93ec63044393",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 774,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 10,
                                                "value": 774,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 200
                                            },
                                            {
                                                "frame": 14,
                                                "value": 905,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "7d52c16b-c405-4ed0-945d-1f9676a0aac8",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 50,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 14,
                        "loop": false,
                        "fps": 50,
                        "id": "ee9bd279-9fab-4249-8fb0-b2933710e3ed"
                    },
                    {
                        "name": "ALT-UPDATE-IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "d19a4852-4e24-4ac3-a0b8-c2ae6f8850ec",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 49,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 1,
                                                "value": 49,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 300
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 15,
                        "loop": false,
                        "fps": 50,
                        "id": "8bc4f994-01ae-4600-b3cc-a572cae6b2fa"
                    },
                    {
                        "name": "ALT-UPDATE-OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "d19a4852-4e24-4ac3-a0b8-c2ae6f8850ec",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 49,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 300
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 15,
                        "loop": false,
                        "fps": 50,
                        "id": "03742737-3e86-4183-a7cf-b4deafd33316"
                    }
                ],
                "scene": {
                    "children": [
                        {
                            "children": [
                                {
                                    "children": [],
                                    "uuid": "3cce9d50-7839-4fea-8f89-dce0cbcac446",
                                    "type": "RECTANGLE",
                                    "name": "Background",
                                    "x": "0",
                                    "y": "0",
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1921,
                                    "height": 1189,
                                    "alpha": 1,
                                    "graphicsData": {
                                        "x": 0,
                                        "y": 0,
                                        "width": 1921,
                                        "height": 1189,
                                        "radius": 0,
                                        "type": 4
                                    },
                                    "fillColor": 14355009,
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": false
                                },
                                {
                                    "children": [],
                                    "uuid": "bb681416-7e05-480f-84a5-285c14f9137d",
                                    "type": "IMAGE",
                                    "name": "REFERENCE",
                                    "tint": 16777215,
                                    "x": "0",
                                    "y": "0",
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1920,
                                    "height": 1080,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchor": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "blendMode": 0,
                                    "mask": null,
                                    "visible": false,
                                    "src": "MatchInfoL3.png"
                                },
                                {
                                    "children": [
                                        {
                                            "children": [
                                                {
                                                    "children": [
                                                        {
                                                            "children": [],
                                                            "uuid": "4646cb88-2526-4190-b3d2-24a887f85bf1",
                                                            "type": "RECTANGLE",
                                                            "name": "DarkBlue_Bar",
                                                            "x": 972,
                                                            "y": 836,
                                                            "control": {
                                                                "id": 0,
                                                                "name": ""
                                                            },
                                                            "width": 164,
                                                            "height": 43,
                                                            "alpha": 1,
                                                            "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}\n// Executed when graphic\n// updated each frame\nconst padding = 40; \nconst text_node_name = 'Period'\n\nfunction update(item, frame, scene) { \nlet text = hyperAPI.getItemByName(scene.name, text_node_name); \nitem.width = (text.x - item.x) + text.width + padding; }",
                                                            "graphicsData": {
                                                                "x": 0,
                                                                "y": 0,
                                                                "width": 164,
                                                                "height": 43,
                                                                "radius": 0,
                                                                "type": 4
                                                            },
                                                            "fillColor": 10334,
                                                            "maxWidth": 0,
                                                            "anchorX": 0.5,
                                                            "anchorY": 0,
                                                            "skew": {
                                                                "x": -0.28,
                                                                "y": 0
                                                            },
                                                            "scale": {
                                                                "x": 1,
                                                                "y": 1
                                                            },
                                                            "mask": null,
                                                            "visible": true
                                                        },
                                                        {
                                                            "children": [],
                                                            "uuid": "130cee01-68f9-44d3-b237-21264e6db5f0",
                                                            "type": "TEXT",
                                                            "name": "Period",
                                                            "x": 965,
                                                            "y": 843,
                                                            "control": {
                                                                "id": "7",
                                                                "name": "Period Text",
                                                                "data": {
                                                                    "variables": [
                                                                        {
                                                                            "template": "HT Score",
                                                                            "field": "Half Text"
                                                                        },
                                                                        {
                                                                            "template": "FT Score",
                                                                            "field": "Half Text"
                                                                        }
                                                                    ]
                                                                }
                                                            },
                                                            "width": 131,
                                                            "height": 26,
                                                            "alpha": 1,
                                                            "maxWidth": 0,
                                                            "anchor": {
                                                                "x": ".5",
                                                                "y": 0
                                                            },
                                                            "skew": {
                                                                "x": 0,
                                                                "y": 0
                                                            },
                                                            "scale": {
                                                                "x": 0.9924242424242424,
                                                                "y": 1
                                                            },
                                                            "blendMode": 0,
                                                            "mask": null,
                                                            "visible": true,
                                                            "style": {
                                                                "styleID": 60,
                                                                "_align": "left",
                                                                "_breakWords": false,
                                                                "_dropShadow": false,
                                                                "_dropShadowAlpha": 1,
                                                                "_dropShadowAngle": 0.5235987755982988,
                                                                "_dropShadowBlur": 0,
                                                                "_dropShadowColor": "black",
                                                                "_dropShadowDistance": 5,
                                                                "_fill": "#ffffff",
                                                                "_fillGradientType": 0,
                                                                "_fillGradientStops": [],
                                                                "_fontFamily": "Gotham-Bold.otf",
                                                                "_fontSize": 24,
                                                                "_fontStyle": "normal",
                                                                "_fontVariant": "normal",
                                                                "_fontWeight": "normal",
                                                                "_letterSpacing": 0,
                                                                "_lineHeight": 0,
                                                                "_lineJoin": "miter",
                                                                "_miterLimit": 10,
                                                                "_padding": 5,
                                                                "_stroke": "black",
                                                                "_strokeThickness": 0,
                                                                "_textBaseline": "alphabetic",
                                                                "_trim": false,
                                                                "_whiteSpace": "pre",
                                                                "_wordWrap": false,
                                                                "_wordWrapWidth": 100,
                                                                "_leading": 0
                                                            },
                                                            "text": "FULL TIME",
                                                            "text_transform": {
                                                                "uppercase": true
                                                            },
                                                            "maxHeight": 0
                                                        }
                                                    ],
                                                    "uuid": "8aa0b812-e10c-4849-b882-dbcbdec0fdae",
                                                    "type": "GROUP",
                                                    "name": "TopTab2 GRP",
                                                    "x": 0,
                                                    "y": 0,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 175.88329288825685,
                                                    "height": 41.32538384736313,
                                                    "alpha": 1,
                                                    "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}",
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": "9ddff7d1-eb40-4d72-856d-4421ed0a195d",
                                                    "visible": true
                                                },
                                                {
                                                    "children": [
                                                        {
                                                            "children": [],
                                                            "uuid": "adbc9fa6-1971-4fe1-a124-bac26d321c9c",
                                                            "type": "RECTANGLE",
                                                            "name": "DarkBlue_Bar",
                                                            "x": 972,
                                                            "y": 836,
                                                            "control": {
                                                                "id": 0,
                                                                "name": ""
                                                            },
                                                            "width": 224,
                                                            "height": 43,
                                                            "alpha": 1,
                                                            "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}\n// Executed when graphic\n// updated each frame\nconst padding = 40; \nconst text_node_name = 'Competition'\n\nfunction update(item, frame, scene) { \nlet text = hyperAPI.getItemByName(scene.name, text_node_name); \nitem.width = (text.x - item.x) + text.width + padding; }",
                                                            "graphicsData": {
                                                                "x": 0,
                                                                "y": 0,
                                                                "width": 224,
                                                                "height": 43,
                                                                "radius": 0,
                                                                "type": 4
                                                            },
                                                            "fillColor": 10334,
                                                            "maxWidth": 0,
                                                            "anchorX": 0.5,
                                                            "anchorY": 0,
                                                            "skew": {
                                                                "x": -0.28,
                                                                "y": 0
                                                            },
                                                            "scale": {
                                                                "x": 1,
                                                                "y": 1
                                                            },
                                                            "mask": null,
                                                            "visible": true
                                                        },
                                                        {
                                                            "children": [],
                                                            "uuid": "738fbb2b-80cc-4566-af44-b6553df7fce2",
                                                            "type": "TEXT",
                                                            "name": "Competition",
                                                            "x": 965,
                                                            "y": 843,
                                                            "control": {
                                                                "id": "6",
                                                                "name": "Competition Text",
                                                                "data": {
                                                                    "variables": [
                                                                        {
                                                                            "template": "HT Score",
                                                                            "field": "Competition"
                                                                        },
                                                                        {
                                                                            "template": "FT Score",
                                                                            "field": "Competition"
                                                                        }
                                                                    ]
                                                                }
                                                            },
                                                            "width": 191,
                                                            "height": 26,
                                                            "alpha": 1,
                                                            "maxWidth": 0,
                                                            "anchor": {
                                                                "x": ".5",
                                                                "y": 0
                                                            },
                                                            "skew": {
                                                                "x": 0,
                                                                "y": 0
                                                            },
                                                            "scale": {
                                                                "x": 0.9947916666666666,
                                                                "y": 1
                                                            },
                                                            "blendMode": 0,
                                                            "mask": null,
                                                            "visible": true,
                                                            "style": {
                                                                "styleID": 60,
                                                                "_align": "left",
                                                                "_breakWords": false,
                                                                "_dropShadow": false,
                                                                "_dropShadowAlpha": 1,
                                                                "_dropShadowAngle": 0.5235987755982988,
                                                                "_dropShadowBlur": 0,
                                                                "_dropShadowColor": "black",
                                                                "_dropShadowDistance": 5,
                                                                "_fill": "#ffffff",
                                                                "_fillGradientType": 0,
                                                                "_fillGradientStops": [],
                                                                "_fontFamily": "Gotham-Bold.otf",
                                                                "_fontSize": 24,
                                                                "_fontStyle": "normal",
                                                                "_fontVariant": "normal",
                                                                "_fontWeight": "normal",
                                                                "_letterSpacing": 0,
                                                                "_lineHeight": 0,
                                                                "_lineJoin": "miter",
                                                                "_miterLimit": 10,
                                                                "_padding": 5,
                                                                "_stroke": "black",
                                                                "_strokeThickness": 0,
                                                                "_textBaseline": "alphabetic",
                                                                "_trim": false,
                                                                "_whiteSpace": "pre",
                                                                "_wordWrap": false,
                                                                "_wordWrapWidth": 100,
                                                                "_leading": 0
                                                            },
                                                            "text": "Premier League",
                                                            "text_transform": {
                                                                "uppercase": true
                                                            },
                                                            "maxHeight": 0
                                                        }
                                                    ],
                                                    "uuid": "d19a4852-4e24-4ac3-a0b8-c2ae6f8850ec",
                                                    "type": "GROUP",
                                                    "name": "TopTab1 GRP",
                                                    "x": 0,
                                                    "y": 50,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 235.88329288825685,
                                                    "height": 41.32538384736313,
                                                    "alpha": 1,
                                                    "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}",
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": "9ddff7d1-eb40-4d72-856d-4421ed0a195d",
                                                    "visible": true
                                                }
                                            ],
                                            "uuid": "7d52c16b-c405-4ed0-945d-1f9676a0aac8",
                                            "type": "GROUP",
                                            "name": "TopTabs_GRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 175.88329288825685,
                                            "height": 41.32538384736313,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "0c1c41a0-e90f-4ee6-b746-5738e21e849b",
                                            "type": "IMAGE",
                                            "name": "Stroke",
                                            "tint": 16777215,
                                            "x": 1380,
                                            "y": 889,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 207,
                                            "height": 78,
                                            "alpha": "0.8",
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "blendMode": 0,
                                            "mask": null,
                                            "visible": true,
                                            "src": "L3Stroke3.png"
                                        },
                                        {
                                            "children": [],
                                            "uuid": "af7d68fe-572c-4e1f-8058-2986005852ef",
                                            "type": "IMAGE",
                                            "name": "Grad_Bar",
                                            "tint": 16777215,
                                            "x": 340,
                                            "y": 895,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 1186,
                                            "height": 77,
                                            "alpha": "1",
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "blendMode": 0,
                                            "mask": null,
                                            "visible": true,
                                            "src": "L3_Grad.png"
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "c6cbe865-f84b-4789-b8ee-9b0b72fb6e99",
                                                    "type": "RECTANGLE",
                                                    "name": "WhiteBG",
                                                    "x": 1450,
                                                    "y": 878,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 102,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 102,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "ef41d0ff-cb16-456f-a5b9-3433cc1c69ed",
                                                    "type": "IMAGE",
                                                    "name": "Away Badge",
                                                    "tint": 16777215,
                                                    "x": 1371,
                                                    "y": 852,
                                                    "control": {
                                                        "id": "5",
                                                        "name": "Away Badge",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "HT Score",
                                                                    "field": "Away Team Badge"
                                                                },
                                                                {
                                                                    "template": "FT Score",
                                                                    "field": "Home Team Badge"
                                                                },
                                                                {
                                                                    "template": "FT Score",
                                                                    "field": "Away Team Badge"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 284,
                                                    "height": 283.99999999999966,
                                                    "alpha": 1,
                                                    "rotation": "-0.27",
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1.868421052631579,
                                                        "y": 1.8562091503267952
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "src": "CREST_ManUTD.png"
                                                }
                                            ],
                                            "uuid": "ed5c39a3-d2c2-4666-88bb-d2a838a3d415",
                                            "type": "GROUP",
                                            "name": "AwayBadge_GRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 349.462646484375,
                                            "height": 349.462646484375,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": "a2827d9e-46ce-445a-b993-e2bc4d06d0a6",
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "ca1512c9-1173-4c3d-b9b0-0e84d8e66adb",
                                                    "type": "RECTANGLE",
                                                    "name": "WhiteBG",
                                                    "x": "390",
                                                    "y": 878,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 102,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 102,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "ce60f33e-bd0b-4b5b-ab01-23b8facc59cf",
                                                    "type": "IMAGE",
                                                    "name": "Home Badge",
                                                    "tint": 16777215,
                                                    "x": 262,
                                                    "y": 852,
                                                    "control": {
                                                        "id": "4",
                                                        "name": "Home Badge",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "HT Score",
                                                                    "field": "Home Team Badge"
                                                                },
                                                                {
                                                                    "template": "FT Score",
                                                                    "field": "Home Team Badge"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 284,
                                                    "height": 282.8288659793815,
                                                    "alpha": 1,
                                                    "rotation": "-0.27",
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.42388059701492536,
                                                        "y": 0.4221326357901216
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "src": "Manchester_City.png"
                                                }
                                            ],
                                            "uuid": "9f62c58d-cb22-4ca2-9921-50c321d2cd07",
                                            "type": "GROUP",
                                            "name": "HomeBadge_GRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 349.1502685546875,
                                            "height": 348.333984375,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": "8c31881b-8aab-436b-8c9e-31d36903e883",
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "8c31881b-8aab-436b-8c9e-31d36903e883",
                                            "type": "RECTANGLE",
                                            "name": "Logo_ChipL_MASK",
                                            "x": 492,
                                            "y": 878,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 102,
                                            "height": 80,
                                            "alpha": 1,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 102,
                                                "height": 80,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 16777215,
                                            "maxWidth": 0,
                                            "anchorX": 1,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.28,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "a2827d9e-46ce-445a-b993-e2bc4d06d0a6",
                                            "type": "RECTANGLE",
                                            "name": "Logo_ChipR_MASK",
                                            "x": 1450,
                                            "y": 878,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 102,
                                            "height": 80,
                                            "alpha": 1,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 102,
                                                "height": 80,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 16777215,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.28,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "9ddff7d1-eb40-4d72-856d-4421ed0a195d",
                                            "type": "RECTANGLE",
                                            "name": "TopTabMASK",
                                            "x": 521,
                                            "y": 819,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 878,
                                            "height": 60,
                                            "alpha": 1,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 878,
                                                "height": 60,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 14355009,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "86435b68-6113-4ef1-b44d-e4e671d14009",
                                            "type": "RECTANGLE",
                                            "name": "LightBlue_Bar",
                                            "x": 492,
                                            "y": 878,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 958,
                                            "height": 80,
                                            "alpha": 1,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 958,
                                                "height": 80,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 7122399,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.28,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "5a320a0e-fca7-4910-bd3e-ff332597fc16",
                                                    "type": "RECTANGLE",
                                                    "name": "White_BG",
                                                    "x": 905,
                                                    "y": 878,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 127,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 127,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "113df672-0131-4af0-9756-e6c0c11b8ce9",
                                                    "type": "TEXT",
                                                    "name": "Home Score",
                                                    "x": 929,
                                                    "y": 894,
                                                    "control": {
                                                        "id": "3",
                                                        "name": "Home Score",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "HT Score",
                                                                    "field": "Home Score"
                                                                },
                                                                {
                                                                    "template": "FT Score",
                                                                    "field": "Home Score"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 25,
                                                    "height": 42,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": ".5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9615384615384616,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 107,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#00285e",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Bold.otf",
                                                        "_fontSize": 40,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "0",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "a9423443-b960-4016-8c74-af64507db236",
                                                    "type": "TEXT",
                                                    "name": "Away Score",
                                                    "x": 990,
                                                    "y": 894,
                                                    "control": {
                                                        "id": "4",
                                                        "name": "Away Score",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "HT Score",
                                                                    "field": "Away Score"
                                                                },
                                                                {
                                                                    "template": "FT Score",
                                                                    "field": "Away Score"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 14,
                                                    "height": 42,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": ".5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9333333333333333,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 107,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#00285e",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Bold.otf",
                                                        "_fontSize": 40,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "1",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "b89b2d1b-f995-4f8b-a8b1-fa9a943c5b13",
                                                    "type": "TEXT",
                                                    "name": "ScoreDot",
                                                    "x": 960,
                                                    "y": 894,
                                                    "control": {
                                                        "id": "5",
                                                        "name": "ScoreDot/Versus"
                                                    },
                                                    "width": 15,
                                                    "height": 42,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": ".5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9375,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 106,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#00285e",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Medium.otf",
                                                        "_fontSize": 40,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "-",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "34187d8f-13cb-4eb1-b75f-93ec63044393",
                                                    "type": "RECTANGLE",
                                                    "name": "BlueWIPE",
                                                    "x": 774,
                                                    "y": 878,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 127,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 127,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 10334,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                }
                                            ],
                                            "uuid": "175a2b85-ac05-4085-9b58-b100a573ffce",
                                            "type": "GROUP",
                                            "name": "Score_GRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 280.10845188512906,
                                            "height": 76.88443506486169,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": "75a22d85-b516-4f6d-a8c8-fa61b5d79649",
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "e7cc1160-af45-468a-b896-fb4de06f6869",
                                                    "type": "TEXT",
                                                    "name": "Home Team",
                                                    "x": 886,
                                                    "y": 895,
                                                    "control": {
                                                        "id": "1",
                                                        "name": "HomeTeamName",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "HT Score",
                                                                    "field": "Home Team Name"
                                                                },
                                                                {
                                                                    "template": "FT Score",
                                                                    "field": "Home Team Name"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 159,
                                                    "height": 41,
                                                    "alpha": 1,
                                                    "maxWidth": "394",
                                                    "anchor": {
                                                        "x": "1",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.99375,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "937e5cba-eed9-41c3-a1d6-9a01e1916cf6",
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 69,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#ffffff",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Medium.otf",
                                                        "_fontSize": 38,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "HYPER XI",
                                                    "text_transform": {
                                                        "uppercase": true
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "937e5cba-eed9-41c3-a1d6-9a01e1916cf6",
                                                    "type": "RECTANGLE",
                                                    "name": "HomeTeamMASK",
                                                    "x": 481,
                                                    "y": 888,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 420,
                                                    "height": 60,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 420,
                                                        "height": 60,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 14355009,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "668c8d56-1229-4fdc-867c-bdc8281fce3c",
                                                    "type": "TEXT",
                                                    "name": "Away Team",
                                                    "x": 1035,
                                                    "y": 895,
                                                    "control": {
                                                        "id": "2",
                                                        "name": "AwayTeamName",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "HT Score",
                                                                    "field": "Away Team Name"
                                                                },
                                                                {
                                                                    "template": "FT Score",
                                                                    "field": "Away Team Name"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 194,
                                                    "height": 41,
                                                    "alpha": 1,
                                                    "maxWidth": "394",
                                                    "anchor": {
                                                        "x": "0",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9948717948717949,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "a3149530-12fa-4a40-8ef0-0c13320caf95",
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 72,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#ffffff",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Medium.otf",
                                                        "_fontSize": 38,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "STUDIOS XI",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "a3149530-12fa-4a40-8ef0-0c13320caf95",
                                                    "type": "RECTANGLE",
                                                    "name": "AwayTeamMASK",
                                                    "x": 1023,
                                                    "y": 888,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 420,
                                                    "height": 60,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 420,
                                                        "height": 60,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 14355009,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                }
                                            ],
                                            "uuid": "c7370d80-7884-42dc-91e4-9d719fc114de",
                                            "type": "GROUP",
                                            "name": "Text_GRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 511.943115234375,
                                            "height": 51,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        }
                                    ],
                                    "uuid": "938f89bb-e7fa-4ae0-abd8-9d7b10f79eec",
                                    "type": "GROUP",
                                    "name": "MainBar GRP",
                                    "x": 0,
                                    "y": 0,
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1247,
                                    "height": 136,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": true
                                },
                                {
                                    "children": [],
                                    "uuid": "75a22d85-b516-4f6d-a8c8-fa61b5d79649",
                                    "type": "RECTANGLE",
                                    "name": "ScoreMask",
                                    "x": 968,
                                    "y": 878,
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 128,
                                    "height": 80,
                                    "alpha": 1,
                                    "graphicsData": {
                                        "x": 0,
                                        "y": 0,
                                        "width": 128,
                                        "height": 80,
                                        "radius": 0,
                                        "type": 4
                                    },
                                    "fillColor": 8311585,
                                    "maxWidth": 0,
                                    "anchorX": 0.5,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": -0.28,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": true
                                }
                            ],
                            "uuid": "cde7635a-7f8b-4863-b83d-6c7f5c221095",
                            "type": "GROUP",
                            "name": "Root",
                            "x": 0,
                            "y": 0,
                            "control": {
                                "id": 0,
                                "name": ""
                            },
                            "width": 1247,
                            "height": 136,
                            "alpha": 1,
                            "maxWidth": 0,
                            "anchorX": 0,
                            "anchorY": 0,
                            "skew": {
                                "x": 0,
                                "y": 0
                            },
                            "scale": {
                                "x": 1,
                                "y": 1
                            },
                            "mask": null,
                            "visible": true
                        }
                    ],
                    "uuid": "dfe2fe9e-26a2-4264-8e83-4ed0edeeb980",
                    "type": "SCENE",
                    "name": "Root",
                    "x": 0,
                    "y": 0,
                    "control": {
                        "id": 0,
                        "name": ""
                    },
                    "alpha": 1,
                    "maxWidth": 0,
                    "skew": {
                        "x": 0,
                        "y": 0
                    },
                    "scale": {
                        "x": 1,
                        "y": 1
                    },
                    "mask": null,
                    "visible": false
                }
            }
        },
        {
            "_id": "61828977a4737fae26881075",
            "name": "L3_MatchInfo",
            "project": "6182817751b7a0dd4843297a",
            "__v": 0,
            "data": {
                "name": "L3_MatchInfo",
                "football": {
                    "template": "LT-Score",
                    "templates": [
                        {
                            "name": "Score Update"
                        }
                    ]
                },
                "width": 1920,
                "height": 1080,
                "folder": "UNIVERSAL",
                "aspect": "16x9",
                "timelines": [
                    {
                        "name": "IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": [
                                    {
                                        "property": "TIMELINE",
                                        "keyframes": [
                                            {
                                                "frame": 5,
                                                "action": "PLAY",
                                                "name": "T3"
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "938f89bb-e7fa-4ae0-abd8-9d7b10f79eec",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1629,
                                                "curve": [
                                                    0.65703125,
                                                    0.40718750000000004,
                                                    1,
                                                    1
                                                ],
                                                "type": "ease-in",
                                                "time": 20
                                            },
                                            {
                                                "frame": 25,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.43703125,
                                                    1.0271875
                                                ],
                                                "type": "ease-out",
                                                "time": 500
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "8c31881b-8aab-436b-8c9e-31d36903e883",
                                "animations": [
                                    {
                                        "property": "width",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0.00980392156862745,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 19,
                                                "value": 0.00980392156862745,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 380
                                            },
                                            {
                                                "frame": 33,
                                                "value": 102,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 660
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 492,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "a2827d9e-46ce-445a-b993-e2bc4d06d0a6",
                                "animations": [
                                    {
                                        "property": "width",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0.00980392156862745,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 19,
                                                "value": 0.00980392156862745,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 380
                                            },
                                            {
                                                "frame": 33,
                                                "value": 102,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 660
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1450,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "d19a4852-4e24-4ac3-a0b8-c2ae6f8850ec",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 49,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 19,
                                                "value": 49,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 380
                                            },
                                            {
                                                "frame": 33,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.316978759765625,
                                                    0.93107666015625
                                                ],
                                                "type": "ease-out",
                                                "time": 660
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "e7cc1160-af45-468a-b896-fb4de06f6869",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1316,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 19,
                                                "value": 1316,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 380
                                            },
                                            {
                                                "frame": 33,
                                                "value": 886,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.209000244140625,
                                                    0.8135003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 660
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "668c8d56-1229-4fdc-867c-bdc8281fce3c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 603,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 19,
                                                "value": 603,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 380
                                            },
                                            {
                                                "frame": 33,
                                                "value": 1035,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.229000244140625,
                                                    0.8635003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 660
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "75a22d85-b516-4f6d-a8c8-fa61b5d79649",
                                "animations": [
                                    {
                                        "property": "width",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 9,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 180
                                            },
                                            {
                                                "frame": 21,
                                                "value": 128,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 420
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "c7370d80-7884-42dc-91e4-9d719fc114de",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "34187d8f-13cb-4eb1-b75f-93ec63044393",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 774,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": -1,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 34,
                        "loop": false,
                        "fps": 50,
                        "id": "6a17693f-cf21-4d6c-b881-4eab9f85dddf",
                        "seeking": false,
                        "prevFrame": 34
                    },
                    {
                        "name": "OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "938f89bb-e7fa-4ae0-abd8-9d7b10f79eec",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 7,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.039000244140625,
                                                    0.9035003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 140
                                            },
                                            {
                                                "frame": 25,
                                                "value": 1609,
                                                "curve": [
                                                    1,
                                                    0.06350036621093746,
                                                    1,
                                                    1
                                                ],
                                                "type": "ease-in",
                                                "time": 500
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "a2827d9e-46ce-445a-b993-e2bc4d06d0a6",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1450,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 10,
                                                "value": 1343,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 200
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "d19a4852-4e24-4ac3-a0b8-c2ae6f8850ec",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 49,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "e7cc1160-af45-468a-b896-fb4de06f6869",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 19,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 380
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "668c8d56-1229-4fdc-867c-bdc8281fce3c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 19,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 380
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "8c31881b-8aab-436b-8c9e-31d36903e883",
                                "animations": [
                                    {
                                        "property": "width",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 102,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 10,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 200
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "75a22d85-b516-4f6d-a8c8-fa61b5d79649",
                                "animations": [
                                    {
                                        "property": "width",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 128,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": -1,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 30,
                        "loop": false,
                        "fps": 50,
                        "id": "7f5ebdb3-931f-4509-8c3c-46beb29f0d58",
                        "seeking": false,
                        "prevFrame": 30
                    },
                    {
                        "name": "T3",
                        "playingState": 1,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "0c1c41a0-e90f-4ee6-b746-5738e21e849b",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1380,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 150,
                                                "value": 1351,
                                                "curve": [
                                                    0.42,
                                                    0,
                                                    0.58,
                                                    1
                                                ],
                                                "type": "ease-in-out",
                                                "time": 3000
                                            },
                                            {
                                                "frame": 300,
                                                "value": 1380,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 6000
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "af7d68fe-572c-4e1f-8058-2986005852ef",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 340,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 150,
                                                "value": 375,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 3000
                                            },
                                            {
                                                "frame": 300,
                                                "value": 340,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 6000
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 3468.5,
                        "frameNumber": 173,
                        "delta": 0,
                        "duration": 300,
                        "loop": true,
                        "fps": 50,
                        "id": "05cf9e4a-9685-457d-b8d4-1e618d885c89",
                        "prevFrame": 173
                    },
                    {
                        "name": "UPDATE-IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "c7370d80-7884-42dc-91e4-9d719fc114de",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "34187d8f-13cb-4eb1-b75f-93ec63044393",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 905,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 8,
                                                "value": 1039,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 160
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "e7cc1160-af45-468a-b896-fb4de06f6869",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1256,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 17,
                                                "value": 886,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.199000244140625,
                                                    0.7035003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 340
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "668c8d56-1229-4fdc-867c-bdc8281fce3c",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 604,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 17,
                                                "value": 1035,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.229000244140625,
                                                    0.8035003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 340
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "d19a4852-4e24-4ac3-a0b8-c2ae6f8850ec",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 55,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 17,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 340
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 25,
                        "loop": false,
                        "fps": 50,
                        "id": "ac252984-9c8c-432e-9af9-5b464b7b6b6f"
                    },
                    {
                        "name": "UPDATE-OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "c7370d80-7884-42dc-91e4-9d719fc114de",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "34187d8f-13cb-4eb1-b75f-93ec63044393",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 774,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 10,
                                                "value": 774,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 200
                                            },
                                            {
                                                "frame": 14,
                                                "value": 905,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "d19a4852-4e24-4ac3-a0b8-c2ae6f8850ec",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 55,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 14,
                        "loop": false,
                        "fps": 50,
                        "id": "41f6974f-75a3-4af8-8862-325087f39bb1"
                    },
                    {
                        "name": "ALT-UPDATE-IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "d19a4852-4e24-4ac3-a0b8-c2ae6f8850ec",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 49,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 1,
                                                "value": 49,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 300
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 15,
                        "loop": false,
                        "fps": 50,
                        "id": "c074d24a-8a7e-4cd3-8f47-759471b1a789"
                    },
                    {
                        "name": "ALT-UPDATE-OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "d19a4852-4e24-4ac3-a0b8-c2ae6f8850ec",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 49,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 300
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 15,
                        "loop": false,
                        "fps": 50,
                        "id": "e5aca983-62d6-4bdb-ab12-4d4c802d4727"
                    }
                ],
                "scene": {
                    "children": [
                        {
                            "children": [
                                {
                                    "children": [],
                                    "uuid": "3cce9d50-7839-4fea-8f89-dce0cbcac446",
                                    "type": "RECTANGLE",
                                    "name": "Background",
                                    "x": "0",
                                    "y": "0",
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1921,
                                    "height": 1189,
                                    "alpha": 1,
                                    "graphicsData": {
                                        "x": 0,
                                        "y": 0,
                                        "width": 1921,
                                        "height": 1189,
                                        "radius": 0,
                                        "type": 4
                                    },
                                    "fillColor": 14355009,
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": false
                                },
                                {
                                    "children": [],
                                    "uuid": "bb681416-7e05-480f-84a5-285c14f9137d",
                                    "type": "IMAGE",
                                    "name": "REFERENCE",
                                    "tint": 16777215,
                                    "x": "0",
                                    "y": "0",
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1920,
                                    "height": 1080,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchor": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "blendMode": 0,
                                    "mask": null,
                                    "visible": false,
                                    "src": "MatchInfoL3.png"
                                },
                                {
                                    "children": [
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "adbc9fa6-1971-4fe1-a124-bac26d321c9c",
                                                    "type": "RECTANGLE",
                                                    "name": "DarkBlue_Bar",
                                                    "x": 972,
                                                    "y": 836,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 253.00000000000003,
                                                    "height": 43,
                                                    "alpha": 1,
                                                    "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}\n// Executed when graphic\n// updated each frame\nconst padding = 40; \nconst text_node_name = 'TopTEXT'\n\nfunction update(item, frame, scene) { \nlet text = hyperAPI.getItemByName(scene.name, text_node_name); \nitem.width = (text.x - item.x) + text.width + padding; }",
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 224,
                                                        "height": 43,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 10334,
                                                    "maxWidth": 0,
                                                    "anchorX": 0.5,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1.1294642857142858,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "738fbb2b-80cc-4566-af44-b6553df7fce2",
                                                    "type": "TEXT",
                                                    "name": "TopTEXT",
                                                    "x": 965,
                                                    "y": 843,
                                                    "control": {
                                                        "id": "6",
                                                        "name": "TopText",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Score Update",
                                                                    "field": "Competition"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 220,
                                                    "height": 26,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": ".5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.995475113122172,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 60,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#ffffff",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 24,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0,
                                                        "original_size": 24
                                                    },
                                                    "text": "PREMIER LEAGUE",
                                                    "text_transform": {
                                                        "uppercase": true
                                                    },
                                                    "maxHeight": 0
                                                }
                                            ],
                                            "uuid": "d19a4852-4e24-4ac3-a0b8-c2ae6f8850ec",
                                            "type": "GROUP",
                                            "name": "TopTab GRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 264.88329288825685,
                                            "height": 41.32538384736313,
                                            "alpha": 1,
                                            "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}",
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": "9ddff7d1-eb40-4d72-856d-4421ed0a195d",
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "0c1c41a0-e90f-4ee6-b746-5738e21e849b",
                                            "type": "IMAGE",
                                            "name": "Stroke",
                                            "tint": 16777215,
                                            "x": 1355.5592281879194,
                                            "y": 889,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 207,
                                            "height": 78,
                                            "alpha": "0.8",
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "blendMode": 0,
                                            "mask": null,
                                            "visible": true,
                                            "src": "L3Stroke3.png"
                                        },
                                        {
                                            "children": [],
                                            "uuid": "af7d68fe-572c-4e1f-8058-2986005852ef",
                                            "type": "IMAGE",
                                            "name": "Grad_Bar",
                                            "tint": 16777215,
                                            "x": 369.4974832214765,
                                            "y": 895,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 1186,
                                            "height": 77,
                                            "alpha": "1",
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "blendMode": 0,
                                            "mask": null,
                                            "visible": true,
                                            "src": "L3_Grad.png"
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "c6cbe865-f84b-4789-b8ee-9b0b72fb6e99",
                                                    "type": "RECTANGLE",
                                                    "name": "WhiteBG",
                                                    "x": 1450,
                                                    "y": 878,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 102,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 102,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "ef41d0ff-cb16-456f-a5b9-3433cc1c69ed",
                                                    "type": "IMAGE",
                                                    "name": "Away Badge",
                                                    "tint": 16777215,
                                                    "x": 1361,
                                                    "y": 852,
                                                    "control": {
                                                        "id": "5",
                                                        "name": "Away Badge",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Score Update",
                                                                    "field": "Away Team Badge"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 225.00000000000003,
                                                    "height": 225.00000000000003,
                                                    "alpha": 1,
                                                    "rotation": "-0.27",
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.3358208955223881,
                                                        "y": 0.3358208955223881
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "src": "CREST_ManUTD.png"
                                                }
                                            ],
                                            "uuid": "ed5c39a3-d2c2-4666-88bb-d2a838a3d415",
                                            "type": "GROUP",
                                            "name": "AwayBadge_GRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 276.863037109375,
                                            "height": 276.86309814453125,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": "a2827d9e-46ce-445a-b993-e2bc4d06d0a6",
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "ca1512c9-1173-4c3d-b9b0-0e84d8e66adb",
                                                    "type": "RECTANGLE",
                                                    "name": "WhiteBG",
                                                    "x": "390",
                                                    "y": 878,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 102,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 102,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "ce60f33e-bd0b-4b5b-ab01-23b8facc59cf",
                                                    "type": "IMAGE",
                                                    "name": "Home Badge",
                                                    "tint": 16777215,
                                                    "x": 323,
                                                    "y": 853,
                                                    "control": {
                                                        "id": "4",
                                                        "name": "Home Badge",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Score Update",
                                                                    "field": "Home Team Badge"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 176,
                                                    "height": 174.99999999999997,
                                                    "alpha": 1,
                                                    "rotation": "-0.27",
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.2626865671641791,
                                                        "y": 0.26119402985074625
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "src": "Manchester_City.png"
                                                }
                                            ],
                                            "uuid": "9f62c58d-cb22-4ca2-9921-50c321d2cd07",
                                            "type": "GROUP",
                                            "name": "HomeBadge_GRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 216.30169677734375,
                                            "height": 215.60467529296875,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": "8c31881b-8aab-436b-8c9e-31d36903e883",
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "8c31881b-8aab-436b-8c9e-31d36903e883",
                                            "type": "RECTANGLE",
                                            "name": "Logo_ChipL_MASK",
                                            "x": 492,
                                            "y": 878,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 102,
                                            "height": 80,
                                            "alpha": 1,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 102,
                                                "height": 80,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 16777215,
                                            "maxWidth": 0,
                                            "anchorX": 1,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.28,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "a2827d9e-46ce-445a-b993-e2bc4d06d0a6",
                                            "type": "RECTANGLE",
                                            "name": "Logo_ChipR_MASK",
                                            "x": 1450,
                                            "y": 878,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 102,
                                            "height": 80,
                                            "alpha": 1,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 102,
                                                "height": 80,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 16777215,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.28,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "9ddff7d1-eb40-4d72-856d-4421ed0a195d",
                                            "type": "RECTANGLE",
                                            "name": "TopTabMASK",
                                            "x": 521,
                                            "y": 819,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 878,
                                            "height": 60,
                                            "alpha": 1,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 878,
                                                "height": 60,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 14355009,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "86435b68-6113-4ef1-b44d-e4e671d14009",
                                            "type": "RECTANGLE",
                                            "name": "LightBlue_Bar",
                                            "x": 492,
                                            "y": 878,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 958,
                                            "height": 80,
                                            "alpha": 1,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 958,
                                                "height": 80,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 7122399,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.28,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "5a320a0e-fca7-4910-bd3e-ff332597fc16",
                                                    "type": "RECTANGLE",
                                                    "name": "White_BG",
                                                    "x": 905,
                                                    "y": 878,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 127,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 127,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "113df672-0131-4af0-9756-e6c0c11b8ce9",
                                                    "type": "TEXT",
                                                    "name": "Home Score",
                                                    "x": 929,
                                                    "y": 894,
                                                    "control": {
                                                        "id": "3",
                                                        "name": "Home Score Text",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Score Update",
                                                                    "field": "Home Score"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 21,
                                                    "height": 42,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": ".5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9545454545454546,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 107,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#00285e",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Bold.otf",
                                                        "_fontSize": 40,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0,
                                                        "original_size": 40
                                                    },
                                                    "text": "2",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "a9423443-b960-4016-8c74-af64507db236",
                                                    "type": "TEXT",
                                                    "name": "Away Score",
                                                    "x": 990,
                                                    "y": 894,
                                                    "control": {
                                                        "id": "4",
                                                        "name": "Away Score Text",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Score Update",
                                                                    "field": "Away Score"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 25,
                                                    "height": 42,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": ".5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9615384615384616,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 107,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#00285e",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Bold.otf",
                                                        "_fontSize": 40,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0,
                                                        "original_size": 40
                                                    },
                                                    "text": "0",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "b89b2d1b-f995-4f8b-a8b1-fa9a943c5b13",
                                                    "type": "TEXT",
                                                    "name": "ScoreDot",
                                                    "x": 960,
                                                    "y": 894,
                                                    "control": {
                                                        "id": "5",
                                                        "name": "ScoreDot/Versus"
                                                    },
                                                    "width": 15,
                                                    "height": 42,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": ".5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9375,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 106,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#00285e",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Medium.otf",
                                                        "_fontSize": 40,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "-",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "34187d8f-13cb-4eb1-b75f-93ec63044393",
                                                    "type": "RECTANGLE",
                                                    "name": "BlueWIPE",
                                                    "x": 774,
                                                    "y": 878,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 127,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 127,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 10334,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                }
                                            ],
                                            "uuid": "175a2b85-ac05-4085-9b58-b100a573ffce",
                                            "type": "GROUP",
                                            "name": "Score_GRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 280.10845188512906,
                                            "height": 76.88443506486169,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": "75a22d85-b516-4f6d-a8c8-fa61b5d79649",
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "e7cc1160-af45-468a-b896-fb4de06f6869",
                                                    "type": "TEXT",
                                                    "name": "Home Team",
                                                    "x": 886,
                                                    "y": 895,
                                                    "control": {
                                                        "id": "1",
                                                        "name": "HomeTeamName",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Score Update",
                                                                    "field": "Home Team Name"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 266,
                                                    "height": 41,
                                                    "alpha": 1,
                                                    "maxWidth": "394",
                                                    "anchor": {
                                                        "x": "1",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9962546816479401,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "937e5cba-eed9-41c3-a1d6-9a01e1916cf6",
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 69,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#ffffff",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Medium.otf",
                                                        "_fontSize": 38,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0,
                                                        "original_size": 38
                                                    },
                                                    "text": "Manchester City",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "937e5cba-eed9-41c3-a1d6-9a01e1916cf6",
                                                    "type": "RECTANGLE",
                                                    "name": "HomeTeamMASK",
                                                    "x": 481,
                                                    "y": 888,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 420,
                                                    "height": 60,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 420,
                                                        "height": 60,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 14355009,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "668c8d56-1229-4fdc-867c-bdc8281fce3c",
                                                    "type": "TEXT",
                                                    "name": "Away Team",
                                                    "x": 1035,
                                                    "y": 895,
                                                    "control": {
                                                        "id": "2",
                                                        "name": "AwayTeamName",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Score Update",
                                                                    "field": "Away Team Name"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 290,
                                                    "height": 41,
                                                    "alpha": 1,
                                                    "maxWidth": "394",
                                                    "anchor": {
                                                        "x": "0",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9965635738831615,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "a3149530-12fa-4a40-8ef0-0c13320caf95",
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 72,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#ffffff",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Medium.otf",
                                                        "_fontSize": 38,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0,
                                                        "original_size": 38
                                                    },
                                                    "text": "West Ham United",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "a3149530-12fa-4a40-8ef0-0c13320caf95",
                                                    "type": "RECTANGLE",
                                                    "name": "AwayTeamMASK",
                                                    "x": 1023,
                                                    "y": 888,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 420,
                                                    "height": 60,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 420,
                                                        "height": 60,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 14355009,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                }
                                            ],
                                            "uuid": "c7370d80-7884-42dc-91e4-9d719fc114de",
                                            "type": "GROUP",
                                            "name": "Text_GRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 714.9640502929688,
                                            "height": 51,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        }
                                    ],
                                    "uuid": "938f89bb-e7fa-4ae0-abd8-9d7b10f79eec",
                                    "type": "GROUP",
                                    "name": "MainBar GRP",
                                    "x": 0,
                                    "y": 0,
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1194.6676559866917,
                                    "height": 136,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": true
                                },
                                {
                                    "children": [],
                                    "uuid": "75a22d85-b516-4f6d-a8c8-fa61b5d79649",
                                    "type": "RECTANGLE",
                                    "name": "ScoreMask",
                                    "x": 968,
                                    "y": 878,
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 128,
                                    "height": 80,
                                    "alpha": 1,
                                    "graphicsData": {
                                        "x": 0,
                                        "y": 0,
                                        "width": 128,
                                        "height": 80,
                                        "radius": 0,
                                        "type": 4
                                    },
                                    "fillColor": 8311585,
                                    "maxWidth": 0,
                                    "anchorX": 0.5,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": -0.28,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": true
                                }
                            ],
                            "uuid": "cde7635a-7f8b-4863-b83d-6c7f5c221095",
                            "type": "GROUP",
                            "name": "Root",
                            "x": 0,
                            "y": 0,
                            "control": {
                                "id": 0,
                                "name": ""
                            },
                            "width": 1194.6676559866917,
                            "height": 136,
                            "alpha": 1,
                            "maxWidth": 0,
                            "anchorX": 0,
                            "anchorY": 0,
                            "skew": {
                                "x": 0,
                                "y": 0
                            },
                            "scale": {
                                "x": 1,
                                "y": 1
                            },
                            "mask": null,
                            "visible": true
                        }
                    ],
                    "uuid": "dfe2fe9e-26a2-4264-8e83-4ed0edeeb980",
                    "type": "SCENE",
                    "name": "Root",
                    "x": 0,
                    "y": 0,
                    "control": {
                        "id": 0,
                        "name": ""
                    },
                    "alpha": 1,
                    "maxWidth": 0,
                    "skew": {
                        "x": 0,
                        "y": 0
                    },
                    "scale": {
                        "x": 1,
                        "y": 1
                    },
                    "mask": null,
                    "visible": true
                }
            }
        },
        {
            "_id": "61828977a4737fae2688109b",
            "name": "L3_Name",
            "project": "6182817751b7a0dd4843297a",
            "__v": 0,
            "data": {
                "name": "L3_Name",
                "width": 1920,
                "height": 1080,
                "folder": "UNIVERSAL",
                "aspect": "16x9",
                "timelines": [
                    {
                        "name": "IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": [
                                    {
                                        "property": "TIMELINE",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "name": "LOOP",
                                                "action": "PLAY"
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "2a803f10-57c8-49cc-81a2-317d2db11aff",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1445,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 23,
                                                "value": -111,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 460
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "64bcd3c4-a3a5-4ad1-9001-423629561f9a",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 46,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 24,
                                                "value": 46,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 480
                                            },
                                            {
                                                "frame": 39,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 780
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "720e61c2-d2b4-4ebd-a35a-fb607cac149c",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 166,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 24,
                                                "value": 166,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 480
                                            },
                                            {
                                                "frame": 39,
                                                "value": 776,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.029000244140625,
                                                    0.9235003662109376
                                                ],
                                                "type": "ease-out",
                                                "time": 780
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "7b3ef1f0-d97d-4d7c-a41d-6a878cba3faa",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 973,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "8a92fc42-4288-4146-84aa-0bafd89a0a5a",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 968,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "a1ce6338-0ad6-4550-8076-b4499b0704b6",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "c3d7204b-0b38-4163-9069-acec29c1736c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 39,
                        "loop": false,
                        "fps": 50,
                        "id": "411b49b9-1e38-4b79-a35f-b26b092b801a"
                    },
                    {
                        "name": "OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "64bcd3c4-a3a5-4ad1-9001-423629561f9a",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 17,
                                                "value": 46,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.699000244140625,
                                                    0.20350036621093748
                                                ],
                                                "type": "ease-out",
                                                "time": 340
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "2a803f10-57c8-49cc-81a2-317d2db11aff",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -111,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": -111,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 300
                                            },
                                            {
                                                "frame": 34,
                                                "value": 1453,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.779000244140625,
                                                    0.053500366210937456
                                                ],
                                                "type": "ease-out",
                                                "time": 680
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 35,
                        "loop": false,
                        "fps": 50,
                        "id": "7ea22e50-6f22-462c-ac6e-3a564425eb56"
                    },
                    {
                        "name": "CARD-IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "7b3ef1f0-d97d-4d7c-a41d-6a878cba3faa",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 300
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 49,
                                                "value": 1336,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.189000244140625,
                                                    0.9235003662109376
                                                ],
                                                "type": "ease-out",
                                                "time": 980
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 15,
                        "loop": false,
                        "fps": 50,
                        "id": "8cbc48ab-73f1-4d0e-b463-65db4db67f6d"
                    },
                    {
                        "name": "CARD-OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "7b3ef1f0-d97d-4d7c-a41d-6a878cba3faa",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 980,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.749000244140625,
                                                    0.04350036621093745
                                                ],
                                                "type": "ease-out",
                                                "time": 300
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 15,
                        "loop": false,
                        "fps": 50,
                        "id": "08df3da0-488f-4b87-a020-5882f7c9f54b"
                    },
                    {
                        "name": "2CARD-iN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "8a92fc42-4288-4146-84aa-0bafd89a0a5a",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 25,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.109000244140625,
                                                    0.9835003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 500
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1277,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 29,
                                                "value": 1277,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 580
                                            },
                                            {
                                                "frame": 35,
                                                "value": 1277,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 700
                                            },
                                            {
                                                "frame": 49,
                                                "value": 1336,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.189000244140625,
                                                    0.9235003662109376
                                                ],
                                                "type": "ease-out",
                                                "time": 980
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 62,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 1240
                                            },
                                            {
                                                "frame": 63,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 1260
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "7b3ef1f0-d97d-4d7c-a41d-6a878cba3faa",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 13,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 260
                                            },
                                            {
                                                "frame": 29,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.109000244140625,
                                                    0.9835003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 580
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 62,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 1240
                                            },
                                            {
                                                "frame": 63,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 1260
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "a1ce6338-0ad6-4550-8076-b4499b0704b6",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 52,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 1040
                                            },
                                            {
                                                "frame": 62,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 1240
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 75,
                        "loop": false,
                        "fps": 50,
                        "id": "a7288f46-c185-401c-9e9c-2d448170fb21"
                    },
                    {
                        "name": "2CARD-OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "a1ce6338-0ad6-4550-8076-b4499b0704b6",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 12,
                                                "value": 958,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 240
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 12,
                        "loop": false,
                        "fps": 50,
                        "id": "76ea87d8-ae10-438d-baa3-e1135fea4a06"
                    },
                    {
                        "name": "UPDATE-IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "720e61c2-d2b4-4ebd-a35a-fb607cac149c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 88,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 22,
                                                "value": 776,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.279000244140625,
                                                    0.8435003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 440
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "c3d7204b-0b38-4163-9069-acec29c1736c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 25,
                        "loop": false,
                        "fps": 50,
                        "id": "474af318-312f-4366-885c-6f82f7a6e89e"
                    },
                    {
                        "name": "UPDATE-OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "720e61c2-d2b4-4ebd-a35a-fb607cac149c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "c3d7204b-0b38-4163-9069-acec29c1736c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 14,
                        "loop": false,
                        "fps": 50,
                        "id": "2da4226b-1576-41e1-bd1c-4ac2b5963858"
                    },
                    {
                        "name": "LOOP",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "c619cc80-c7c1-416b-8236-acc05ca399c5",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 515,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 150,
                                                "value": 533,
                                                "curve": [
                                                    0.42,
                                                    0,
                                                    0.58,
                                                    1
                                                ],
                                                "type": "ease-in-out",
                                                "time": 3000
                                            },
                                            {
                                                "frame": 300,
                                                "value": 515,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 6000
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "6fc539ea-4678-440a-b242-8df4992b2937",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1224,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 175,
                                                "value": 1207,
                                                "curve": [
                                                    0.42,
                                                    0,
                                                    0.58,
                                                    1
                                                ],
                                                "type": "ease-in-out",
                                                "time": 3500
                                            },
                                            {
                                                "frame": 300,
                                                "value": 1224,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 6000
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 300,
                        "loop": true,
                        "fps": 50,
                        "id": "8596c231-1654-432f-b72f-3b43dc9cc572"
                    }
                ],
                "scene": {
                    "children": [
                        {
                            "children": [
                                {
                                    "children": [],
                                    "uuid": "170bf835-10e7-4284-80d2-096637bf36a1",
                                    "type": "IMAGE",
                                    "name": "REF",
                                    "tint": 16777215,
                                    "x": "0",
                                    "y": "0",
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1920,
                                    "height": 1080,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchor": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "blendMode": 0,
                                    "mask": null,
                                    "visible": false,
                                    "src": "LowerThirdREF.jpg"
                                },
                                {
                                    "children": [],
                                    "uuid": "81981809-83ec-4e06-b646-5e80f0067b3b",
                                    "type": "RECTANGLE",
                                    "name": "Background",
                                    "x": "0",
                                    "y": "0",
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1920,
                                    "height": 1080,
                                    "alpha": 1,
                                    "graphicsData": {
                                        "x": 0,
                                        "y": 0,
                                        "width": 1920,
                                        "height": 1080,
                                        "radius": 0,
                                        "type": 4
                                    },
                                    "fillColor": 14355009,
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": false
                                },
                                {
                                    "children": [
                                        {
                                            "children": [],
                                            "uuid": "6fc539ea-4678-440a-b242-8df4992b2937",
                                            "type": "IMAGE",
                                            "name": "Stroke",
                                            "tint": 16777215,
                                            "x": 1224,
                                            "y": 884,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 207,
                                            "height": 78,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "blendMode": 0,
                                            "mask": null,
                                            "visible": true,
                                            "src": "L3Stroke3.png"
                                        },
                                        {
                                            "children": [],
                                            "uuid": "b2ddf9d7-8cc7-4e8c-a8ec-f35716726596",
                                            "type": "RECTANGLE",
                                            "name": "Grad_MASK",
                                            "x": 744,
                                            "y": 858,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 872,
                                            "height": 129,
                                            "alpha": 1,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 872,
                                                "height": 129,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 14355009,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.28,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "c619cc80-c7c1-416b-8236-acc05ca399c5",
                                            "type": "IMAGE",
                                            "name": "Grad",
                                            "tint": 16777215,
                                            "x": 515,
                                            "y": 891,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 874,
                                            "height": 77,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "blendMode": 0,
                                            "mask": "b2ddf9d7-8cc7-4e8c-a8ec-f35716726596",
                                            "visible": true,
                                            "src": "L3_Grad_name.png"
                                        },
                                        {
                                            "children": [],
                                            "uuid": "6f9a2488-e5f8-4549-892d-20815a20e20d",
                                            "type": "RECTANGLE",
                                            "name": "HeaderMASK",
                                            "x": 770,
                                            "y": 837,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 646.9999999999999,
                                            "height": 42,
                                            "alpha": 1,
                                            "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}",
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 646.9999999999999,
                                                "height": 42,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 10334,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.27,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "91ff368e-2550-4dc5-8ddc-6ec7aadbff0a",
                                                    "type": "RECTANGLE",
                                                    "name": "DarkBlueBG",
                                                    "x": 770,
                                                    "y": 837,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 166,
                                                    "height": 42,
                                                    "alpha": 1,
                                                    "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}\n// Executed when graphic\n// updated each frame\nconst padding = 20; \nconst text_node_name = 'Header'\n\nfunction update(item, frame, scene) { \nlet text = hyperAPI.getItemByName(scene.name, text_node_name); \nitem.width = (text.x - item.x) + text.width + padding; }",
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 166,
                                                        "height": 42,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 10334,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.27,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "624b4d39-ebb5-4669-b6ca-e88bc9ca0bd4",
                                                    "type": "TEXT",
                                                    "name": "Header",
                                                    "x": 777,
                                                    "y": 842,
                                                    "control": {
                                                        "id": "5",
                                                        "name": "Header Text"
                                                    },
                                                    "width": 139,
                                                    "height": 29,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "0",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9928571428571429,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 75,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#fcfcfc",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Medium.otf",
                                                        "_fontSize": 26,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "MANAGER",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                }
                                            ],
                                            "uuid": "64bcd3c4-a3a5-4ad1-9001-423629561f9a",
                                            "type": "GROUP",
                                            "name": "HeaderGRP",
                                            "x": 0,
                                            "y": 46,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 177.2027203409309,
                                            "height": 40.47837764736744,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": "6f9a2488-e5f8-4549-892d-20815a20e20d",
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "b7e9b022-7d19-472b-86b7-b9eff513c856",
                                            "type": "RECTANGLE",
                                            "name": "BlueBG",
                                            "x": 758,
                                            "y": 877,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 659,
                                            "height": 80,
                                            "alpha": 1,
                                            "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}",
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 659,
                                                "height": 80,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 7122399,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.27,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "720e61c2-d2b4-4ebd-a35a-fb607cac149c",
                                                    "type": "TEXT",
                                                    "name": "FirstName",
                                                    "x": 776,
                                                    "y": 896,
                                                    "control": {
                                                        "id": "3",
                                                        "name": "First Name"
                                                    },
                                                    "width": 69,
                                                    "height": 42,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "0",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9857142857142858,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 73,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#fcfcfc",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Medium.otf",
                                                        "_fontSize": 40,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "PEP",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "c3d7204b-0b38-4163-9069-acec29c1736c",
                                                    "type": "TEXT",
                                                    "name": "LastName",
                                                    "x": 857,
                                                    "y": 896,
                                                    "control": {
                                                        "id": "4",
                                                        "name": "Last Name"
                                                    },
                                                    "width": 219,
                                                    "height": 42,
                                                    "alpha": 1,
                                                    "autoFollow": "720e61c2-d2b4-4ebd-a35a-fb607cac149c",
                                                    "autoFollowPadding": 12,
                                                    "maxWidth": "418",
                                                    "anchor": {
                                                        "x": "0",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9954545454545455,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 75,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#fcfcfc",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Bold.otf",
                                                        "_fontSize": 40,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "GUARDIOLA",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                                    "type": "RECTANGLE",
                                                    "name": "NameMASK",
                                                    "x": 762,
                                                    "y": 877,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 751,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}",
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 751,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 7122399,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.27,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "7b3ef1f0-d97d-4d7c-a41d-6a878cba3faa",
                                                    "type": "RECTANGLE",
                                                    "name": "YELLOWCARD1",
                                                    "x": 1336,
                                                    "y": 973,
                                                    "control": {
                                                        "id": "5",
                                                        "name": "Card"
                                                    },
                                                    "width": 39,
                                                    "height": 55,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 39,
                                                        "height": 55,
                                                        "radius": 8,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16312092,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "8a92fc42-4288-4146-84aa-0bafd89a0a5a",
                                                    "type": "RECTANGLE",
                                                    "name": "YELLOWCARD2",
                                                    "x": 1336,
                                                    "y": 968,
                                                    "control": {
                                                        "id": "5",
                                                        "name": "Card"
                                                    },
                                                    "width": 39,
                                                    "height": 55,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 39,
                                                        "height": 55,
                                                        "radius": 8,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16312092,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "a1ce6338-0ad6-4550-8076-b4499b0704b6",
                                                    "type": "RECTANGLE",
                                                    "name": "REDCARD",
                                                    "x": 1336,
                                                    "y": 888,
                                                    "control": {
                                                        "id": "5",
                                                        "name": "Card"
                                                    },
                                                    "width": 39,
                                                    "height": 55,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 39,
                                                        "height": 55,
                                                        "radius": 8,
                                                        "type": 4
                                                    },
                                                    "fillColor": 13632027,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                                    "visible": true
                                                }
                                            ],
                                            "uuid": "0f133c67-ba17-4030-86ee-31fd9e44b1da",
                                            "type": "GROUP",
                                            "name": "NameGRP",
                                            "x": "-0",
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 603.9285888671875,
                                            "height": 55,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        }
                                    ],
                                    "uuid": "2a803f10-57c8-49cc-81a2-317d2db11aff",
                                    "type": "GROUP",
                                    "name": "L3_GRP",
                                    "x": 1453,
                                    "y": 0,
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 722.6498786647707,
                                    "height": 91,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": true
                                }
                            ],
                            "uuid": "fc5c538c-86c7-4c96-bcc4-beaf0bfd9b9c",
                            "type": "GROUP",
                            "name": "Root",
                            "x": 0,
                            "y": 0,
                            "control": {
                                "id": 0,
                                "name": ""
                            },
                            "width": 722.6498786647708,
                            "height": 91,
                            "alpha": 1,
                            "maxWidth": 0,
                            "anchorX": 0,
                            "anchorY": 0,
                            "skew": {
                                "x": 0,
                                "y": 0
                            },
                            "scale": {
                                "x": 1,
                                "y": 1
                            },
                            "mask": null,
                            "visible": true
                        }
                    ],
                    "uuid": "d5e9b400-21b0-4f14-8e64-33e541586d9e",
                    "type": "SCENE",
                    "name": "Root",
                    "x": 0,
                    "y": 0,
                    "control": {
                        "id": 0,
                        "name": ""
                    },
                    "alpha": 1,
                    "maxWidth": 0,
                    "skew": {
                        "x": 0,
                        "y": 0
                    },
                    "scale": {
                        "x": 1,
                        "y": 1
                    },
                    "mask": null,
                    "visible": false
                }
            }
        },
        {
            "_id": "61828977a4737fae268810b5",
            "name": "L3_Penalties",
            "project": "6182817751b7a0dd4843297a",
            "__v": 0,
            "data": {
                "name": "L3_Penalties",
                "football": {
                    "templates": [
                        {
                            "name": "Penalties"
                        }
                    ]
                },
                "width": 1920,
                "height": 1080,
                "folder": "UNIVERSAL",
                "aspect": "16x9",
                "timelines": [
                    {
                        "name": "IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": [
                                    {
                                        "property": "TIMELINE",
                                        "keyframes": [
                                            {
                                                "frame": 5,
                                                "action": "PLAY",
                                                "name": "T3"
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "938f89bb-e7fa-4ae0-abd8-9d7b10f79eec",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1629,
                                                "curve": [
                                                    0.65703125,
                                                    0.40718750000000004,
                                                    1,
                                                    1
                                                ],
                                                "type": "ease-in",
                                                "time": 20
                                            },
                                            {
                                                "frame": 25,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.43703125,
                                                    1.0271875
                                                ],
                                                "type": "ease-out",
                                                "time": 500
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "8c31881b-8aab-436b-8c9e-31d36903e883",
                                "animations": [
                                    {
                                        "property": "width",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0.00980392156862745,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 19,
                                                "value": 0.00980392156862745,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 380
                                            },
                                            {
                                                "frame": 33,
                                                "value": 102,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 660
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 492,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "a2827d9e-46ce-445a-b993-e2bc4d06d0a6",
                                "animations": [
                                    {
                                        "property": "width",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0.00980392156862745,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 19,
                                                "value": 0.00980392156862745,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 380
                                            },
                                            {
                                                "frame": 33,
                                                "value": 102,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 660
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1450,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "d19a4852-4e24-4ac3-a0b8-c2ae6f8850ec",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 49,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 19,
                                                "value": 49,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 380
                                            },
                                            {
                                                "frame": 33,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.316978759765625,
                                                    0.93107666015625
                                                ],
                                                "type": "ease-out",
                                                "time": 660
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "e7cc1160-af45-468a-b896-fb4de06f6869",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1316,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 19,
                                                "value": 1316,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 380
                                            },
                                            {
                                                "frame": 33,
                                                "value": 886,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.209000244140625,
                                                    0.8135003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 660
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "668c8d56-1229-4fdc-867c-bdc8281fce3c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 603,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 19,
                                                "value": 603,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 380
                                            },
                                            {
                                                "frame": 33,
                                                "value": 1035,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.229000244140625,
                                                    0.8635003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 660
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "75a22d85-b516-4f6d-a8c8-fa61b5d79649",
                                "animations": [
                                    {
                                        "property": "width",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 9,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 180
                                            },
                                            {
                                                "frame": 21,
                                                "value": 128,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 420
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "c7370d80-7884-42dc-91e4-9d719fc114de",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "34187d8f-13cb-4eb1-b75f-93ec63044393",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 774,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 34,
                        "loop": false,
                        "fps": 50,
                        "id": "978d48dd-e009-47ee-adb3-5f87547d59c5"
                    },
                    {
                        "name": "OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "938f89bb-e7fa-4ae0-abd8-9d7b10f79eec",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 7,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.039000244140625,
                                                    0.9035003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 140
                                            },
                                            {
                                                "frame": 25,
                                                "value": 1609,
                                                "curve": [
                                                    1,
                                                    0.06350036621093746,
                                                    1,
                                                    1
                                                ],
                                                "type": "ease-in",
                                                "time": 500
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "a2827d9e-46ce-445a-b993-e2bc4d06d0a6",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1450,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 10,
                                                "value": 1343,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 200
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "d19a4852-4e24-4ac3-a0b8-c2ae6f8850ec",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 49,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "e7cc1160-af45-468a-b896-fb4de06f6869",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 19,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 380
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "668c8d56-1229-4fdc-867c-bdc8281fce3c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 19,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 380
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "8c31881b-8aab-436b-8c9e-31d36903e883",
                                "animations": [
                                    {
                                        "property": "width",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 102,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 10,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 200
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "75a22d85-b516-4f6d-a8c8-fa61b5d79649",
                                "animations": [
                                    {
                                        "property": "width",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 128,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 30,
                        "loop": false,
                        "fps": 50,
                        "id": "ca910abe-27d4-4b0b-969c-4de1a9d142b5"
                    },
                    {
                        "name": "T3",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "0c1c41a0-e90f-4ee6-b746-5738e21e849b",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1380,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 150,
                                                "value": 1351,
                                                "curve": [
                                                    0.42,
                                                    0,
                                                    0.58,
                                                    1
                                                ],
                                                "type": "ease-in-out",
                                                "time": 3000
                                            },
                                            {
                                                "frame": 300,
                                                "value": 1380,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 6000
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "af7d68fe-572c-4e1f-8058-2986005852ef",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 340,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 150,
                                                "value": 375,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 3000
                                            },
                                            {
                                                "frame": 300,
                                                "value": 340,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 6000
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 300,
                        "loop": true,
                        "fps": 50,
                        "id": "5e713cae-0a26-4801-b227-671ef2402654"
                    },
                    {
                        "name": "UPDATE-IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "c7370d80-7884-42dc-91e4-9d719fc114de",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "34187d8f-13cb-4eb1-b75f-93ec63044393",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 905,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 8,
                                                "value": 1039,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 160
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "e7cc1160-af45-468a-b896-fb4de06f6869",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1256,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 17,
                                                "value": 886,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.199000244140625,
                                                    0.7035003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 340
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "668c8d56-1229-4fdc-867c-bdc8281fce3c",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 604,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 17,
                                                "value": 1035,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.229000244140625,
                                                    0.8035003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 340
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "d19a4852-4e24-4ac3-a0b8-c2ae6f8850ec",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 55,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 17,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 340
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 25,
                        "loop": false,
                        "fps": 50,
                        "id": "50f38439-3c47-48fb-a57c-5ec4a7c1b9b5"
                    },
                    {
                        "name": "UPDATE-OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "c7370d80-7884-42dc-91e4-9d719fc114de",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "34187d8f-13cb-4eb1-b75f-93ec63044393",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 774,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 10,
                                                "value": 774,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 200
                                            },
                                            {
                                                "frame": 14,
                                                "value": 905,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "d19a4852-4e24-4ac3-a0b8-c2ae6f8850ec",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 55,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 14,
                        "loop": false,
                        "fps": 50,
                        "id": "3c79e5ea-bf37-4ac6-b13c-389f9d1d52bd"
                    },
                    {
                        "name": "ALT-UPDATE-IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "d19a4852-4e24-4ac3-a0b8-c2ae6f8850ec",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 49,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 1,
                                                "value": 49,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 300
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 15,
                        "loop": false,
                        "fps": 50,
                        "id": "44125385-6fdf-432b-8c6b-2741e310ee50"
                    },
                    {
                        "name": "ALT-UPDATE-OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "d19a4852-4e24-4ac3-a0b8-c2ae6f8850ec",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 49,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 300
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 15,
                        "loop": false,
                        "fps": 50,
                        "id": "07b5ee6d-f780-4ed0-bca0-3a28e7ff07d9"
                    }
                ],
                "scene": {
                    "children": [
                        {
                            "children": [
                                {
                                    "children": [],
                                    "uuid": "3cce9d50-7839-4fea-8f89-dce0cbcac446",
                                    "type": "RECTANGLE",
                                    "name": "Background",
                                    "x": "0",
                                    "y": "0",
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1921,
                                    "height": 1189,
                                    "alpha": 1,
                                    "graphicsData": {
                                        "x": 0,
                                        "y": 0,
                                        "width": 1921,
                                        "height": 1189,
                                        "radius": 0,
                                        "type": 4
                                    },
                                    "fillColor": 14355009,
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": false
                                },
                                {
                                    "children": [],
                                    "uuid": "bb681416-7e05-480f-84a5-285c14f9137d",
                                    "type": "IMAGE",
                                    "name": "REFERENCE",
                                    "tint": 16777215,
                                    "x": "0",
                                    "y": "0",
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1920,
                                    "height": 1080,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchor": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "blendMode": 0,
                                    "mask": null,
                                    "visible": false,
                                    "src": "PenaltiesREF.png"
                                },
                                {
                                    "children": [
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "adbc9fa6-1971-4fe1-a124-bac26d321c9c",
                                                    "type": "RECTANGLE",
                                                    "name": "DarkBlue_Bar",
                                                    "x": 972,
                                                    "y": 836,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 298,
                                                    "height": 43,
                                                    "alpha": 1,
                                                    "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}\n// Executed when graphic\n// updated each frame\nconst padding = 40; \nconst text_node_name = 'TopTEXT'\n\nfunction update(item, frame, scene) { \nlet text = hyperAPI.getItemByName(scene.name, text_node_name); \nitem.width = (text.x - item.x) + text.width + padding; }",
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 298,
                                                        "height": 43,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 10334,
                                                    "maxWidth": 0,
                                                    "anchorX": 0.5,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "738fbb2b-80cc-4566-af44-b6553df7fce2",
                                                    "type": "TEXT",
                                                    "name": "TopTEXT",
                                                    "x": 965,
                                                    "y": 843,
                                                    "control": {
                                                        "id": "6",
                                                        "name": "TopText"
                                                    },
                                                    "width": 265,
                                                    "height": 26,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": ".5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9962406015037594,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 60,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#ffffff",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 24,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "PENALTY SHOOTOUT",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                }
                                            ],
                                            "uuid": "d19a4852-4e24-4ac3-a0b8-c2ae6f8850ec",
                                            "type": "GROUP",
                                            "name": "TopTab GRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 309.88329288825685,
                                            "height": 41.32538384736313,
                                            "alpha": 1,
                                            "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}",
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": "9ddff7d1-eb40-4d72-856d-4421ed0a195d",
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "0c1c41a0-e90f-4ee6-b746-5738e21e849b",
                                            "type": "IMAGE",
                                            "name": "Stroke",
                                            "tint": 16777215,
                                            "x": 1380,
                                            "y": 889,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 207,
                                            "height": 78,
                                            "alpha": "0.8",
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "blendMode": 0,
                                            "mask": null,
                                            "visible": true,
                                            "src": "L3Stroke3.png"
                                        },
                                        {
                                            "children": [],
                                            "uuid": "af7d68fe-572c-4e1f-8058-2986005852ef",
                                            "type": "IMAGE",
                                            "name": "Grad_Bar",
                                            "tint": 16777215,
                                            "x": 340,
                                            "y": 895,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 1186,
                                            "height": 77,
                                            "alpha": "1",
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "blendMode": 0,
                                            "mask": null,
                                            "visible": true,
                                            "src": "L3_Grad.png"
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "c6cbe865-f84b-4789-b8ee-9b0b72fb6e99",
                                                    "type": "RECTANGLE",
                                                    "name": "WhiteBG",
                                                    "x": 1450,
                                                    "y": 878,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 102,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 102,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "ef41d0ff-cb16-456f-a5b9-3433cc1c69ed",
                                                    "type": "IMAGE",
                                                    "name": "Away Badge",
                                                    "tint": 16777215,
                                                    "x": 1371,
                                                    "y": 852,
                                                    "control": {
                                                        "id": "5",
                                                        "name": "Away Badge",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Penalties",
                                                                    "field": "Away Team Badge"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 200,
                                                    "height": 201.3157894736842,
                                                    "alpha": 1,
                                                    "rotation": "-0.27",
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1.3157894736842106,
                                                        "y": 1.3157894736842104
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "src": "CREST_ManUTD.png"
                                                }
                                            ],
                                            "uuid": "ed5c39a3-d2c2-4666-88bb-d2a838a3d415",
                                            "type": "GROUP",
                                            "name": "AwayBadge_GRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 246.451416015625,
                                            "height": 247.36865234375,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": "a2827d9e-46ce-445a-b993-e2bc4d06d0a6",
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "ca1512c9-1173-4c3d-b9b0-0e84d8e66adb",
                                                    "type": "RECTANGLE",
                                                    "name": "WhiteBG",
                                                    "x": "390",
                                                    "y": 878,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 102,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 102,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "ce60f33e-bd0b-4b5b-ab01-23b8facc59cf",
                                                    "type": "IMAGE",
                                                    "name": "Home Badge",
                                                    "tint": 16777215,
                                                    "x": 315,
                                                    "y": 852,
                                                    "control": {
                                                        "id": "4",
                                                        "name": "Home Badge",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Penalties",
                                                                    "field": "Home Team Badge"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 199.99999999999997,
                                                    "height": 199.99999999999997,
                                                    "alpha": 1,
                                                    "rotation": "-0.27",
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.29850746268656714,
                                                        "y": 0.29850746268656714
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "src": "Manchester_City.png"
                                                }
                                            ],
                                            "uuid": "9f62c58d-cb22-4ca2-9921-50c321d2cd07",
                                            "type": "GROUP",
                                            "name": "HomeBadge_GRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 246.1004638671875,
                                            "height": 246.1004638671875,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": "8c31881b-8aab-436b-8c9e-31d36903e883",
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "8c31881b-8aab-436b-8c9e-31d36903e883",
                                            "type": "RECTANGLE",
                                            "name": "Logo_ChipL_MASK",
                                            "x": 492,
                                            "y": 878,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 102,
                                            "height": 80,
                                            "alpha": 1,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 102,
                                                "height": 80,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 16777215,
                                            "maxWidth": 0,
                                            "anchorX": 1,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.28,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "a2827d9e-46ce-445a-b993-e2bc4d06d0a6",
                                            "type": "RECTANGLE",
                                            "name": "Logo_ChipR_MASK",
                                            "x": 1450,
                                            "y": 878,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 102,
                                            "height": 80,
                                            "alpha": 1,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 102,
                                                "height": 80,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 16777215,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.28,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "9ddff7d1-eb40-4d72-856d-4421ed0a195d",
                                            "type": "RECTANGLE",
                                            "name": "TopTabMASK",
                                            "x": 521,
                                            "y": 819,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 878,
                                            "height": 60,
                                            "alpha": 1,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 878,
                                                "height": 60,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 14355009,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "86435b68-6113-4ef1-b44d-e4e671d14009",
                                            "type": "RECTANGLE",
                                            "name": "LightBlue_Bar",
                                            "x": 492,
                                            "y": 878,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 958,
                                            "height": 80,
                                            "alpha": 1,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 958,
                                                "height": 80,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 7122399,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.28,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "8958c318-e875-40c0-9564-92d336927fbf",
                                                    "type": "RECTANGLE",
                                                    "name": "Pen1",
                                                    "x": "510",
                                                    "y": "890",
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 52.000000000000014,
                                                    "height": 52,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 52.000000000000014,
                                                        "height": 52,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "3383a94e-1fda-4a8e-a91a-a3934fc85409",
                                                    "type": "RECTANGLE",
                                                    "name": "Pen2",
                                                    "x": "590",
                                                    "y": "890",
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 52.000000000000014,
                                                    "height": 52,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 52.000000000000014,
                                                        "height": 52,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "be2d4c6a-2bd1-4c21-88d9-d94620b7558f",
                                                    "type": "RECTANGLE",
                                                    "name": "Pen3",
                                                    "x": "670",
                                                    "y": "890",
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 52.000000000000014,
                                                    "height": 52,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 52.000000000000014,
                                                        "height": 52,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "42083e30-aaa6-4ad1-a05a-7ae54daa36d3",
                                                    "type": "RECTANGLE",
                                                    "name": "Pen4",
                                                    "x": "750",
                                                    "y": "890",
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 52.000000000000014,
                                                    "height": 52,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 52.000000000000014,
                                                        "height": 52,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "23741b75-a9f1-4242-89a3-dec274260271",
                                                    "type": "RECTANGLE",
                                                    "name": "Pen5",
                                                    "x": "830",
                                                    "y": "890",
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 52.000000000000014,
                                                    "height": 52,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 52.000000000000014,
                                                        "height": 52,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "70118203-42c0-4fa9-b8ea-a7994a1c341e",
                                                    "type": "TEXT",
                                                    "name": "Pen1Text",
                                                    "x": 530,
                                                    "y": 892,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 15,
                                                    "height": 40,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": ".5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9375,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 37,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#00285e",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 36,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "1",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "7f956634-1b54-4019-9422-efc8388388e3",
                                                    "type": "TEXT",
                                                    "name": "Pen2Text",
                                                    "x": 609,
                                                    "y": 892,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 22,
                                                    "height": 40,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": ".5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9565217391304348,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 37,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#00285e",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 36,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "2",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "a91c0593-09fa-4d86-923b-9f25db7fe8ac",
                                                    "type": "TEXT",
                                                    "name": "Pen3Text",
                                                    "x": 689,
                                                    "y": 892,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 22,
                                                    "height": 40,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": ".5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9565217391304348,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 37,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#00285e",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 36,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "3",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "b73af990-068b-4570-82c0-65e26b4db158",
                                                    "type": "TEXT",
                                                    "name": "Pen4Text",
                                                    "x": 769,
                                                    "y": 892,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 24,
                                                    "height": 40,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": ".5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.96,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 37,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#00285e",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 36,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "4",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "cc3c30ca-a2a6-4efe-bb03-81a1162e3417",
                                                    "type": "TEXT",
                                                    "name": "Pen5Text",
                                                    "x": 849,
                                                    "y": 892,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 22,
                                                    "height": 40,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": ".5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9565217391304348,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 37,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#00285e",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 36,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "5",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                }
                                            ],
                                            "uuid": "7d80c635-76bc-48a8-a536-27bceb55f3bc",
                                            "type": "GROUP",
                                            "name": "HomePens_GRP",
                                            "x": 0,
                                            "y": "2",
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 386.3704937253339,
                                            "height": 52.97488279216009,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "ec50d902-0f6c-425a-b395-4a1a3956d96a",
                                                    "type": "RECTANGLE",
                                                    "name": "Pen1",
                                                    "x": "510",
                                                    "y": "890",
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 52.000000000000014,
                                                    "height": 52,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 52.000000000000014,
                                                        "height": 52,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "de52461e-d315-4f07-9773-eb6c7b0276d5",
                                                    "type": "RECTANGLE",
                                                    "name": "Pen2",
                                                    "x": "590",
                                                    "y": "890",
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 52.000000000000014,
                                                    "height": 52,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 52.000000000000014,
                                                        "height": 52,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "6582b095-8d9a-494d-8792-9e55f207360d",
                                                    "type": "RECTANGLE",
                                                    "name": "Pen3",
                                                    "x": "670",
                                                    "y": "890",
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 52.000000000000014,
                                                    "height": 52,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 52.000000000000014,
                                                        "height": 52,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "501ab51d-e931-443c-ba0d-6a4f6a5165a2",
                                                    "type": "RECTANGLE",
                                                    "name": "Pen4",
                                                    "x": "750",
                                                    "y": "890",
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 52.000000000000014,
                                                    "height": 52,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 52.000000000000014,
                                                        "height": 52,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "d6bb8bd7-60df-4f43-9542-bc39bfa6af9d",
                                                    "type": "RECTANGLE",
                                                    "name": "Pen5",
                                                    "x": "830",
                                                    "y": "890",
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 52.000000000000014,
                                                    "height": 52,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 52.000000000000014,
                                                        "height": 52,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "7976a8e1-8c6a-443b-bf7b-435a813a48e7",
                                                    "type": "TEXT",
                                                    "name": "Pen1Text",
                                                    "x": 530,
                                                    "y": 892,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 15,
                                                    "height": 40,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": ".5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9375,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 37,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#00285e",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 36,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "1",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "ad130cac-1c22-44d6-802b-d6f64aa0f3b1",
                                                    "type": "TEXT",
                                                    "name": "Pen2Text",
                                                    "x": 609,
                                                    "y": 892,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 22,
                                                    "height": 40,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": ".5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9565217391304348,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 37,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#00285e",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 36,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "2",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "a7d3ce74-9537-4a8c-b9d4-4ef381ae49be",
                                                    "type": "TEXT",
                                                    "name": "Pen3Text",
                                                    "x": 689,
                                                    "y": 892,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 22,
                                                    "height": 40,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": ".5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9565217391304348,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 37,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#00285e",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 36,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "3",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "4e791963-95a3-4de6-8c64-4a0f533a6444",
                                                    "type": "TEXT",
                                                    "name": "Pen4Text",
                                                    "x": 769,
                                                    "y": 892,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 24,
                                                    "height": 40,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": ".5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.96,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 37,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#00285e",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 36,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "4",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "a949e21f-c673-4dc3-8b9a-699354922bec",
                                                    "type": "TEXT",
                                                    "name": "Pen5Text",
                                                    "x": 849,
                                                    "y": 892,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 22,
                                                    "height": 40,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": ".5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9565217391304348,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 37,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#00285e",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 36,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "5",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                }
                                            ],
                                            "uuid": "5a1c1bdc-6b2d-4581-849f-1a9a6eeeba73",
                                            "type": "GROUP",
                                            "name": "AwayPens_GRP",
                                            "x": 543,
                                            "y": 2,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 386.3704937253339,
                                            "height": 52.97488279216009,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "5a320a0e-fca7-4910-bd3e-ff332597fc16",
                                                    "type": "RECTANGLE",
                                                    "name": "White_BG",
                                                    "x": 905,
                                                    "y": 878,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 127,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 127,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "113df672-0131-4af0-9756-e6c0c11b8ce9",
                                                    "type": "TEXT",
                                                    "name": "Home Score",
                                                    "x": 929,
                                                    "y": 894,
                                                    "control": {
                                                        "id": "3",
                                                        "name": "Home Score Text"
                                                    },
                                                    "width": 25,
                                                    "height": 42,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": ".5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9615384615384616,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 107,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#00285e",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Bold.otf",
                                                        "_fontSize": 40,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "0",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "a9423443-b960-4016-8c74-af64507db236",
                                                    "type": "TEXT",
                                                    "name": "Away Score",
                                                    "x": 990,
                                                    "y": 894,
                                                    "control": {
                                                        "id": "4",
                                                        "name": "Away Score Text"
                                                    },
                                                    "width": 25,
                                                    "height": 42,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": ".5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9615384615384616,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 107,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#00285e",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Bold.otf",
                                                        "_fontSize": 40,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "0",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "b89b2d1b-f995-4f8b-a8b1-fa9a943c5b13",
                                                    "type": "TEXT",
                                                    "name": "ScoreDot",
                                                    "x": 960,
                                                    "y": 894,
                                                    "control": {
                                                        "id": "5",
                                                        "name": "ScoreDot/Versus"
                                                    },
                                                    "width": 15,
                                                    "height": 42,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": ".5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9375,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 106,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#00285e",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Medium.otf",
                                                        "_fontSize": 40,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "-",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "34187d8f-13cb-4eb1-b75f-93ec63044393",
                                                    "type": "RECTANGLE",
                                                    "name": "BlueWIPE",
                                                    "x": 774,
                                                    "y": 878,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 127,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 127,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 10334,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                }
                                            ],
                                            "uuid": "175a2b85-ac05-4085-9b58-b100a573ffce",
                                            "type": "GROUP",
                                            "name": "Score_GRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 280.10845188512906,
                                            "height": 76.88443506486169,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": "75a22d85-b516-4f6d-a8c8-fa61b5d79649",
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "e7cc1160-af45-468a-b896-fb4de06f6869",
                                                    "type": "TEXT",
                                                    "name": "Home Team",
                                                    "x": 886,
                                                    "y": 895,
                                                    "control": {
                                                        "id": "1",
                                                        "name": "HomeTeamName"
                                                    },
                                                    "width": 324,
                                                    "height": 41,
                                                    "alpha": 1,
                                                    "maxWidth": "394",
                                                    "anchor": {
                                                        "x": "1",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9969230769230769,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "937e5cba-eed9-41c3-a1d6-9a01e1916cf6",
                                                    "visible": false,
                                                    "style": {
                                                        "styleID": 69,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#ffffff",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Medium.otf",
                                                        "_fontSize": 38,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0,
                                                        "original_size": 38
                                                    },
                                                    "text": "MANCHESTER CITY",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "937e5cba-eed9-41c3-a1d6-9a01e1916cf6",
                                                    "type": "RECTANGLE",
                                                    "name": "HomeTeamMASK",
                                                    "x": 481,
                                                    "y": 888,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 420,
                                                    "height": 60,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 420,
                                                        "height": 60,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 14355009,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": false
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "668c8d56-1229-4fdc-867c-bdc8281fce3c",
                                                    "type": "TEXT",
                                                    "name": "Away Team",
                                                    "x": 1035,
                                                    "y": 895,
                                                    "control": {
                                                        "id": "2",
                                                        "name": "AwayTeamName"
                                                    },
                                                    "width": 394,
                                                    "height": 41,
                                                    "alpha": 1,
                                                    "maxWidth": "394",
                                                    "anchor": {
                                                        "x": "0",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9974683544303797,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "a3149530-12fa-4a40-8ef0-0c13320caf95",
                                                    "visible": false,
                                                    "style": {
                                                        "styleID": 72,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#ffffff",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Medium.otf",
                                                        "_fontSize": 38,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0,
                                                        "original_size": 38
                                                    },
                                                    "text": "MANCHESTER UNITEDx",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "a3149530-12fa-4a40-8ef0-0c13320caf95",
                                                    "type": "RECTANGLE",
                                                    "name": "AwayTeamMASK",
                                                    "x": 1023,
                                                    "y": 888,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 420,
                                                    "height": 60,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 420,
                                                        "height": 60,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 14355009,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": false
                                                }
                                            ],
                                            "uuid": "c7370d80-7884-42dc-91e4-9d719fc114de",
                                            "type": "GROUP",
                                            "name": "Text_GRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 0,
                                            "height": 0,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": false
                                        }
                                    ],
                                    "uuid": "938f89bb-e7fa-4ae0-abd8-9d7b10f79eec",
                                    "type": "GROUP",
                                    "name": "MainBar GRP",
                                    "x": 0,
                                    "y": 0,
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1247,
                                    "height": 136,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": true
                                },
                                {
                                    "children": [],
                                    "uuid": "75a22d85-b516-4f6d-a8c8-fa61b5d79649",
                                    "type": "RECTANGLE",
                                    "name": "ScoreMask",
                                    "x": 968,
                                    "y": 878,
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 128,
                                    "height": 80,
                                    "alpha": 1,
                                    "graphicsData": {
                                        "x": 0,
                                        "y": 0,
                                        "width": 128,
                                        "height": 80,
                                        "radius": 0,
                                        "type": 4
                                    },
                                    "fillColor": 8311585,
                                    "maxWidth": 0,
                                    "anchorX": 0.5,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": -0.28,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": true
                                }
                            ],
                            "uuid": "cde7635a-7f8b-4863-b83d-6c7f5c221095",
                            "type": "GROUP",
                            "name": "Root",
                            "x": 0,
                            "y": 0,
                            "control": {
                                "id": 0,
                                "name": ""
                            },
                            "width": 1247,
                            "height": 136,
                            "alpha": 1,
                            "maxWidth": 0,
                            "anchorX": 0,
                            "anchorY": 0,
                            "skew": {
                                "x": 0,
                                "y": 0
                            },
                            "scale": {
                                "x": 1,
                                "y": 1
                            },
                            "mask": null,
                            "visible": true
                        }
                    ],
                    "uuid": "dfe2fe9e-26a2-4264-8e83-4ed0edeeb980",
                    "type": "SCENE",
                    "name": "Root",
                    "x": 0,
                    "y": 0,
                    "control": {
                        "id": 0,
                        "name": ""
                    },
                    "alpha": 1,
                    "maxWidth": 0,
                    "skew": {
                        "x": 0,
                        "y": 0
                    },
                    "scale": {
                        "x": 1,
                        "y": 1
                    },
                    "mask": null,
                    "visible": false
                }
            }
        },
        {
            "_id": "61828977a4737fae268810d1",
            "name": "L3_RedCard",
            "project": "6182817751b7a0dd4843297a",
            "__v": 0,
            "data": {
                "name": "L3_RedCard",
                "football": {
                    "templates": [
                        {
                            "name": "Red Card"
                        }
                    ]
                },
                "width": 1920,
                "height": 1080,
                "folder": "UNIVERSAL",
                "aspect": "16x9",
                "timelines": [
                    {
                        "name": "IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": [
                                    {
                                        "property": "TIMELINE",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "name": "LOOP",
                                                "action": "PLAY"
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "2a803f10-57c8-49cc-81a2-317d2db11aff",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1445,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 23,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.269000244140625,
                                                    0.9235003662109376
                                                ],
                                                "type": "ease-out",
                                                "time": 460
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "37ae6058-ee1f-4cf2-992e-816ecef0d9ab",
                                "animations": [
                                    {
                                        "property": "width",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            },
                                            {
                                                "frame": 26,
                                                "value": 105,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 520
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "64bcd3c4-a3a5-4ad1-9001-423629561f9a",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 46,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 24,
                                                "value": 46,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 480
                                            },
                                            {
                                                "frame": 39,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 780
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "720e61c2-d2b4-4ebd-a35a-fb607cac149c",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 166,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 24,
                                                "value": 166,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 480
                                            },
                                            {
                                                "frame": 39,
                                                "value": 776,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.029000244140625,
                                                    0.9235003662109376
                                                ],
                                                "type": "ease-out",
                                                "time": 780
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "7b3ef1f0-d97d-4d7c-a41d-6a878cba3faa",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 24,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 480
                                            },
                                            {
                                                "frame": 39,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 780
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1336,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.189000244140625,
                                                    0.9235003662109376
                                                ],
                                                "type": "ease-out",
                                                "time": 20
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "8a92fc42-4288-4146-84aa-0bafd89a0a5a",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 968,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "c3d7204b-0b38-4163-9069-acec29c1736c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "bc6a08dd-efc8-4640-a04b-36a670b34302",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 550,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "9f65bb4f-4422-4404-9d79-04d13167bc34",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 24,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 480
                                            },
                                            {
                                                "frame": 39,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 780
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1336,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.189000244140625,
                                                    0.9235003662109376
                                                ],
                                                "type": "ease-out",
                                                "time": 20
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 39,
                        "loop": false,
                        "fps": 50,
                        "id": "53612447-3d4e-41de-8316-3707e1e52596"
                    },
                    {
                        "name": "OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "64bcd3c4-a3a5-4ad1-9001-423629561f9a",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 17,
                                                "value": 46,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.699000244140625,
                                                    0.20350036621093748
                                                ],
                                                "type": "ease-out",
                                                "time": 340
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "2a803f10-57c8-49cc-81a2-317d2db11aff",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 300
                                            },
                                            {
                                                "frame": 34,
                                                "value": 1453,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.779000244140625,
                                                    0.053500366210937456
                                                ],
                                                "type": "ease-out",
                                                "time": 680
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 35,
                        "loop": false,
                        "fps": 50,
                        "id": "e72b7904-ddeb-4ac2-99ea-00ba30030421"
                    },
                    {
                        "name": "CARD-IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "7b3ef1f0-d97d-4d7c-a41d-6a878cba3faa",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 300
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 49,
                                                "value": 1336,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.189000244140625,
                                                    0.9235003662109376
                                                ],
                                                "type": "ease-out",
                                                "time": 980
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 15,
                        "loop": false,
                        "fps": 50,
                        "id": "94eb5e83-e519-4a3a-96f6-9009c8eded06"
                    },
                    {
                        "name": "CARD-OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "7b3ef1f0-d97d-4d7c-a41d-6a878cba3faa",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 980,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.749000244140625,
                                                    0.04350036621093745
                                                ],
                                                "type": "ease-out",
                                                "time": 300
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 15,
                        "loop": false,
                        "fps": 50,
                        "id": "36df4d55-4685-4add-bba7-6b73b1e97711"
                    },
                    {
                        "name": "2CARD-IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "8a92fc42-4288-4146-84aa-0bafd89a0a5a",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 25,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.109000244140625,
                                                    0.9835003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 500
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1277,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 29,
                                                "value": 1277,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 580
                                            },
                                            {
                                                "frame": 35,
                                                "value": 1277,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 700
                                            },
                                            {
                                                "frame": 49,
                                                "value": 1336,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.189000244140625,
                                                    0.9235003662109376
                                                ],
                                                "type": "ease-out",
                                                "time": 980
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 62,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 1240
                                            },
                                            {
                                                "frame": 63,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 1260
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "7b3ef1f0-d97d-4d7c-a41d-6a878cba3faa",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 13,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 260
                                            },
                                            {
                                                "frame": 29,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.109000244140625,
                                                    0.9835003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 580
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 62,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 1240
                                            },
                                            {
                                                "frame": 63,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 1260
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 75,
                        "loop": false,
                        "fps": 50,
                        "id": "a5afb82d-e7e8-4553-b131-0ea813184aa1"
                    },
                    {
                        "name": "2CARD-OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 12,
                        "loop": false,
                        "fps": 50,
                        "id": "621013ba-c961-4b66-8ffc-5389f1d5f947"
                    },
                    {
                        "name": "UPDATE-IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "bc6a08dd-efc8-4640-a04b-36a670b34302",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 656,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 6,
                                                "value": 764,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 120
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "720e61c2-d2b4-4ebd-a35a-fb607cac149c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 88,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 22,
                                                "value": 776,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.279000244140625,
                                                    0.8435003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 440
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "c3d7204b-0b38-4163-9069-acec29c1736c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 25,
                        "loop": false,
                        "fps": 50,
                        "id": "5d542a8d-86b7-403a-b28e-5be69a910c9f"
                    },
                    {
                        "name": "UPDATE-OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "bc6a08dd-efc8-4640-a04b-36a670b34302",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 550,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 9,
                                                "value": 550,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 180
                                            },
                                            {
                                                "frame": 14,
                                                "value": 656,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "720e61c2-d2b4-4ebd-a35a-fb607cac149c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "c3d7204b-0b38-4163-9069-acec29c1736c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 14,
                        "loop": false,
                        "fps": 50,
                        "id": "c0b30e3e-c482-4bbb-9d44-c519eb5fc38c"
                    },
                    {
                        "name": "LOOP",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "c619cc80-c7c1-416b-8236-acc05ca399c5",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 515,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 150,
                                                "value": 533,
                                                "curve": [
                                                    0.42,
                                                    0,
                                                    0.58,
                                                    1
                                                ],
                                                "type": "ease-in-out",
                                                "time": 3000
                                            },
                                            {
                                                "frame": 300,
                                                "value": 515,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 6000
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "6fc539ea-4678-440a-b242-8df4992b2937",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1224,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 175,
                                                "value": 1207,
                                                "curve": [
                                                    0.42,
                                                    0,
                                                    0.58,
                                                    1
                                                ],
                                                "type": "ease-in-out",
                                                "time": 3500
                                            },
                                            {
                                                "frame": 300,
                                                "value": 1224,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 6000
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 300,
                        "loop": true,
                        "fps": 50,
                        "id": "a4aa0633-d95d-4330-b2c4-163754a9fdab"
                    }
                ],
                "scene": {
                    "children": [
                        {
                            "children": [
                                {
                                    "children": [],
                                    "uuid": "170bf835-10e7-4284-80d2-096637bf36a1",
                                    "type": "IMAGE",
                                    "name": "REF",
                                    "tint": 16777215,
                                    "x": "0",
                                    "y": "0",
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1920,
                                    "height": 1080,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchor": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "blendMode": 0,
                                    "mask": null,
                                    "visible": false,
                                    "src": "LowerThirdREF.jpg"
                                },
                                {
                                    "children": [],
                                    "uuid": "81981809-83ec-4e06-b646-5e80f0067b3b",
                                    "type": "RECTANGLE",
                                    "name": "Background",
                                    "x": "0",
                                    "y": "0",
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1920,
                                    "height": 1080,
                                    "alpha": 1,
                                    "graphicsData": {
                                        "x": 0,
                                        "y": 0,
                                        "width": 1920,
                                        "height": 1080,
                                        "radius": 0,
                                        "type": 4
                                    },
                                    "fillColor": 14355009,
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": false
                                },
                                {
                                    "children": [
                                        {
                                            "children": [],
                                            "uuid": "6fc539ea-4678-440a-b242-8df4992b2937",
                                            "type": "IMAGE",
                                            "name": "Stroke",
                                            "tint": 16777215,
                                            "x": 1210.569314516456,
                                            "y": 884,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 207,
                                            "height": 78,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "blendMode": 0,
                                            "mask": null,
                                            "visible": true,
                                            "src": "L3Stroke3.png"
                                        },
                                        {
                                            "children": [],
                                            "uuid": "c619cc80-c7c1-416b-8236-acc05ca399c5",
                                            "type": "IMAGE",
                                            "name": "Grad",
                                            "tint": 16777215,
                                            "x": 526.8346979862891,
                                            "y": 891,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 874,
                                            "height": 77,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "blendMode": 0,
                                            "mask": null,
                                            "visible": true,
                                            "src": "L3_Grad_name.png"
                                        },
                                        {
                                            "children": [],
                                            "uuid": "6f9a2488-e5f8-4549-892d-20815a20e20d",
                                            "type": "RECTANGLE",
                                            "name": "HeaderMASK",
                                            "x": 770,
                                            "y": 837,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 646.9999999999999,
                                            "height": 42,
                                            "alpha": 1,
                                            "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}",
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 646.9999999999999,
                                                "height": 42,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 10334,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.27,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "91ff368e-2550-4dc5-8ddc-6ec7aadbff0a",
                                                    "type": "RECTANGLE",
                                                    "name": "DarkBlueBG",
                                                    "x": 770,
                                                    "y": 837,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 170,
                                                    "height": 42,
                                                    "alpha": 1,
                                                    "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}\n// Executed when graphic\n// updated each frame\nconst padding = 20; \nconst text_node_name = 'Header'\n\nfunction update(item, frame, scene) { \nlet text = hyperAPI.getItemByName(scene.name, text_node_name); \nitem.width = (text.x - item.x) + text.width + padding; }",
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 170,
                                                        "height": 42,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 10334,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.27,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "624b4d39-ebb5-4669-b6ca-e88bc9ca0bd4",
                                                    "type": "TEXT",
                                                    "name": "Header",
                                                    "x": 777,
                                                    "y": 842,
                                                    "control": {
                                                        "id": "5",
                                                        "name": "Header Text"
                                                    },
                                                    "width": 143,
                                                    "height": 29,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "0",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9930555555555556,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 75,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#fcfcfc",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Medium.otf",
                                                        "_fontSize": 26,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "RED CARD",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                }
                                            ],
                                            "uuid": "64bcd3c4-a3a5-4ad1-9001-423629561f9a",
                                            "type": "GROUP",
                                            "name": "HeaderGRP",
                                            "x": 0,
                                            "y": 46,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 181.2027203409309,
                                            "height": 40.47837764736744,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": "6f9a2488-e5f8-4549-892d-20815a20e20d",
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "617531fc-62d4-4b9f-b2b0-299fdfcaabc8",
                                                    "type": "RECTANGLE",
                                                    "name": "BadgeMask",
                                                    "x": 554,
                                                    "y": 877,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 103,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 103,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.27,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "1f4db8da-ca3d-447d-b6bd-45cf9fe7b288",
                                                    "type": "RECTANGLE",
                                                    "name": "BadgeBG",
                                                    "x": 554,
                                                    "y": 877,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 103,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 103,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.27,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "95b56385-3579-4293-b3fb-2c8778156186",
                                                    "type": "IMAGE",
                                                    "name": "BADGE",
                                                    "tint": 16777215,
                                                    "x": 422,
                                                    "y": 856,
                                                    "control": {
                                                        "id": "1",
                                                        "name": "Team Badge",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Red Card",
                                                                    "field": "Team Badge"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 283,
                                                    "height": 283,
                                                    "alpha": 1,
                                                    "rotation": "12.2",
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.4223880597014925,
                                                        "y": 0.4223880597014925
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "617531fc-62d4-4b9f-b2b0-299fdfcaabc8",
                                                    "visible": true,
                                                    "src": "Arsenal.png"
                                                }
                                            ],
                                            "uuid": "b3386003-e1be-41cc-a227-a93aa6cf2358",
                                            "type": "GROUP",
                                            "name": "BadgeGRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 124.33851493510645,
                                            "height": 77.10167170927127,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "b7e9b022-7d19-472b-86b7-b9eff513c856",
                                            "type": "RECTANGLE",
                                            "name": "BlueBG",
                                            "x": 657,
                                            "y": 877,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 751,
                                            "height": 80,
                                            "alpha": 1,
                                            "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}",
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 751,
                                                "height": 80,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 7122399,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.27,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "720e61c2-d2b4-4ebd-a35a-fb607cac149c",
                                                    "type": "TEXT",
                                                    "name": "FirstName",
                                                    "x": 776,
                                                    "y": 896,
                                                    "control": {
                                                        "id": "3",
                                                        "name": "First Name",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Red Card",
                                                                    "field": "First Name Uppercase"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 111,
                                                    "height": 42,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "0",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9910714285714286,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 73,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#fcfcfc",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Medium.otf",
                                                        "_fontSize": 40,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "KEVIN",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "c3d7204b-0b38-4163-9069-acec29c1736c",
                                                    "type": "TEXT",
                                                    "name": "LastName",
                                                    "x": 899,
                                                    "y": 896,
                                                    "control": {
                                                        "id": "4",
                                                        "name": "Last Name",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Red Card",
                                                                    "field": "Last Name Uppercase"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 218,
                                                    "height": 42,
                                                    "alpha": 1,
                                                    "autoFollow": "720e61c2-d2b4-4ebd-a35a-fb607cac149c",
                                                    "autoFollowPadding": 12,
                                                    "maxWidth": "418",
                                                    "anchor": {
                                                        "x": "0",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9954337899543378,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 75,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#fcfcfc",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Bold.otf",
                                                        "_fontSize": 40,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "DE BRUYNE ",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                                    "type": "RECTANGLE",
                                                    "name": "NameMASK",
                                                    "x": 657,
                                                    "y": 877,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 751,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}",
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 751,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 7122399,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.27,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "7b3ef1f0-d97d-4d7c-a41d-6a878cba3faa",
                                                    "type": "RECTANGLE",
                                                    "name": "YELLOWCARD1",
                                                    "x": 1336,
                                                    "y": 888,
                                                    "control": {
                                                        "id": "6",
                                                        "name": "Yellow Card On/Off"
                                                    },
                                                    "width": 39,
                                                    "height": 55,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 39,
                                                        "height": 55,
                                                        "radius": 8,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16312092,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                                    "visible": false
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "8a92fc42-4288-4146-84aa-0bafd89a0a5a",
                                                    "type": "RECTANGLE",
                                                    "name": "YELLOWCARD2",
                                                    "x": 1336,
                                                    "y": 968,
                                                    "control": {
                                                        "id": "0",
                                                        "name": ""
                                                    },
                                                    "width": 39,
                                                    "height": 55,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 39,
                                                        "height": 55,
                                                        "radius": 8,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16312092,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                                    "visible": false
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "9f65bb4f-4422-4404-9d79-04d13167bc34",
                                                    "type": "RECTANGLE",
                                                    "name": "REDCARD",
                                                    "x": 1336,
                                                    "y": 888,
                                                    "control": {
                                                        "id": "7",
                                                        "name": "Red Card ON/OFF"
                                                    },
                                                    "width": 39,
                                                    "height": 55,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 39,
                                                        "height": 55,
                                                        "radius": 8,
                                                        "type": 4
                                                    },
                                                    "fillColor": 13632027,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                                    "visible": true
                                                }
                                            ],
                                            "uuid": "0f133c67-ba17-4030-86ee-31fd9e44b1da",
                                            "type": "GROUP",
                                            "name": "NameGRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 603.9553833007812,
                                            "height": 55,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "7b6c42a2-cdb0-4500-99b0-26715edfba47",
                                                    "type": "RECTANGLE",
                                                    "name": "WhiteBG",
                                                    "x": 656,
                                                    "y": 877,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 103,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 103,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.27,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "5d8112ee-d410-46ba-8680-c930380fa55a",
                                                    "type": "TEXT",
                                                    "name": "NUMBER",
                                                    "x": 700,
                                                    "y": "890",
                                                    "control": {
                                                        "id": "2",
                                                        "name": "Shirt Number",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Red Card",
                                                                    "field": "Shirt Number"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 60,
                                                    "height": 53,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "0.5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9836065573770492,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 52,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#000000",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 48,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "88",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "bc6a08dd-efc8-4640-a04b-36a670b34302",
                                                    "type": "RECTANGLE",
                                                    "name": "BlueWipe",
                                                    "x": 550,
                                                    "y": 877,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 103,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 103,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 10334,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.27,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                }
                                            ],
                                            "uuid": "43204316-ec4e-471d-af2f-7169c8540630",
                                            "type": "GROUP",
                                            "name": "NumberGRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 230.33851493510645,
                                            "height": 77.10167170927127,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": "37ae6058-ee1f-4cf2-992e-816ecef0d9ab",
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "37ae6058-ee1f-4cf2-992e-816ecef0d9ab",
                                            "type": "RECTANGLE",
                                            "name": "NumberMASK",
                                            "x": 656,
                                            "y": 877,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 105,
                                            "height": 80,
                                            "alpha": 1,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 105,
                                                "height": 80,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 8311585,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.27,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        }
                                    ],
                                    "uuid": "2a803f10-57c8-49cc-81a2-317d2db11aff",
                                    "type": "GROUP",
                                    "name": "L3_GRP",
                                    "x": 1453,
                                    "y": 0,
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 890.734619140625,
                                    "height": 91,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": true
                                }
                            ],
                            "uuid": "fc5c538c-86c7-4c96-bcc4-beaf0bfd9b9c",
                            "type": "GROUP",
                            "name": "Root",
                            "x": 0,
                            "y": 0,
                            "control": {
                                "id": 0,
                                "name": ""
                            },
                            "width": 890.734619140625,
                            "height": 91,
                            "alpha": 1,
                            "maxWidth": 0,
                            "anchorX": 0,
                            "anchorY": 0,
                            "skew": {
                                "x": 0,
                                "y": 0
                            },
                            "scale": {
                                "x": 1,
                                "y": 1
                            },
                            "mask": null,
                            "visible": true
                        }
                    ],
                    "uuid": "d5e9b400-21b0-4f14-8e64-33e541586d9e",
                    "type": "SCENE",
                    "name": "Root",
                    "x": 0,
                    "y": 0,
                    "control": {
                        "id": 0,
                        "name": ""
                    },
                    "alpha": 1,
                    "maxWidth": 0,
                    "skew": {
                        "x": 0,
                        "y": 0
                    },
                    "scale": {
                        "x": 1,
                        "y": 1
                    },
                    "mask": null,
                    "visible": false
                }
            }
        },
        {
            "_id": "61828977a4737fae268810df",
            "name": "L3_Substitute",
            "project": "6182817751b7a0dd4843297a",
            "__v": 0,
            "data": {
                "name": "L3_Substitute",
                "football": {
                    "templates": [
                        {
                            "name": "Substitution"
                        }
                    ]
                },
                "width": 1920,
                "height": 1080,
                "folder": "UNIVERSAL",
                "aspect": "16x9",
                "timelines": [
                    {
                        "name": "IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": [
                                    {
                                        "property": "TIMELINE",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "name": "LOOP",
                                                "action": "PLAY"
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "2a803f10-57c8-49cc-81a2-317d2db11aff",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1445,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 23,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.269000244140625,
                                                    0.9235003662109376
                                                ],
                                                "type": "ease-out",
                                                "time": 460
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "37ae6058-ee1f-4cf2-992e-816ecef0d9ab",
                                "animations": [
                                    {
                                        "property": "width",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            },
                                            {
                                                "frame": 26,
                                                "value": 105,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 520
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "64bcd3c4-a3a5-4ad1-9001-423629561f9a",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 46,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 24,
                                                "value": 46,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 480
                                            },
                                            {
                                                "frame": 39,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 780
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "720e61c2-d2b4-4ebd-a35a-fb607cac149c",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 166,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 24,
                                                "value": 166,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 480
                                            },
                                            {
                                                "frame": 39,
                                                "value": 776,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.029000244140625,
                                                    0.9235003662109376
                                                ],
                                                "type": "ease-out",
                                                "time": 780
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "7b3ef1f0-d97d-4d7c-a41d-6a878cba3faa",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 973,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "8a92fc42-4288-4146-84aa-0bafd89a0a5a",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 968,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "a1ce6338-0ad6-4550-8076-b4499b0704b6",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "c3d7204b-0b38-4163-9069-acec29c1736c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "bc6a08dd-efc8-4640-a04b-36a670b34302",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 550,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "be1f8c2c-d0ab-4c28-a944-4ca18e1e9527",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 792,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 24,
                                                "value": 792,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 480
                                            },
                                            {
                                                "frame": 39,
                                                "value": 882,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 780
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "77b78f3c-a4f7-471f-b74c-b4cfe4ee132f",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 997,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 39,
                        "loop": false,
                        "fps": 50,
                        "id": "343515d3-f21a-4248-808a-30478f0833d8"
                    },
                    {
                        "name": "OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "64bcd3c4-a3a5-4ad1-9001-423629561f9a",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 17,
                                                "value": 46,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.699000244140625,
                                                    0.20350036621093748
                                                ],
                                                "type": "ease-out",
                                                "time": 340
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "2a803f10-57c8-49cc-81a2-317d2db11aff",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 300
                                            },
                                            {
                                                "frame": 34,
                                                "value": 1453,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.779000244140625,
                                                    0.053500366210937456
                                                ],
                                                "type": "ease-out",
                                                "time": 680
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 35,
                        "loop": false,
                        "fps": 50,
                        "id": "bef91403-615b-428f-895d-3459b616d2fa"
                    },
                    {
                        "name": "CARD-IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "7b3ef1f0-d97d-4d7c-a41d-6a878cba3faa",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 300
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 49,
                                                "value": 1336,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.189000244140625,
                                                    0.9235003662109376
                                                ],
                                                "type": "ease-out",
                                                "time": 980
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 15,
                        "loop": false,
                        "fps": 50,
                        "id": "34c6fcdc-58e0-4f1e-b4ac-bf64f8dbb654"
                    },
                    {
                        "name": "CARD-OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "7b3ef1f0-d97d-4d7c-a41d-6a878cba3faa",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 980,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.749000244140625,
                                                    0.04350036621093745
                                                ],
                                                "type": "ease-out",
                                                "time": 300
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 15,
                        "loop": false,
                        "fps": 50,
                        "id": "48bbaf6b-1a4f-4e81-a1da-45cf7b9a8702"
                    },
                    {
                        "name": "2CARD-iN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "8a92fc42-4288-4146-84aa-0bafd89a0a5a",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 25,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.109000244140625,
                                                    0.9835003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 500
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1277,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 29,
                                                "value": 1277,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 580
                                            },
                                            {
                                                "frame": 35,
                                                "value": 1277,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 700
                                            },
                                            {
                                                "frame": 49,
                                                "value": 1336,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.189000244140625,
                                                    0.9235003662109376
                                                ],
                                                "type": "ease-out",
                                                "time": 980
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 62,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 1240
                                            },
                                            {
                                                "frame": 63,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 1260
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "7b3ef1f0-d97d-4d7c-a41d-6a878cba3faa",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 13,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 260
                                            },
                                            {
                                                "frame": 29,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.109000244140625,
                                                    0.9835003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 580
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 62,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 1240
                                            },
                                            {
                                                "frame": 63,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 1260
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "a1ce6338-0ad6-4550-8076-b4499b0704b6",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 52,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 1040
                                            },
                                            {
                                                "frame": 62,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 1240
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 75,
                        "loop": false,
                        "fps": 50,
                        "id": "e9d0b0b8-4b0a-4665-b6bc-22521529831f"
                    },
                    {
                        "name": "2CARD-OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "a1ce6338-0ad6-4550-8076-b4499b0704b6",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 12,
                                                "value": 958,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 240
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 12,
                        "loop": false,
                        "fps": 50,
                        "id": "ab0a1bff-ba90-412b-b15a-4f60ddb32f05"
                    },
                    {
                        "name": "UPDATE-IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": [
                                    {
                                        "property": "TIMELINE",
                                        "keyframes": []
                                    }
                                ]
                            },
                            {
                                "object": "bc6a08dd-efc8-4640-a04b-36a670b34302",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 656,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 6,
                                                "value": 764,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 120
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "720e61c2-d2b4-4ebd-a35a-fb607cac149c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 88,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 22,
                                                "value": 776,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.279000244140625,
                                                    0.8435003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 440
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "c3d7204b-0b38-4163-9069-acec29c1736c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "77b78f3c-a4f7-471f-b74c-b4cfe4ee132f",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 997,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 880,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 300
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 25,
                        "loop": false,
                        "fps": 50,
                        "id": "5f3a30d1-838c-4716-84c9-d1144247f984"
                    },
                    {
                        "name": "UPDATE-OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "bc6a08dd-efc8-4640-a04b-36a670b34302",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 550,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 9,
                                                "value": 550,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 180
                                            },
                                            {
                                                "frame": 14,
                                                "value": 656,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "720e61c2-d2b4-4ebd-a35a-fb607cac149c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "c3d7204b-0b38-4163-9069-acec29c1736c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "be1f8c2c-d0ab-4c28-a944-4ca18e1e9527",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 882,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 968,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "77b78f3c-a4f7-471f-b74c-b4cfe4ee132f",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 997,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 14,
                        "loop": false,
                        "fps": 50,
                        "id": "8caf6f9e-c5bc-40f2-a8c5-6f32554f6b55"
                    },
                    {
                        "name": "LOOP",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "c619cc80-c7c1-416b-8236-acc05ca399c5",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 515,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 150,
                                                "value": 533,
                                                "curve": [
                                                    0.42,
                                                    0,
                                                    0.58,
                                                    1
                                                ],
                                                "type": "ease-in-out",
                                                "time": 3000
                                            },
                                            {
                                                "frame": 300,
                                                "value": 515,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 6000
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "6fc539ea-4678-440a-b242-8df4992b2937",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1224,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 175,
                                                "value": 1207,
                                                "curve": [
                                                    0.42,
                                                    0,
                                                    0.58,
                                                    1
                                                ],
                                                "type": "ease-in-out",
                                                "time": 3500
                                            },
                                            {
                                                "frame": 300,
                                                "value": 1224,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 6000
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 300,
                        "loop": true,
                        "fps": 50,
                        "id": "11f5f005-7e59-490f-a050-bf690c9f051f"
                    }
                ],
                "scene": {
                    "children": [
                        {
                            "children": [
                                {
                                    "children": [],
                                    "uuid": "170bf835-10e7-4284-80d2-096637bf36a1",
                                    "type": "IMAGE",
                                    "name": "REF",
                                    "tint": 16777215,
                                    "x": "0",
                                    "y": "0",
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1920,
                                    "height": 1080,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchor": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "blendMode": 0,
                                    "mask": null,
                                    "visible": false,
                                    "src": "LowerThirdREF.jpg"
                                },
                                {
                                    "children": [],
                                    "uuid": "81981809-83ec-4e06-b646-5e80f0067b3b",
                                    "type": "RECTANGLE",
                                    "name": "Background",
                                    "x": "0",
                                    "y": "0",
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1920,
                                    "height": 1080,
                                    "alpha": 1,
                                    "graphicsData": {
                                        "x": 0,
                                        "y": 0,
                                        "width": 1920,
                                        "height": 1080,
                                        "radius": 0,
                                        "type": 4
                                    },
                                    "fillColor": 14355009,
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": false
                                },
                                {
                                    "children": [
                                        {
                                            "children": [],
                                            "uuid": "6fc539ea-4678-440a-b242-8df4992b2937",
                                            "type": "IMAGE",
                                            "name": "Stroke",
                                            "tint": 16777215,
                                            "x": 1210.569314516456,
                                            "y": 884,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 207,
                                            "height": 78,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "blendMode": 0,
                                            "mask": null,
                                            "visible": true,
                                            "src": "L3Stroke3.png"
                                        },
                                        {
                                            "children": [],
                                            "uuid": "c619cc80-c7c1-416b-8236-acc05ca399c5",
                                            "type": "IMAGE",
                                            "name": "Grad",
                                            "tint": 16777215,
                                            "x": 526.8346979862891,
                                            "y": 891,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 874,
                                            "height": 77,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "blendMode": 0,
                                            "mask": null,
                                            "visible": true,
                                            "src": "L3_Grad_name.png"
                                        },
                                        {
                                            "children": [],
                                            "uuid": "6f9a2488-e5f8-4549-892d-20815a20e20d",
                                            "type": "RECTANGLE",
                                            "name": "HeaderMASK",
                                            "x": 770,
                                            "y": 837,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 646.9999999999999,
                                            "height": 42,
                                            "alpha": 1,
                                            "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}",
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 646.9999999999999,
                                                "height": 42,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 10334,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.27,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "91ff368e-2550-4dc5-8ddc-6ec7aadbff0a",
                                                    "type": "RECTANGLE",
                                                    "name": "DarkBlueBG",
                                                    "x": 770,
                                                    "y": 837,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 194,
                                                    "height": 42,
                                                    "alpha": 1,
                                                    "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}\n// Executed when graphic\n// updated each frame\nconst padding = 20; \nconst text_node_name = 'Header'\n\nfunction update(item, frame, scene) { \nlet text = hyperAPI.getItemByName(scene.name, text_node_name); \nitem.width = (text.x - item.x) + text.width + padding; }",
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 194,
                                                        "height": 42,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 10334,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.27,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "624b4d39-ebb5-4669-b6ca-e88bc9ca0bd4",
                                                    "type": "TEXT",
                                                    "name": "Header",
                                                    "x": 777,
                                                    "y": 842,
                                                    "control": {
                                                        "id": "5",
                                                        "name": "Header Text"
                                                    },
                                                    "width": 167,
                                                    "height": 29,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "0",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9940476190476191,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 75,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#fcfcfc",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Medium.otf",
                                                        "_fontSize": 26,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "SUBSTITUTE",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                }
                                            ],
                                            "uuid": "64bcd3c4-a3a5-4ad1-9001-423629561f9a",
                                            "type": "GROUP",
                                            "name": "HeaderGRP",
                                            "x": 0,
                                            "y": 46,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 205.2027203409309,
                                            "height": 40.47837764736744,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": "6f9a2488-e5f8-4549-892d-20815a20e20d",
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "617531fc-62d4-4b9f-b2b0-299fdfcaabc8",
                                                    "type": "RECTANGLE",
                                                    "name": "BadgeMask",
                                                    "x": 554,
                                                    "y": 877,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 103,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 103,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.27,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "1f4db8da-ca3d-447d-b6bd-45cf9fe7b288",
                                                    "type": "RECTANGLE",
                                                    "name": "BadgeBG",
                                                    "x": 554,
                                                    "y": 877,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 103,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 103,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.27,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "95b56385-3579-4293-b3fb-2c8778156186",
                                                    "type": "IMAGE",
                                                    "name": "BADGE",
                                                    "tint": 16777215,
                                                    "x": 422,
                                                    "y": 856,
                                                    "control": {
                                                        "id": "1",
                                                        "name": "Team Badge",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Substitution",
                                                                    "field": "Team Badge"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 283,
                                                    "height": 283,
                                                    "alpha": 1,
                                                    "rotation": "12.2",
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.4223880597014925,
                                                        "y": 0.4223880597014925
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "617531fc-62d4-4b9f-b2b0-299fdfcaabc8",
                                                    "visible": true,
                                                    "src": "Arsenal.png"
                                                }
                                            ],
                                            "uuid": "b3386003-e1be-41cc-a227-a93aa6cf2358",
                                            "type": "GROUP",
                                            "name": "BadgeGRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 124.33851493510645,
                                            "height": 77.10167170927127,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "b7e9b022-7d19-472b-86b7-b9eff513c856",
                                            "type": "RECTANGLE",
                                            "name": "BlueBG",
                                            "x": 657,
                                            "y": 877,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 751,
                                            "height": 80,
                                            "alpha": 1,
                                            "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}",
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 751,
                                                "height": 80,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 7122399,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.27,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "720e61c2-d2b4-4ebd-a35a-fb607cac149c",
                                                    "type": "TEXT",
                                                    "name": "FirstName",
                                                    "x": 776,
                                                    "y": 896,
                                                    "control": {
                                                        "id": "3",
                                                        "name": "First Name",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Substitution",
                                                                    "field": "First Name Uppercase"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 111,
                                                    "height": 42,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "0",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9910714285714286,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 73,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#fcfcfc",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Medium.otf",
                                                        "_fontSize": 40,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "KEVIN",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "c3d7204b-0b38-4163-9069-acec29c1736c",
                                                    "type": "TEXT",
                                                    "name": "LastName",
                                                    "x": 899,
                                                    "y": 896,
                                                    "control": {
                                                        "id": "4",
                                                        "name": "Last Name",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Substitution",
                                                                    "field": "Last Name Uppercase"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 218,
                                                    "height": 42,
                                                    "alpha": 1,
                                                    "autoFollow": "720e61c2-d2b4-4ebd-a35a-fb607cac149c",
                                                    "autoFollowPadding": 12,
                                                    "maxWidth": "418",
                                                    "anchor": {
                                                        "x": "0",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9954337899543378,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 75,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#fcfcfc",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Bold.otf",
                                                        "_fontSize": 40,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "DE BRUYNE ",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                                    "type": "RECTANGLE",
                                                    "name": "NameMASK",
                                                    "x": 657,
                                                    "y": 877,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 751,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}",
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 751,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 7122399,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.27,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "7b3ef1f0-d97d-4d7c-a41d-6a878cba3faa",
                                                    "type": "RECTANGLE",
                                                    "name": "YELLOWCARD1",
                                                    "x": 1336,
                                                    "y": 973,
                                                    "control": {
                                                        "id": "5",
                                                        "name": "Card"
                                                    },
                                                    "width": 39,
                                                    "height": 55,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 39,
                                                        "height": 55,
                                                        "radius": 8,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16312092,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "8a92fc42-4288-4146-84aa-0bafd89a0a5a",
                                                    "type": "RECTANGLE",
                                                    "name": "YELLOWCARD2",
                                                    "x": 1336,
                                                    "y": 968,
                                                    "control": {
                                                        "id": "5",
                                                        "name": "Card"
                                                    },
                                                    "width": 39,
                                                    "height": 55,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 39,
                                                        "height": 55,
                                                        "radius": 8,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16312092,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "a1ce6338-0ad6-4550-8076-b4499b0704b6",
                                                    "type": "RECTANGLE",
                                                    "name": "REDCARD",
                                                    "x": 1336,
                                                    "y": 888,
                                                    "control": {
                                                        "id": "5",
                                                        "name": "Card"
                                                    },
                                                    "width": 39,
                                                    "height": 55,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 39,
                                                        "height": 55,
                                                        "radius": 8,
                                                        "type": 4
                                                    },
                                                    "fillColor": 13632027,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                                    "visible": true
                                                }
                                            ],
                                            "uuid": "0f133c67-ba17-4030-86ee-31fd9e44b1da",
                                            "type": "GROUP",
                                            "name": "NameGRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 603.9553833007812,
                                            "height": 55,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "7b6c42a2-cdb0-4500-99b0-26715edfba47",
                                                    "type": "RECTANGLE",
                                                    "name": "WhiteBG",
                                                    "x": 656,
                                                    "y": 877,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 103,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 103,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.27,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "5d8112ee-d410-46ba-8680-c930380fa55a",
                                                    "type": "TEXT",
                                                    "name": "NUMBER",
                                                    "x": 700,
                                                    "y": "890",
                                                    "control": {
                                                        "id": "2",
                                                        "name": "Shirt Number",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Substitution",
                                                                    "field": "Shirt Number"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 60,
                                                    "height": 53,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "0.5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9836065573770492,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 52,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#000000",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 48,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "88",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "bc6a08dd-efc8-4640-a04b-36a670b34302",
                                                    "type": "RECTANGLE",
                                                    "name": "BlueWipe",
                                                    "x": 550,
                                                    "y": 877,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 103,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 103,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 10334,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.27,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                }
                                            ],
                                            "uuid": "43204316-ec4e-471d-af2f-7169c8540630",
                                            "type": "GROUP",
                                            "name": "NumberGRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 230.33851493510645,
                                            "height": 77.10167170927127,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": "37ae6058-ee1f-4cf2-992e-816ecef0d9ab",
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "37ae6058-ee1f-4cf2-992e-816ecef0d9ab",
                                            "type": "RECTANGLE",
                                            "name": "NumberMASK",
                                            "x": 656,
                                            "y": 877,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 105,
                                            "height": 80,
                                            "alpha": 1,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 105,
                                                "height": 80,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 8311585,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.27,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "77b78f3c-a4f7-471f-b74c-b4cfe4ee132f",
                                            "type": "IMAGE",
                                            "name": "SubON_Icon",
                                            "tint": 16777215,
                                            "x": 1337,
                                            "y": 997,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 43.95959595959595,
                                            "height": 64,
                                            "alpha": 1,
                                            "rotation": "0",
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 0.6464646464646463,
                                                "y": 0.6464646464646465
                                            },
                                            "blendMode": 0,
                                            "mask": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                            "visible": true,
                                            "src": "SubOn.png"
                                        },
                                        {
                                            "children": [],
                                            "uuid": "be1f8c2c-d0ab-4c28-a944-4ca18e1e9527",
                                            "type": "IMAGE",
                                            "name": "SubOFF_Icon",
                                            "tint": 16777215,
                                            "x": 1337,
                                            "y": 882,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 43.95959595959595,
                                            "height": 64,
                                            "alpha": 1,
                                            "rotation": "0",
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 0.6464646464646463,
                                                "y": 0.6464646464646465
                                            },
                                            "blendMode": 0,
                                            "mask": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                            "visible": true,
                                            "src": "SubOff.png"
                                        }
                                    ],
                                    "uuid": "2a803f10-57c8-49cc-81a2-317d2db11aff",
                                    "type": "GROUP",
                                    "name": "L3_GRP",
                                    "x": 1453,
                                    "y": 0,
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 890.734619140625,
                                    "height": 91,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": true
                                }
                            ],
                            "uuid": "fc5c538c-86c7-4c96-bcc4-beaf0bfd9b9c",
                            "type": "GROUP",
                            "name": "Root",
                            "x": 0,
                            "y": 0,
                            "control": {
                                "id": 0,
                                "name": ""
                            },
                            "width": 890.734619140625,
                            "height": 91,
                            "alpha": 1,
                            "maxWidth": 0,
                            "anchorX": 0,
                            "anchorY": 0,
                            "skew": {
                                "x": 0,
                                "y": 0
                            },
                            "scale": {
                                "x": 1,
                                "y": 1
                            },
                            "mask": null,
                            "visible": true
                        }
                    ],
                    "uuid": "d5e9b400-21b0-4f14-8e64-33e541586d9e",
                    "type": "SCENE",
                    "name": "Root",
                    "x": 0,
                    "y": 0,
                    "control": {
                        "id": 0,
                        "name": ""
                    },
                    "alpha": 1,
                    "maxWidth": 0,
                    "skew": {
                        "x": 0,
                        "y": 0
                    },
                    "scale": {
                        "x": 1,
                        "y": 1
                    },
                    "mask": null,
                    "visible": false
                }
            }
        },
        {
            "_id": "61828977a4737fae26881109",
            "name": "L3_YellowCard",
            "project": "6182817751b7a0dd4843297a",
            "__v": 0,
            "data": {
                "name": "L3_YellowCard",
                "football": {
                    "templates": [
                        {
                            "name": "Yellow Card"
                        }
                    ]
                },
                "width": 1920,
                "height": 1080,
                "folder": "UNIVERSAL",
                "aspect": "16x9",
                "timelines": [
                    {
                        "name": "IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": [
                                    {
                                        "property": "TIMELINE",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "name": "LOOP",
                                                "action": "PLAY"
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "2a803f10-57c8-49cc-81a2-317d2db11aff",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1445,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 23,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.269000244140625,
                                                    0.9235003662109376
                                                ],
                                                "type": "ease-out",
                                                "time": 460
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "37ae6058-ee1f-4cf2-992e-816ecef0d9ab",
                                "animations": [
                                    {
                                        "property": "width",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            },
                                            {
                                                "frame": 26,
                                                "value": 105,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 520
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "64bcd3c4-a3a5-4ad1-9001-423629561f9a",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 46,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 24,
                                                "value": 46,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 480
                                            },
                                            {
                                                "frame": 39,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 780
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "720e61c2-d2b4-4ebd-a35a-fb607cac149c",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 166,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 24,
                                                "value": 166,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 480
                                            },
                                            {
                                                "frame": 39,
                                                "value": 776,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.029000244140625,
                                                    0.9235003662109376
                                                ],
                                                "type": "ease-out",
                                                "time": 780
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "7b3ef1f0-d97d-4d7c-a41d-6a878cba3faa",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 24,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 480
                                            },
                                            {
                                                "frame": 39,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 780
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1336,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.189000244140625,
                                                    0.9235003662109376
                                                ],
                                                "type": "ease-out",
                                                "time": 20
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "8a92fc42-4288-4146-84aa-0bafd89a0a5a",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 968,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "c3d7204b-0b38-4163-9069-acec29c1736c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "bc6a08dd-efc8-4640-a04b-36a670b34302",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 550,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "9f65bb4f-4422-4404-9d79-04d13167bc34",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 24,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 480
                                            },
                                            {
                                                "frame": 39,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 780
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1336,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.189000244140625,
                                                    0.9235003662109376
                                                ],
                                                "type": "ease-out",
                                                "time": 20
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 39,
                        "loop": false,
                        "fps": 50,
                        "id": "200a0022-5b5b-4804-b665-99eaa0b4224c"
                    },
                    {
                        "name": "OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "64bcd3c4-a3a5-4ad1-9001-423629561f9a",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 17,
                                                "value": 46,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.699000244140625,
                                                    0.20350036621093748
                                                ],
                                                "type": "ease-out",
                                                "time": 340
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "2a803f10-57c8-49cc-81a2-317d2db11aff",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 300
                                            },
                                            {
                                                "frame": 34,
                                                "value": 1453,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.779000244140625,
                                                    0.053500366210937456
                                                ],
                                                "type": "ease-out",
                                                "time": 680
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 35,
                        "loop": false,
                        "fps": 50,
                        "id": "534b5f44-ad06-4dea-894e-7386fcdfe51a"
                    },
                    {
                        "name": "CARD-IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "7b3ef1f0-d97d-4d7c-a41d-6a878cba3faa",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 300
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 49,
                                                "value": 1336,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.189000244140625,
                                                    0.9235003662109376
                                                ],
                                                "type": "ease-out",
                                                "time": 980
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 15,
                        "loop": false,
                        "fps": 50,
                        "id": "81638e53-bf20-469f-b018-28f559cbc94d"
                    },
                    {
                        "name": "CARD-OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "7b3ef1f0-d97d-4d7c-a41d-6a878cba3faa",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 980,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.749000244140625,
                                                    0.04350036621093745
                                                ],
                                                "type": "ease-out",
                                                "time": 300
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 15,
                        "loop": false,
                        "fps": 50,
                        "id": "df875b72-0a3b-4be0-be1b-44a3453d56cd"
                    },
                    {
                        "name": "2CARD-IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "8a92fc42-4288-4146-84aa-0bafd89a0a5a",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 25,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.109000244140625,
                                                    0.9835003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 500
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1277,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 29,
                                                "value": 1277,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 580
                                            },
                                            {
                                                "frame": 35,
                                                "value": 1277,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 700
                                            },
                                            {
                                                "frame": 49,
                                                "value": 1336,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.189000244140625,
                                                    0.9235003662109376
                                                ],
                                                "type": "ease-out",
                                                "time": 980
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 62,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 1240
                                            },
                                            {
                                                "frame": 63,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 1260
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "7b3ef1f0-d97d-4d7c-a41d-6a878cba3faa",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 13,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 260
                                            },
                                            {
                                                "frame": 29,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.109000244140625,
                                                    0.9835003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 580
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 62,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 1240
                                            },
                                            {
                                                "frame": 63,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 1260
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 75,
                        "loop": false,
                        "fps": 50,
                        "id": "8fb34b1e-f6e1-4e8b-adcb-654467ca2122"
                    },
                    {
                        "name": "2CARD-OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 12,
                        "loop": false,
                        "fps": 50,
                        "id": "e9f3b217-a036-4d16-8d21-67a30986531e"
                    },
                    {
                        "name": "UPDATE-IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "bc6a08dd-efc8-4640-a04b-36a670b34302",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 656,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 6,
                                                "value": 764,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 120
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "720e61c2-d2b4-4ebd-a35a-fb607cac149c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 88,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 22,
                                                "value": 776,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.279000244140625,
                                                    0.8435003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 440
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "c3d7204b-0b38-4163-9069-acec29c1736c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 25,
                        "loop": false,
                        "fps": 50,
                        "id": "3fb24ddb-9951-4878-aa3a-3a6d2879605b"
                    },
                    {
                        "name": "UPDATE-OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "bc6a08dd-efc8-4640-a04b-36a670b34302",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 550,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 9,
                                                "value": 550,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 180
                                            },
                                            {
                                                "frame": 14,
                                                "value": 656,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "720e61c2-d2b4-4ebd-a35a-fb607cac149c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "c3d7204b-0b38-4163-9069-acec29c1736c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 14,
                        "loop": false,
                        "fps": 50,
                        "id": "24c4acfc-e680-4269-8ad4-c63cd3fcdc7f"
                    },
                    {
                        "name": "LOOP",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "c619cc80-c7c1-416b-8236-acc05ca399c5",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 515,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 150,
                                                "value": 533,
                                                "curve": [
                                                    0.42,
                                                    0,
                                                    0.58,
                                                    1
                                                ],
                                                "type": "ease-in-out",
                                                "time": 3000
                                            },
                                            {
                                                "frame": 300,
                                                "value": 515,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 6000
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "6fc539ea-4678-440a-b242-8df4992b2937",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1224,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 175,
                                                "value": 1207,
                                                "curve": [
                                                    0.42,
                                                    0,
                                                    0.58,
                                                    1
                                                ],
                                                "type": "ease-in-out",
                                                "time": 3500
                                            },
                                            {
                                                "frame": 300,
                                                "value": 1224,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 6000
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 300,
                        "loop": true,
                        "fps": 50,
                        "id": "56c6e0f1-64d4-4537-90b1-f9485e43fea3"
                    }
                ],
                "scene": {
                    "children": [
                        {
                            "children": [
                                {
                                    "children": [],
                                    "uuid": "170bf835-10e7-4284-80d2-096637bf36a1",
                                    "type": "IMAGE",
                                    "name": "REF",
                                    "tint": 16777215,
                                    "x": "0",
                                    "y": "0",
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1920,
                                    "height": 1080,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchor": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "blendMode": 0,
                                    "mask": null,
                                    "visible": false,
                                    "src": "LowerThirdREF.jpg"
                                },
                                {
                                    "children": [],
                                    "uuid": "81981809-83ec-4e06-b646-5e80f0067b3b",
                                    "type": "RECTANGLE",
                                    "name": "Background",
                                    "x": "0",
                                    "y": "0",
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1920,
                                    "height": 1080,
                                    "alpha": 1,
                                    "graphicsData": {
                                        "x": 0,
                                        "y": 0,
                                        "width": 1920,
                                        "height": 1080,
                                        "radius": 0,
                                        "type": 4
                                    },
                                    "fillColor": 14355009,
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": false
                                },
                                {
                                    "children": [
                                        {
                                            "children": [],
                                            "uuid": "6fc539ea-4678-440a-b242-8df4992b2937",
                                            "type": "IMAGE",
                                            "name": "Stroke",
                                            "tint": 16777215,
                                            "x": 1224,
                                            "y": 884,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 207,
                                            "height": 78,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "blendMode": 0,
                                            "mask": null,
                                            "visible": true,
                                            "src": "L3Stroke3.png"
                                        },
                                        {
                                            "children": [],
                                            "uuid": "c619cc80-c7c1-416b-8236-acc05ca399c5",
                                            "type": "IMAGE",
                                            "name": "Grad",
                                            "tint": 16777215,
                                            "x": 515,
                                            "y": 891,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 874,
                                            "height": 77,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "blendMode": 0,
                                            "mask": null,
                                            "visible": true,
                                            "src": "L3_Grad_name.png"
                                        },
                                        {
                                            "children": [],
                                            "uuid": "6f9a2488-e5f8-4549-892d-20815a20e20d",
                                            "type": "RECTANGLE",
                                            "name": "HeaderMASK",
                                            "x": 770,
                                            "y": 837,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 646.9999999999999,
                                            "height": 42,
                                            "alpha": 1,
                                            "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}",
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 646.9999999999999,
                                                "height": 42,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 10334,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.27,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "91ff368e-2550-4dc5-8ddc-6ec7aadbff0a",
                                                    "type": "RECTANGLE",
                                                    "name": "DarkBlueBG",
                                                    "x": 770,
                                                    "y": 837,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 230,
                                                    "height": 42,
                                                    "alpha": 1,
                                                    "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}\n// Executed when graphic\n// updated each frame\nconst padding = 20; \nconst text_node_name = 'Header'\n\nfunction update(item, frame, scene) { \nlet text = hyperAPI.getItemByName(scene.name, text_node_name); \nitem.width = (text.x - item.x) + text.width + padding; }",
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 230,
                                                        "height": 42,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 10334,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.27,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "624b4d39-ebb5-4669-b6ca-e88bc9ca0bd4",
                                                    "type": "TEXT",
                                                    "name": "Header",
                                                    "x": 777,
                                                    "y": 842,
                                                    "control": {
                                                        "id": "5",
                                                        "name": "Header Text"
                                                    },
                                                    "width": 203,
                                                    "height": 29,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "0",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9950980392156863,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 75,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#fcfcfc",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Medium.otf",
                                                        "_fontSize": 26,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "YELLOW CARD",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                }
                                            ],
                                            "uuid": "64bcd3c4-a3a5-4ad1-9001-423629561f9a",
                                            "type": "GROUP",
                                            "name": "HeaderGRP",
                                            "x": 0,
                                            "y": 46,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 241.2027203409309,
                                            "height": 40.47837764736744,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": "6f9a2488-e5f8-4549-892d-20815a20e20d",
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "617531fc-62d4-4b9f-b2b0-299fdfcaabc8",
                                                    "type": "RECTANGLE",
                                                    "name": "BadgeMask",
                                                    "x": 554,
                                                    "y": 877,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 103,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 103,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.27,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "1f4db8da-ca3d-447d-b6bd-45cf9fe7b288",
                                                    "type": "RECTANGLE",
                                                    "name": "BadgeBG",
                                                    "x": 554,
                                                    "y": 877,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 103,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 103,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.27,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "95b56385-3579-4293-b3fb-2c8778156186",
                                                    "type": "IMAGE",
                                                    "name": "BADGE",
                                                    "tint": 16777215,
                                                    "x": 422,
                                                    "y": 856,
                                                    "control": {
                                                        "id": "1",
                                                        "name": "Team Badge",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Yellow Card",
                                                                    "field": "Team Badge"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 283,
                                                    "height": 283,
                                                    "alpha": 1,
                                                    "rotation": "12.2",
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.4223880597014925,
                                                        "y": 0.4223880597014925
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "617531fc-62d4-4b9f-b2b0-299fdfcaabc8",
                                                    "visible": true,
                                                    "src": "Arsenal.png"
                                                }
                                            ],
                                            "uuid": "b3386003-e1be-41cc-a227-a93aa6cf2358",
                                            "type": "GROUP",
                                            "name": "BadgeGRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 124.33851493510645,
                                            "height": 77.10167170927127,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "b7e9b022-7d19-472b-86b7-b9eff513c856",
                                            "type": "RECTANGLE",
                                            "name": "BlueBG",
                                            "x": 657,
                                            "y": 877,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 751,
                                            "height": 80,
                                            "alpha": 1,
                                            "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}",
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 751,
                                                "height": 80,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 7122399,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.27,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "720e61c2-d2b4-4ebd-a35a-fb607cac149c",
                                                    "type": "TEXT",
                                                    "name": "FirstName",
                                                    "x": 776,
                                                    "y": 896,
                                                    "control": {
                                                        "id": "3",
                                                        "name": "First Name",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Yellow Card",
                                                                    "field": "First Name Uppercase"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 111,
                                                    "height": 42,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "0",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9910714285714286,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 73,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#fcfcfc",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Medium.otf",
                                                        "_fontSize": 40,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "KEVIN",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "c3d7204b-0b38-4163-9069-acec29c1736c",
                                                    "type": "TEXT",
                                                    "name": "LastName",
                                                    "x": 899,
                                                    "y": 896,
                                                    "control": {
                                                        "id": "4",
                                                        "name": "Last Name",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Yellow Card",
                                                                    "field": "Last Name Uppercase"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 218,
                                                    "height": 42,
                                                    "alpha": 1,
                                                    "autoFollow": "720e61c2-d2b4-4ebd-a35a-fb607cac149c",
                                                    "autoFollowPadding": 12,
                                                    "maxWidth": "418",
                                                    "anchor": {
                                                        "x": "0",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9954337899543378,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 75,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#fcfcfc",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Bold.otf",
                                                        "_fontSize": 40,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "DE BRUYNE ",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                                    "type": "RECTANGLE",
                                                    "name": "NameMASK",
                                                    "x": 657,
                                                    "y": 877,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 751,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}",
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 751,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 7122399,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.27,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "7b3ef1f0-d97d-4d7c-a41d-6a878cba3faa",
                                                    "type": "RECTANGLE",
                                                    "name": "YELLOWCARD1",
                                                    "x": 1336,
                                                    "y": 888,
                                                    "control": {
                                                        "id": "6",
                                                        "name": "Yellow Card On/Off"
                                                    },
                                                    "width": 39,
                                                    "height": 55,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 39,
                                                        "height": 55,
                                                        "radius": 8,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16312092,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "8a92fc42-4288-4146-84aa-0bafd89a0a5a",
                                                    "type": "RECTANGLE",
                                                    "name": "YELLOWCARD2",
                                                    "x": 1336,
                                                    "y": 968,
                                                    "control": {
                                                        "id": "0",
                                                        "name": ""
                                                    },
                                                    "width": 39,
                                                    "height": 55,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 39,
                                                        "height": 55,
                                                        "radius": 8,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16312092,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                                    "visible": false
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "9f65bb4f-4422-4404-9d79-04d13167bc34",
                                                    "type": "RECTANGLE",
                                                    "name": "REDCARD",
                                                    "x": 1336,
                                                    "y": 888,
                                                    "control": {
                                                        "id": "7",
                                                        "name": "Red Card ON/OFF"
                                                    },
                                                    "width": 39,
                                                    "height": 55,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 39,
                                                        "height": 55,
                                                        "radius": 8,
                                                        "type": 4
                                                    },
                                                    "fillColor": 13632027,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": false
                                                }
                                            ],
                                            "uuid": "0f133c67-ba17-4030-86ee-31fd9e44b1da",
                                            "type": "GROUP",
                                            "name": "NameGRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 603.9553833007812,
                                            "height": 55,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "7b6c42a2-cdb0-4500-99b0-26715edfba47",
                                                    "type": "RECTANGLE",
                                                    "name": "WhiteBG",
                                                    "x": 656,
                                                    "y": 877,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 103,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 103,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.27,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "5d8112ee-d410-46ba-8680-c930380fa55a",
                                                    "type": "TEXT",
                                                    "name": "NUMBER",
                                                    "x": 700,
                                                    "y": "890",
                                                    "control": {
                                                        "id": "2",
                                                        "name": "Shirt Number",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Yellow Card",
                                                                    "field": "Shirt Number"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 60,
                                                    "height": 53,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "0.5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9836065573770492,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 52,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#000000",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 48,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "88",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "bc6a08dd-efc8-4640-a04b-36a670b34302",
                                                    "type": "RECTANGLE",
                                                    "name": "BlueWipe",
                                                    "x": 550,
                                                    "y": 877,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 103,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 103,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 10334,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.27,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                }
                                            ],
                                            "uuid": "43204316-ec4e-471d-af2f-7169c8540630",
                                            "type": "GROUP",
                                            "name": "NumberGRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 230.33851493510645,
                                            "height": 77.10167170927127,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": "37ae6058-ee1f-4cf2-992e-816ecef0d9ab",
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "37ae6058-ee1f-4cf2-992e-816ecef0d9ab",
                                            "type": "RECTANGLE",
                                            "name": "NumberMASK",
                                            "x": 656,
                                            "y": 877,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 105,
                                            "height": 80,
                                            "alpha": 1,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 105,
                                                "height": 80,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 8311585,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.27,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        }
                                    ],
                                    "uuid": "2a803f10-57c8-49cc-81a2-317d2db11aff",
                                    "type": "GROUP",
                                    "name": "L3_GRP",
                                    "x": 1453,
                                    "y": 0,
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 916,
                                    "height": 91,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": true
                                }
                            ],
                            "uuid": "fc5c538c-86c7-4c96-bcc4-beaf0bfd9b9c",
                            "type": "GROUP",
                            "name": "Root",
                            "x": 0,
                            "y": 0,
                            "control": {
                                "id": 0,
                                "name": ""
                            },
                            "width": 916,
                            "height": 91,
                            "alpha": 1,
                            "maxWidth": 0,
                            "anchorX": 0,
                            "anchorY": 0,
                            "skew": {
                                "x": 0,
                                "y": 0
                            },
                            "scale": {
                                "x": 1,
                                "y": 1
                            },
                            "mask": null,
                            "visible": true
                        }
                    ],
                    "uuid": "d5e9b400-21b0-4f14-8e64-33e541586d9e",
                    "type": "SCENE",
                    "name": "Root",
                    "x": 0,
                    "y": 0,
                    "control": {
                        "id": 0,
                        "name": ""
                    },
                    "alpha": 1,
                    "maxWidth": 0,
                    "skew": {
                        "x": 0,
                        "y": 0
                    },
                    "scale": {
                        "x": 1,
                        "y": 1
                    },
                    "mask": null,
                    "visible": false
                }
            }
        },
        {
            "_id": "61828977a4737fae26881114",
            "name": "L3_YellowRedCard",
            "project": "6182817751b7a0dd4843297a",
            "__v": 0,
            "data": {
                "name": "L3_YellowRedCard",
                "football": {
                    "templates": [
                        {
                            "name": "Double Yellow Card"
                        }
                    ]
                },
                "width": 1920,
                "height": 1080,
                "folder": "UNIVERSAL",
                "aspect": "16x9",
                "timelines": [
                    {
                        "name": "IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "2a803f10-57c8-49cc-81a2-317d2db11aff",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1445,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 23,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.269000244140625,
                                                    0.9235003662109376
                                                ],
                                                "type": "ease-out",
                                                "time": 460
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "37ae6058-ee1f-4cf2-992e-816ecef0d9ab",
                                "animations": [
                                    {
                                        "property": "width",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            },
                                            {
                                                "frame": 26,
                                                "value": 105,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 520
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "64bcd3c4-a3a5-4ad1-9001-423629561f9a",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 46,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 24,
                                                "value": 46,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 480
                                            },
                                            {
                                                "frame": 39,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 780
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "720e61c2-d2b4-4ebd-a35a-fb607cac149c",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 166,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 24,
                                                "value": 166,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 480
                                            },
                                            {
                                                "frame": 39,
                                                "value": 776,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.029000244140625,
                                                    0.9235003662109376
                                                ],
                                                "type": "ease-out",
                                                "time": 780
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "7b3ef1f0-d97d-4d7c-a41d-6a878cba3faa",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 24,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 480
                                            },
                                            {
                                                "frame": 39,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 780
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1336,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.189000244140625,
                                                    0.9235003662109376
                                                ],
                                                "type": "ease-out",
                                                "time": 20
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "8a92fc42-4288-4146-84aa-0bafd89a0a5a",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 968,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "c3d7204b-0b38-4163-9069-acec29c1736c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "bc6a08dd-efc8-4640-a04b-36a670b34302",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 550,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "9f65bb4f-4422-4404-9d79-04d13167bc34",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 24,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 480
                                            },
                                            {
                                                "frame": 39,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 780
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1336,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.189000244140625,
                                                    0.9235003662109376
                                                ],
                                                "type": "ease-out",
                                                "time": 20
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 39,
                        "loop": false,
                        "fps": 50,
                        "id": "9e0c02f9-b6ef-45f1-ba81-e2e0bbf884b3"
                    },
                    {
                        "name": "OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "64bcd3c4-a3a5-4ad1-9001-423629561f9a",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 17,
                                                "value": 46,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.699000244140625,
                                                    0.20350036621093748
                                                ],
                                                "type": "ease-out",
                                                "time": 340
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "2a803f10-57c8-49cc-81a2-317d2db11aff",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 300
                                            },
                                            {
                                                "frame": 34,
                                                "value": 1453,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.779000244140625,
                                                    0.053500366210937456
                                                ],
                                                "type": "ease-out",
                                                "time": 680
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 35,
                        "loop": false,
                        "fps": 50,
                        "id": "002f526e-63a9-4fd8-b9a1-d5cc34a025a7"
                    },
                    {
                        "name": "CARD-IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "7b3ef1f0-d97d-4d7c-a41d-6a878cba3faa",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 300
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 49,
                                                "value": 1336,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.189000244140625,
                                                    0.9235003662109376
                                                ],
                                                "type": "ease-out",
                                                "time": 980
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 15,
                        "loop": false,
                        "fps": 50,
                        "id": "41b9b96d-c00a-415a-b712-e19d7a459301"
                    },
                    {
                        "name": "CARD-OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "7b3ef1f0-d97d-4d7c-a41d-6a878cba3faa",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 980,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.749000244140625,
                                                    0.04350036621093745
                                                ],
                                                "type": "ease-out",
                                                "time": 300
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 15,
                        "loop": false,
                        "fps": 50,
                        "id": "4048d3db-8f33-4855-a079-f0f66885c2f7"
                    },
                    {
                        "name": "2CARD-IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "8a92fc42-4288-4146-84aa-0bafd89a0a5a",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 25,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.109000244140625,
                                                    0.9835003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 500
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1277,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 29,
                                                "value": 1277,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 580
                                            },
                                            {
                                                "frame": 35,
                                                "value": 1277,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 700
                                            },
                                            {
                                                "frame": 49,
                                                "value": 1336,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.189000244140625,
                                                    0.9235003662109376
                                                ],
                                                "type": "ease-out",
                                                "time": 980
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 62,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 1240
                                            },
                                            {
                                                "frame": 63,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 1260
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "7b3ef1f0-d97d-4d7c-a41d-6a878cba3faa",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 13,
                                                "value": 955,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 260
                                            },
                                            {
                                                "frame": 29,
                                                "value": 888,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.109000244140625,
                                                    0.9835003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 580
                                            }
                                        ]
                                    },
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 62,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 1240
                                            },
                                            {
                                                "frame": 63,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 1260
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 75,
                        "loop": false,
                        "fps": 50,
                        "id": "a3b14072-7d27-4e26-a12d-2fe98697b0f7"
                    },
                    {
                        "name": "2CARD-OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 12,
                        "loop": false,
                        "fps": 50,
                        "id": "1e603265-d1ee-4f04-9f61-98556a1ee0ce"
                    },
                    {
                        "name": "UPDATE-IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "bc6a08dd-efc8-4640-a04b-36a670b34302",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 656,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 6,
                                                "value": 764,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 120
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "720e61c2-d2b4-4ebd-a35a-fb607cac149c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 88,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 22,
                                                "value": 776,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.279000244140625,
                                                    0.8435003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 440
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "c3d7204b-0b38-4163-9069-acec29c1736c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 25,
                        "loop": false,
                        "fps": 50,
                        "id": "85cf68c1-4749-4fb0-a224-aef042c35450"
                    },
                    {
                        "name": "UPDATE-OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "bc6a08dd-efc8-4640-a04b-36a670b34302",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 550,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 9,
                                                "value": 550,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 180
                                            },
                                            {
                                                "frame": 14,
                                                "value": 656,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "720e61c2-d2b4-4ebd-a35a-fb607cac149c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "c3d7204b-0b38-4163-9069-acec29c1736c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 14,
                        "loop": false,
                        "fps": 50,
                        "id": "df0d05cc-df53-4776-abfb-ce9930fa38c3"
                    },
                    {
                        "name": "LOOP",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "c619cc80-c7c1-416b-8236-acc05ca399c5",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 515,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 150,
                                                "value": 533,
                                                "curve": [
                                                    0.42,
                                                    0,
                                                    0.58,
                                                    1
                                                ],
                                                "type": "ease-in-out",
                                                "time": 3000
                                            },
                                            {
                                                "frame": 300,
                                                "value": 515,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 6000
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "6fc539ea-4678-440a-b242-8df4992b2937",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1224,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 175,
                                                "value": 1207,
                                                "curve": [
                                                    0.42,
                                                    0,
                                                    0.58,
                                                    1
                                                ],
                                                "type": "ease-in-out",
                                                "time": 3500
                                            },
                                            {
                                                "frame": 300,
                                                "value": 1224,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 6000
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 300,
                        "loop": true,
                        "fps": 50,
                        "id": "4b1bb878-da22-47f0-b5b3-95f674f578b7"
                    }
                ],
                "scene": {
                    "children": [
                        {
                            "children": [
                                {
                                    "children": [],
                                    "uuid": "170bf835-10e7-4284-80d2-096637bf36a1",
                                    "type": "IMAGE",
                                    "name": "REF",
                                    "tint": 16777215,
                                    "x": "0",
                                    "y": "0",
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1920,
                                    "height": 1080,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchor": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "blendMode": 0,
                                    "mask": null,
                                    "visible": false,
                                    "src": "LowerThirdREF.jpg"
                                },
                                {
                                    "children": [],
                                    "uuid": "81981809-83ec-4e06-b646-5e80f0067b3b",
                                    "type": "RECTANGLE",
                                    "name": "Background",
                                    "x": "0",
                                    "y": "0",
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1920,
                                    "height": 1080,
                                    "alpha": 1,
                                    "graphicsData": {
                                        "x": 0,
                                        "y": 0,
                                        "width": 1920,
                                        "height": 1080,
                                        "radius": 0,
                                        "type": 4
                                    },
                                    "fillColor": 14355009,
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": false
                                },
                                {
                                    "children": [
                                        {
                                            "children": [],
                                            "uuid": "6fc539ea-4678-440a-b242-8df4992b2937",
                                            "type": "IMAGE",
                                            "name": "Stroke",
                                            "tint": 16777215,
                                            "x": 1224,
                                            "y": 884,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 207,
                                            "height": 78,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "blendMode": 0,
                                            "mask": null,
                                            "visible": true,
                                            "src": "L3Stroke3.png"
                                        },
                                        {
                                            "children": [],
                                            "uuid": "c619cc80-c7c1-416b-8236-acc05ca399c5",
                                            "type": "IMAGE",
                                            "name": "Grad",
                                            "tint": 16777215,
                                            "x": 515,
                                            "y": 891,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 874,
                                            "height": 77,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "blendMode": 0,
                                            "mask": null,
                                            "visible": true,
                                            "src": "L3_Grad_name.png"
                                        },
                                        {
                                            "children": [],
                                            "uuid": "6f9a2488-e5f8-4549-892d-20815a20e20d",
                                            "type": "RECTANGLE",
                                            "name": "HeaderMASK",
                                            "x": 770,
                                            "y": 837,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 646.9999999999999,
                                            "height": 42,
                                            "alpha": 1,
                                            "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}",
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 646.9999999999999,
                                                "height": 42,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 10334,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.27,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "91ff368e-2550-4dc5-8ddc-6ec7aadbff0a",
                                                    "type": "RECTANGLE",
                                                    "name": "DarkBlueBG",
                                                    "x": 770,
                                                    "y": 837,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 230,
                                                    "height": 42,
                                                    "alpha": 1,
                                                    "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}\n// Executed when graphic\n// updated each frame\nconst padding = 20; \nconst text_node_name = 'Header'\n\nfunction update(item, frame, scene) { \nlet text = hyperAPI.getItemByName(scene.name, text_node_name); \nitem.width = (text.x - item.x) + text.width + padding; }",
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 230,
                                                        "height": 42,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 10334,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.27,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "624b4d39-ebb5-4669-b6ca-e88bc9ca0bd4",
                                                    "type": "TEXT",
                                                    "name": "Header",
                                                    "x": 777,
                                                    "y": 842,
                                                    "control": {
                                                        "id": "5",
                                                        "name": "Header Text"
                                                    },
                                                    "width": 203,
                                                    "height": 29,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "0",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9950980392156863,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 75,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#fcfcfc",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Medium.otf",
                                                        "_fontSize": 26,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "YELLOW CARD",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                }
                                            ],
                                            "uuid": "64bcd3c4-a3a5-4ad1-9001-423629561f9a",
                                            "type": "GROUP",
                                            "name": "HeaderGRP",
                                            "x": 0,
                                            "y": 46,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 241.2027203409309,
                                            "height": 40.47837764736744,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": "6f9a2488-e5f8-4549-892d-20815a20e20d",
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "617531fc-62d4-4b9f-b2b0-299fdfcaabc8",
                                                    "type": "RECTANGLE",
                                                    "name": "BadgeMask",
                                                    "x": 554,
                                                    "y": 877,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 103,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 103,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.27,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "1f4db8da-ca3d-447d-b6bd-45cf9fe7b288",
                                                    "type": "RECTANGLE",
                                                    "name": "BadgeBG",
                                                    "x": 554,
                                                    "y": 877,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 103,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 103,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.27,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "95b56385-3579-4293-b3fb-2c8778156186",
                                                    "type": "IMAGE",
                                                    "name": "BADGE",
                                                    "tint": 16777215,
                                                    "x": 422,
                                                    "y": 856,
                                                    "control": {
                                                        "id": "1",
                                                        "name": "Team Badge",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Double Yellow Card",
                                                                    "field": "Team Badge"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 283,
                                                    "height": 283,
                                                    "alpha": 1,
                                                    "rotation": "12.2",
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.4223880597014925,
                                                        "y": 0.4223880597014925
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "617531fc-62d4-4b9f-b2b0-299fdfcaabc8",
                                                    "visible": true,
                                                    "src": "Arsenal.png"
                                                }
                                            ],
                                            "uuid": "b3386003-e1be-41cc-a227-a93aa6cf2358",
                                            "type": "GROUP",
                                            "name": "BadgeGRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 124.33851493510645,
                                            "height": 77.10167170927127,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "b7e9b022-7d19-472b-86b7-b9eff513c856",
                                            "type": "RECTANGLE",
                                            "name": "BlueBG",
                                            "x": 657,
                                            "y": 877,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 751,
                                            "height": 80,
                                            "alpha": 1,
                                            "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}",
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 751,
                                                "height": 80,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 7122399,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.27,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "720e61c2-d2b4-4ebd-a35a-fb607cac149c",
                                                    "type": "TEXT",
                                                    "name": "FirstName",
                                                    "x": 776,
                                                    "y": 896,
                                                    "control": {
                                                        "id": "3",
                                                        "name": "First Name",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Double Yellow Card",
                                                                    "field": "First Name Uppercase"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 111,
                                                    "height": 42,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "0",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9910714285714286,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 73,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#fcfcfc",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Medium.otf",
                                                        "_fontSize": 40,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "KEVIN",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "c3d7204b-0b38-4163-9069-acec29c1736c",
                                                    "type": "TEXT",
                                                    "name": "LastName",
                                                    "x": 899,
                                                    "y": 896,
                                                    "control": {
                                                        "id": "4",
                                                        "name": "Last Name",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Double Yellow Card",
                                                                    "field": "Last Name Uppercase"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 218,
                                                    "height": 42,
                                                    "alpha": 1,
                                                    "autoFollow": "720e61c2-d2b4-4ebd-a35a-fb607cac149c",
                                                    "autoFollowPadding": 12,
                                                    "maxWidth": "418",
                                                    "anchor": {
                                                        "x": "0",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9954337899543378,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 75,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#fcfcfc",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Bold.otf",
                                                        "_fontSize": 40,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "DE BRUYNE ",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                                    "type": "RECTANGLE",
                                                    "name": "NameMASK",
                                                    "x": 657,
                                                    "y": 877,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 751,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}",
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 751,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 7122399,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.27,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "7b3ef1f0-d97d-4d7c-a41d-6a878cba3faa",
                                                    "type": "RECTANGLE",
                                                    "name": "YELLOWCARD1",
                                                    "x": 1336,
                                                    "y": 888,
                                                    "control": {
                                                        "id": "6",
                                                        "name": "Yellow Card On/Off"
                                                    },
                                                    "width": 39,
                                                    "height": 55,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 39,
                                                        "height": 55,
                                                        "radius": 8,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16312092,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "8a92fc42-4288-4146-84aa-0bafd89a0a5a",
                                                    "type": "RECTANGLE",
                                                    "name": "YELLOWCARD2",
                                                    "x": 1336,
                                                    "y": 968,
                                                    "control": {
                                                        "id": "0",
                                                        "name": ""
                                                    },
                                                    "width": 39,
                                                    "height": 55,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 39,
                                                        "height": 55,
                                                        "radius": 8,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16312092,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": "14a03d82-e901-4d6b-a7e3-2d6c38d19f41",
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "9f65bb4f-4422-4404-9d79-04d13167bc34",
                                                    "type": "RECTANGLE",
                                                    "name": "REDCARD",
                                                    "x": 1336,
                                                    "y": 888,
                                                    "control": {
                                                        "id": "7",
                                                        "name": "Red Card ON/OFF"
                                                    },
                                                    "width": 39,
                                                    "height": 55,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 39,
                                                        "height": 55,
                                                        "radius": 8,
                                                        "type": 4
                                                    },
                                                    "fillColor": 13632027,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": false
                                                }
                                            ],
                                            "uuid": "0f133c67-ba17-4030-86ee-31fd9e44b1da",
                                            "type": "GROUP",
                                            "name": "NameGRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 603.9553833007812,
                                            "height": 55,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "7b6c42a2-cdb0-4500-99b0-26715edfba47",
                                                    "type": "RECTANGLE",
                                                    "name": "WhiteBG",
                                                    "x": 656,
                                                    "y": 877,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 103,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 103,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.27,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "5d8112ee-d410-46ba-8680-c930380fa55a",
                                                    "type": "TEXT",
                                                    "name": "NUMBER",
                                                    "x": 700,
                                                    "y": "890",
                                                    "control": {
                                                        "id": "2",
                                                        "name": "Shirt Number",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Double Yellow Card",
                                                                    "field": "Shirt Number"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 60,
                                                    "height": 53,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": "0.5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9836065573770492,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 52,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#000000",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 48,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "88",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "bc6a08dd-efc8-4640-a04b-36a670b34302",
                                                    "type": "RECTANGLE",
                                                    "name": "BlueWipe",
                                                    "x": 550,
                                                    "y": 877,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 103,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 103,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 10334,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.27,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                }
                                            ],
                                            "uuid": "43204316-ec4e-471d-af2f-7169c8540630",
                                            "type": "GROUP",
                                            "name": "NumberGRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 230.33851493510645,
                                            "height": 77.10167170927127,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": "37ae6058-ee1f-4cf2-992e-816ecef0d9ab",
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "37ae6058-ee1f-4cf2-992e-816ecef0d9ab",
                                            "type": "RECTANGLE",
                                            "name": "NumberMASK",
                                            "x": 656,
                                            "y": 877,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 105,
                                            "height": 80,
                                            "alpha": 1,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 105,
                                                "height": 80,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 8311585,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.27,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        }
                                    ],
                                    "uuid": "2a803f10-57c8-49cc-81a2-317d2db11aff",
                                    "type": "GROUP",
                                    "name": "L3_GRP",
                                    "x": 1453,
                                    "y": 0,
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 916,
                                    "height": 91,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": true
                                }
                            ],
                            "uuid": "fc5c538c-86c7-4c96-bcc4-beaf0bfd9b9c",
                            "type": "GROUP",
                            "name": "Root",
                            "x": 0,
                            "y": 0,
                            "control": {
                                "id": 0,
                                "name": ""
                            },
                            "width": 916,
                            "height": 91,
                            "alpha": 1,
                            "maxWidth": 0,
                            "anchorX": 0,
                            "anchorY": 0,
                            "skew": {
                                "x": 0,
                                "y": 0
                            },
                            "scale": {
                                "x": 1,
                                "y": 1
                            },
                            "mask": null,
                            "visible": true
                        }
                    ],
                    "uuid": "d5e9b400-21b0-4f14-8e64-33e541586d9e",
                    "type": "SCENE",
                    "name": "Root",
                    "x": 0,
                    "y": 0,
                    "control": {
                        "id": 0,
                        "name": ""
                    },
                    "alpha": 1,
                    "maxWidth": 0,
                    "skew": {
                        "x": 0,
                        "y": 0
                    },
                    "scale": {
                        "x": 1,
                        "y": 1
                    },
                    "mask": null,
                    "visible": false
                }
            }
        },
        {
            "_id": "61828977a4737fae26881128",
            "name": "LIVE_L3rd_Call2Aaction",
            "project": "6182817751b7a0dd4843297a",
            "__v": 0,
            "data": {
                "name": "LIVE_L3rd_Call2Aaction",
                "width": 1920,
                "height": 1080,
                "folder": "UNIVERSAL",
                "aspect": "16x9",
                "timelines": [
                    {
                        "name": "IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "b463054f-402f-4cbc-b8c7-4f07568a5031",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -896,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 21,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.22703125,
                                                    0.9971875
                                                ],
                                                "type": "ease-out",
                                                "time": 420
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "0b06b41b-a7ae-4919-989b-07a6c096a187",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 2546,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 21,
                                                "value": 1815,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0,
                                                    0.8971875
                                                ],
                                                "type": "ease-out",
                                                "time": 420
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "26bd4ea5-3ce9-4e57-a48c-8e1d2309a7a5",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 183,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 21,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.04703125,
                                                    0.8871875
                                                ],
                                                "type": "ease-out",
                                                "time": 420
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "a349c8e9-8710-45f2-a966-cfae2ca7d175",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "68ae100a-2497-46a4-9d0a-ccc52d460c49",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 178,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 20,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.109000244140625,
                                                    0.9535003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 400
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": -1,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 25,
                        "loop": false,
                        "fps": 50,
                        "id": "345413ef-4312-4893-bfdd-44b459fe6e52",
                        "prevFrame": 25
                    },
                    {
                        "name": "OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "a349c8e9-8710-45f2-a966-cfae2ca7d175",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 16,
                                                "value": 170,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.82703125,
                                                    0.017187500000000022
                                                ],
                                                "type": "ease-out",
                                                "time": 320
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "68ae100a-2497-46a4-9d0a-ccc52d460c49",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 5,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 100
                                            },
                                            {
                                                "frame": 16,
                                                "value": 181,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.669000244140625,
                                                    0.02350036621093754
                                                ],
                                                "type": "ease-out",
                                                "time": 320
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": -1,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 25,
                        "loop": false,
                        "fps": 50,
                        "id": "0626fea5-1b23-444a-b5aa-5c9a177006cb",
                        "prevFrame": 25
                    },
                    {
                        "name": "LOOP",
                        "playingState": 1,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "4d25eeed-3142-4e29-87e5-fa9a7a02ab55",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1746,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 403,
                                                "value": 1427,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 8060
                                            },
                                            {
                                                "frame": 800,
                                                "value": 1746,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.48703125,
                                                    0.15718750000000004
                                                ],
                                                "type": "ease-out",
                                                "time": 16000
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "a054c95c-3dbe-4202-bfe8-42d3527042cc",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 15,
                                                "value": 572,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 300
                                            },
                                            {
                                                "frame": 386,
                                                "value": 85,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 7720
                                            },
                                            {
                                                "frame": 755,
                                                "value": 572,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 15100
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "8cb3c096-d446-4d97-a35e-fbd165e9b15e",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 23,
                                                "value": 160,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 460
                                            },
                                            {
                                                "frame": 416,
                                                "value": 660,
                                                "curve": [
                                                    0.42,
                                                    0,
                                                    0.58,
                                                    1
                                                ],
                                                "type": "ease-in-out",
                                                "time": 8320
                                            },
                                            {
                                                "frame": 794,
                                                "value": 160,
                                                "curve": [
                                                    0.42,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "ease-in",
                                                "time": 15880
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "8c725eac-def0-4752-b1a7-5de287f62415",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 770,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 443,
                                                "value": 1209,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 8860
                                            },
                                            {
                                                "frame": 800,
                                                "value": 770,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 16000
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "52581d4b-cfaa-4d28-9fae-511cc033f3f5",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 8,
                                                "value": 1109,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 160
                                            },
                                            {
                                                "frame": 375,
                                                "value": 1590,
                                                "curve": [
                                                    0.29703125,
                                                    0.27718750000000003,
                                                    0.67703125,
                                                    0.8671875
                                                ],
                                                "type": "ease-in-out",
                                                "time": 7500
                                            },
                                            {
                                                "frame": 778,
                                                "value": 1109,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.58,
                                                    1
                                                ],
                                                "type": "ease-out",
                                                "time": 15560
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 4596.699999988079,
                        "frameNumber": 229,
                        "delta": 13.100000023841858,
                        "duration": 800,
                        "loop": true,
                        "fps": 50,
                        "id": "7afefdbf-1480-4547-b919-5db5a484d371",
                        "prevFrame": 229
                    },
                    {
                        "name": "UPDATE-IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "a349c8e9-8710-45f2-a966-cfae2ca7d175",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "b463054f-402f-4cbc-b8c7-4f07568a5031",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -896,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 21,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.22703125,
                                                    0.9971875
                                                ],
                                                "type": "ease-out",
                                                "time": 420
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "0b06b41b-a7ae-4919-989b-07a6c096a187",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 2546,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 21,
                                                "value": 1815,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0,
                                                    0.8971875
                                                ],
                                                "type": "ease-out",
                                                "time": 420
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "26bd4ea5-3ce9-4e57-a48c-8e1d2309a7a5",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 183,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 21,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.04703125,
                                                    0.8871875
                                                ],
                                                "type": "ease-out",
                                                "time": 420
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": -1,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 21,
                        "loop": false,
                        "fps": 50,
                        "id": "4347d437-0498-45e9-a1df-8a7c9a4d4123",
                        "prevFrame": 21
                    },
                    {
                        "name": "UPDATE-OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "a349c8e9-8710-45f2-a966-cfae2ca7d175",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 16,
                                                "value": 170,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.82703125,
                                                    0.017187500000000022
                                                ],
                                                "type": "ease-out",
                                                "time": 320
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": -1,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 16,
                        "loop": false,
                        "fps": 50,
                        "id": "ce77f0ed-3cce-4369-a62e-bb47d87bd316",
                        "prevFrame": 16
                    }
                ],
                "scene": {
                    "children": [
                        {
                            "children": [
                                {
                                    "children": [],
                                    "uuid": "55d254b2-e76c-4a8d-93de-25e0bc1f92e2",
                                    "type": "IMAGE",
                                    "name": "REF1",
                                    "tint": 16777215,
                                    "x": "0",
                                    "y": "0",
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1928,
                                    "height": 1080,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchor": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "blendMode": 0,
                                    "mask": null,
                                    "visible": false,
                                    "src": "Call2ActionRef.png"
                                },
                                {
                                    "children": [
                                        {
                                            "children": [],
                                            "uuid": "524d16bc-5a45-4839-9d5a-22db356d2f97",
                                            "type": "IMAGE",
                                            "name": "BaseGrad",
                                            "tint": 16777215,
                                            "x": "0",
                                            "y": 912,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 1928,
                                            "height": 173,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "blendMode": 0,
                                            "mask": null,
                                            "visible": true,
                                            "src": "LIVE_L3rdGrad.png"
                                        },
                                        {
                                            "children": [],
                                            "uuid": "4d25eeed-3142-4e29-87e5-fa9a7a02ab55",
                                            "type": "RECTANGLE",
                                            "name": "BigBlue1",
                                            "x": 1564.4802493779812,
                                            "y": 924,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 447,
                                            "height": 268,
                                            "alpha": "0.6",
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 447,
                                                "height": 268,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 16777215,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.26,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "a054c95c-3dbe-4202-bfe8-42d3527042cc",
                                            "type": "RECTANGLE",
                                            "name": "BigBlue2",
                                            "x": 290.09281081316436,
                                            "y": 728,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 447,
                                            "height": 268,
                                            "alpha": "0.6",
                                            "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}",
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 447,
                                                "height": 268,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 16777215,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.26,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": "ff0fc31c-d6d4-41c8-bef6-34a8bcc2abd5",
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "8cb3c096-d446-4d97-a35e-fbd165e9b15e",
                                            "type": "IMAGE",
                                            "name": "SmallStroke1",
                                            "tint": 16777215,
                                            "x": 432.3489453191307,
                                            "y": 947,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 387.97297297297297,
                                            "height": 330,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 0.8918918918918919,
                                                "y": 0.8918918918918919
                                            },
                                            "blendMode": 0,
                                            "mask": "ff0fc31c-d6d4-41c8-bef6-34a8bcc2abd5",
                                            "visible": true,
                                            "src": "SmallStrokeFF.png"
                                        },
                                        {
                                            "children": [],
                                            "uuid": "8c725eac-def0-4752-b1a7-5de287f62415",
                                            "type": "IMAGE",
                                            "name": "SmallStroke2",
                                            "tint": 16777215,
                                            "x": 997.1451700662471,
                                            "y": 686,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 387.97297297297297,
                                            "height": 330,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 0.8918918918918919,
                                                "y": 0.8918918918918919
                                            },
                                            "blendMode": 0,
                                            "mask": "ff0fc31c-d6d4-41c8-bef6-34a8bcc2abd5",
                                            "visible": true,
                                            "src": "SmallStrokeFF.png"
                                        },
                                        {
                                            "children": [],
                                            "uuid": "52581d4b-cfaa-4d28-9fae-511cc033f3f5",
                                            "type": "IMAGE",
                                            "name": "SmallerStroke1",
                                            "tint": 16777215,
                                            "x": 1436.8363990799094,
                                            "y": 962,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 254.76923076923055,
                                            "height": 96,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1.2307692307692297,
                                                "y": 1.2307692307692308
                                            },
                                            "blendMode": 0,
                                            "mask": null,
                                            "visible": true,
                                            "src": "L3Stroke3.png"
                                        },
                                        {
                                            "children": [],
                                            "uuid": "ff0fc31c-d6d4-41c8-bef6-34a8bcc2abd5",
                                            "type": "RECTANGLE",
                                            "name": "BaseMASK",
                                            "x": "0",
                                            "y": 912,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 1920,
                                            "height": 170,
                                            "alpha": 1,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 1920,
                                                "height": 170,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 14355009,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        }
                                    ],
                                    "uuid": "68ae100a-2497-46a4-9d0a-ccc52d460c49",
                                    "type": "GROUP",
                                    "name": "BaseGRP",
                                    "x": 0,
                                    "y": 181,
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 2011.4802493779812,
                                    "height": 270.9925141400495,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": true
                                },
                                {
                                    "children": [
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "da3974ac-a134-4011-8d9b-59856dd52082",
                                                    "type": "RECTANGLE",
                                                    "name": "Background",
                                                    "x": 49,
                                                    "y": 959,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 679,
                                                    "height": 76,
                                                    "alpha": 1,
                                                    "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}\n// Executed when graphic\n// updated each frame\nconst padding = 20; \nconst text_node_name = 'QuestonTEXT'\n\nfunction update(item, frame, scene) { \nlet text = hyperAPI.getItemByName(scene.name, text_node_name); \nitem.width = (text.x - item.x) + text.width + padding; }\n",
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 679,
                                                        "height": 76,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 10334,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.23,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "25dc4040-b02e-49f9-a4b1-022e5ace25c5",
                                                    "type": "TEXT",
                                                    "name": "QuestonTEXT",
                                                    "x": 58,
                                                    "y": 957,
                                                    "control": {
                                                        "id": "5",
                                                        "name": "LeftTEXT"
                                                    },
                                                    "width": 650,
                                                    "height": 73,
                                                    "alpha": 1,
                                                    "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}",
                                                    "maxWidth": "687",
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9984639016897081,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 56,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "white",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 67,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "GOT A QUESTION?",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                }
                                            ],
                                            "uuid": "b463054f-402f-4cbc-b8c7-4f07568a5031",
                                            "type": "GROUP",
                                            "name": "QuestionGRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 696.3262917886743,
                                            "height": 83,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "3608ac56-4870-41d3-9c91-559e6bf67aff",
                                                    "type": "RECTANGLE",
                                                    "name": "Background",
                                                    "x": 83,
                                                    "y": 959,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 592,
                                                    "height": 76,
                                                    "alpha": 1,
                                                    "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}\n// Executed when graphic\n// updated each frame\nconst padding = 30; \nconst text_node_name = 'PhoneTEXT'\n\nfunction update(item, frame, scene) { \nlet text = hyperAPI.getItemByName(scene.name, text_node_name); \nitem.width = (text.x - item.x) + text.width + padding; }\n",
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 592,
                                                        "height": 76,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 1,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.23,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "042d04fb-841f-4b2d-90eb-d3f600e06e60",
                                                    "type": "TEXT",
                                                    "name": "PhoneTEXT",
                                                    "x": 67,
                                                    "y": 957,
                                                    "control": {
                                                        "id": "10",
                                                        "name": "RightTEXT"
                                                    },
                                                    "width": 578,
                                                    "height": 73,
                                                    "alpha": 1,
                                                    "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}",
                                                    "maxWidth": "635",
                                                    "anchor": {
                                                        "x": "1",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.998272884283247,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 57,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#00285e",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 67,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": " +447537186538 ",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                }
                                            ],
                                            "uuid": "0b06b41b-a7ae-4919-989b-07a6c096a187",
                                            "type": "GROUP",
                                            "name": "NumberGRP",
                                            "x": 1815,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 609.3262917886743,
                                            "height": 83,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "82ad8aed-5473-41ba-bb0c-728e02da5462",
                                                    "type": "IMAGE",
                                                    "name": "SocialLOGO",
                                                    "tint": 16777215,
                                                    "x": 761,
                                                    "y": 933,
                                                    "control": {
                                                        "id": "15",
                                                        "name": "CentreLOGO"
                                                    },
                                                    "width": 470,
                                                    "height": 126.9,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.7833333333333333,
                                                        "y": 0.7833333333333333
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "src": "WhatsApp-Emblem2.png"
                                                }
                                            ],
                                            "uuid": "26bd4ea5-3ce9-4e57-a48c-8e1d2309a7a5",
                                            "type": "GROUP",
                                            "name": "SocialLogoGRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 470,
                                            "height": 126.9000244140625,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        }
                                    ],
                                    "uuid": "a349c8e9-8710-45f2-a966-cfae2ca7d175",
                                    "type": "GROUP",
                                    "name": "ActionGRP",
                                    "x": 0,
                                    "y": 170,
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1866.3262917886743,
                                    "height": 126.9000244140625,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": true
                                }
                            ],
                            "uuid": "47732648-5889-4b21-a446-6b0cdc889072",
                            "type": "GROUP",
                            "name": "Root",
                            "x": 0,
                            "y": 0,
                            "control": {
                                "id": 0,
                                "name": ""
                            },
                            "width": 2011.4802493779812,
                            "height": 270.9925141400495,
                            "alpha": 1,
                            "maxWidth": 0,
                            "anchorX": 0,
                            "anchorY": 0,
                            "skew": {
                                "x": 0,
                                "y": 0
                            },
                            "scale": {
                                "x": 1,
                                "y": 1
                            },
                            "mask": null,
                            "visible": true
                        }
                    ],
                    "uuid": "075f978e-716c-4026-bf05-3477d880ce64",
                    "type": "SCENE",
                    "name": "Root",
                    "x": 0,
                    "y": 0,
                    "control": {
                        "id": 0,
                        "name": ""
                    },
                    "alpha": 1,
                    "maxWidth": 0,
                    "skew": {
                        "x": 0,
                        "y": 0
                    },
                    "scale": {
                        "x": 1,
                        "y": 1
                    },
                    "mask": null,
                    "visible": true
                }
            }
        },
        {
            "_id": "61828978a4737fae26881143",
            "name": "LIVE_L3rd_MASTER",
            "project": "6182817751b7a0dd4843297a",
            "__v": 0,
            "data": {
                "name": "LIVE_L3rd_MASTER",
                "neverno": {
                    "server": "https://beeon-mancity.never.no/xml/feed/beeon.json"
                },
                "width": 1920,
                "height": 1080,
                "folder": "UNIVERSAL",
                "aspect": "16x9",
                "timelines": [
                    {
                        "name": "IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": [
                                    {
                                        "property": "TIMELINE",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "name": "LOOP",
                                                "action": "PLAY"
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "07367462-ac69-428c-85eb-86d4ee10e7c7",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1910,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 25,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.25703125,
                                                    0.9971875
                                                ],
                                                "type": "ease-out",
                                                "time": 500
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "6d94cbb5-4c70-448c-96e9-a3c39849b1d7",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 58,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 25,
                                                "value": 58,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 500
                                            },
                                            {
                                                "frame": 39,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.03703125,
                                                    0.9171875
                                                ],
                                                "type": "ease-out",
                                                "time": 780
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "b139fab1-ab60-41c4-9ba6-4d4cfc01bfba",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1278,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 21,
                                                "value": -1278,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 420
                                            },
                                            {
                                                "frame": 49,
                                                "value": 279,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.24703125,
                                                    0.7071875000000001
                                                ],
                                                "type": "ease-out",
                                                "time": 980
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "68ae100a-2497-46a4-9d0a-ccc52d460c49",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 184,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 16,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.039000244140625,
                                                    0.8635003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 320
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "2a9adc88-cbaf-4d4c-ab14-b1b747581222",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": -1,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 50,
                        "loop": false,
                        "fps": 50,
                        "id": "538b6a16-0fa1-4e38-a8e5-6d074fa31cf8",
                        "prevFrame": 50
                    },
                    {
                        "name": "OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "6d94cbb5-4c70-448c-96e9-a3c39849b1d7",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 59,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    0.08718749999999997
                                                ],
                                                "type": "ease-out",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "07367462-ac69-428c-85eb-86d4ee10e7c7",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 10,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 200
                                            },
                                            {
                                                "frame": 33,
                                                "value": 1907,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.94703125,
                                                    0.017187500000000022
                                                ],
                                                "type": "ease-out",
                                                "time": 660
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "68ae100a-2497-46a4-9d0a-ccc52d460c49",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 17,
                                                "value": 173,
                                                "curve": [
                                                    0.789000244140625,
                                                    0.04350036621093745,
                                                    1,
                                                    1
                                                ],
                                                "type": "ease-in",
                                                "time": 340
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 34,
                        "loop": false,
                        "fps": 50,
                        "id": "0fecf7eb-d72b-49de-b619-a22466cc489d"
                    },
                    {
                        "name": "LOOP",
                        "playingState": 1,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "4d25eeed-3142-4e29-87e5-fa9a7a02ab55",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1746,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 403,
                                                "value": 1427,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 8060
                                            },
                                            {
                                                "frame": 800,
                                                "value": 1746,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.48703125,
                                                    0.15718750000000004
                                                ],
                                                "type": "ease-out",
                                                "time": 16000
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "a054c95c-3dbe-4202-bfe8-42d3527042cc",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 15,
                                                "value": 572,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 300
                                            },
                                            {
                                                "frame": 386,
                                                "value": 85,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 7720
                                            },
                                            {
                                                "frame": 755,
                                                "value": 572,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 15100
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "8cb3c096-d446-4d97-a35e-fbd165e9b15e",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 23,
                                                "value": 160,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 460
                                            },
                                            {
                                                "frame": 416,
                                                "value": 660,
                                                "curve": [
                                                    0.42,
                                                    0,
                                                    0.58,
                                                    1
                                                ],
                                                "type": "ease-in-out",
                                                "time": 8320
                                            },
                                            {
                                                "frame": 794,
                                                "value": 160,
                                                "curve": [
                                                    0.42,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "ease-in",
                                                "time": 15880
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "8c725eac-def0-4752-b1a7-5de287f62415",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 770,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 443,
                                                "value": 1209,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 8860
                                            },
                                            {
                                                "frame": 800,
                                                "value": 770,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 16000
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "52581d4b-cfaa-4d28-9fae-511cc033f3f5",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 8,
                                                "value": 1109,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 160
                                            },
                                            {
                                                "frame": 375,
                                                "value": 1590,
                                                "curve": [
                                                    0.29703125,
                                                    0.27718750000000003,
                                                    0.67703125,
                                                    0.8671875
                                                ],
                                                "type": "ease-in-out",
                                                "time": 7500
                                            },
                                            {
                                                "frame": 778,
                                                "value": 1109,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.58,
                                                    1
                                                ],
                                                "type": "ease-out",
                                                "time": 15560
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 14541.300000011921,
                        "frameNumber": 727,
                        "delta": 0,
                        "duration": 800,
                        "loop": true,
                        "fps": 50,
                        "id": "b239ddc8-82ff-4c07-9c8c-236fa0fa65a8",
                        "prevFrame": 727
                    },
                    {
                        "name": "UPDATE-OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "2a9adc88-cbaf-4d4c-ab14-b1b747581222",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 300
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "aac0ef78-a14c-49a4-b3a2-fb973e6b0b3d",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 210,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.819000244140625,
                                                    0.02350036621093754
                                                ],
                                                "type": "ease-out",
                                                "time": 300
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "1f10480d-37c6-4b7d-bd3d-4d4175416277",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 38,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 230,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.839000244140625,
                                                    0.09350036621093749
                                                ],
                                                "type": "ease-out",
                                                "time": 300
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "6d94cbb5-4c70-448c-96e9-a3c39849b1d7",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 53,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.919000244140625,
                                                    0.3835003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 300
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "bd62125a-041e-4f7f-94d7-144a6a21a423",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 300
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 15,
                        "loop": false,
                        "fps": 50,
                        "id": "3332be6e-a5b5-4708-b888-47407d38cf9d"
                    },
                    {
                        "name": "UPDATE-IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "2a9adc88-cbaf-4d4c-ab14-b1b747581222",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "b139fab1-ab60-41c4-9ba6-4d4cfc01bfba",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1278,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 1,
                                                "value": -1278,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 38,
                                                "value": 279,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.24703125,
                                                    0.7071875000000001
                                                ],
                                                "type": "ease-out",
                                                "time": 760
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "aac0ef78-a14c-49a4-b3a2-fb973e6b0b3d",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 210,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 21,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.309000244140625,
                                                    0.9335003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 420
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "1f10480d-37c6-4b7d-bd3d-4d4175416277",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 230,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 21,
                                                "value": 32,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.429000244140625,
                                                    0.7735003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 420
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "6d94cbb5-4c70-448c-96e9-a3c39849b1d7",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 53,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 21,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.379000244140625,
                                                    0.9435003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 420
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "fb090655-bdcc-4b22-bba0-79793b9b5799",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 21,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 420
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "bd62125a-041e-4f7f-94d7-144a6a21a423",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 300
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 40,
                        "loop": false,
                        "fps": 50,
                        "id": "83df3c9b-98aa-4a5a-9ae8-dca3e6e97124"
                    }
                ],
                "scene": {
                    "children": [
                        {
                            "children": [
                                {
                                    "children": [],
                                    "uuid": "55d254b2-e76c-4a8d-93de-25e0bc1f92e2",
                                    "type": "IMAGE",
                                    "name": "REF1",
                                    "tint": 16777215,
                                    "x": "0",
                                    "y": "0",
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1928,
                                    "height": 1080,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchor": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "blendMode": 0,
                                    "mask": null,
                                    "visible": false,
                                    "src": "TeamNewsL3rd.png"
                                },
                                {
                                    "children": [
                                        {
                                            "children": [],
                                            "uuid": "524d16bc-5a45-4839-9d5a-22db356d2f97",
                                            "type": "IMAGE",
                                            "name": "BaseGrad",
                                            "tint": 16777215,
                                            "x": "0",
                                            "y": 912,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 1928,
                                            "height": 173,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "blendMode": 0,
                                            "mask": null,
                                            "visible": true,
                                            "src": "LIVE_L3rdGrad.png"
                                        },
                                        {
                                            "children": [],
                                            "uuid": "ff0fc31c-d6d4-41c8-bef6-34a8bcc2abd5",
                                            "type": "RECTANGLE",
                                            "name": "BaseMASK",
                                            "x": "0",
                                            "y": 912,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 1920,
                                            "height": 170,
                                            "alpha": 1,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 1920,
                                                "height": 170,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 14355009,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "4d25eeed-3142-4e29-87e5-fa9a7a02ab55",
                                            "type": "RECTANGLE",
                                            "name": "BigBlue1",
                                            "x": 1658.8489681603771,
                                            "y": 924,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 447,
                                            "height": 268,
                                            "alpha": "0.6",
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 447,
                                                "height": 268,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 16777215,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.26,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "a054c95c-3dbe-4202-bfe8-42d3527042cc",
                                            "type": "RECTANGLE",
                                            "name": "BigBlue2",
                                            "x": 536.3550407616583,
                                            "y": 793,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 447,
                                            "height": 268,
                                            "alpha": "0.6",
                                            "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}",
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 447,
                                                "height": 268,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 16777215,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.26,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": "ff0fc31c-d6d4-41c8-bef6-34a8bcc2abd5",
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "8cb3c096-d446-4d97-a35e-fbd165e9b15e",
                                            "type": "IMAGE",
                                            "name": "SmallStroke1",
                                            "tint": 16777215,
                                            "x": 296.1534669858021,
                                            "y": 947,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 387.97297297297297,
                                            "height": 330,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 0.8918918918918919,
                                                "y": 0.8918918918918919
                                            },
                                            "blendMode": 0,
                                            "mask": "ff0fc31c-d6d4-41c8-bef6-34a8bcc2abd5",
                                            "visible": true,
                                            "src": "SmallStrokeFF.png"
                                        },
                                        {
                                            "children": [],
                                            "uuid": "8c725eac-def0-4752-b1a7-5de287f62415",
                                            "type": "IMAGE",
                                            "name": "SmallStroke2",
                                            "tint": 16777215,
                                            "x": 858.7063623588156,
                                            "y": 686,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 387.97297297297297,
                                            "height": 330,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 0.8918918918918919,
                                                "y": 0.8918918918918919
                                            },
                                            "blendMode": 0,
                                            "mask": "ff0fc31c-d6d4-41c8-bef6-34a8bcc2abd5",
                                            "visible": true,
                                            "src": "SmallStrokeFF.png"
                                        },
                                        {
                                            "children": [],
                                            "uuid": "52581d4b-cfaa-4d28-9fae-511cc033f3f5",
                                            "type": "IMAGE",
                                            "name": "SmallerStroke1",
                                            "tint": 16777215,
                                            "x": 1121.3513384482833,
                                            "y": 962,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 254.76923076923055,
                                            "height": 96,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1.2307692307692297,
                                                "y": 1.2307692307692308
                                            },
                                            "blendMode": 0,
                                            "mask": null,
                                            "visible": true,
                                            "src": "L3Stroke3.png"
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [
                                                        {
                                                            "children": [],
                                                            "uuid": "da3974ac-a134-4011-8d9b-59856dd52082",
                                                            "type": "RECTANGLE",
                                                            "name": "Background",
                                                            "x": 49,
                                                            "y": 959,
                                                            "control": {
                                                                "id": 0,
                                                                "name": ""
                                                            },
                                                            "width": 679,
                                                            "height": 76,
                                                            "alpha": 1,
                                                            "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}\n// Executed when graphic\n// updated each frame\nconst padding = 20; \nconst text_node_name = 'QuestonTEXT'\n\nfunction update(item, frame, scene) { \nlet text = hyperAPI.getItemByName(scene.name, text_node_name); \nitem.width = (text.x - item.x) + text.width + padding; }\n",
                                                            "graphicsData": {
                                                                "x": 0,
                                                                "y": 0,
                                                                "width": 679,
                                                                "height": 76,
                                                                "radius": 0,
                                                                "type": 4
                                                            },
                                                            "fillColor": 10334,
                                                            "maxWidth": 0,
                                                            "anchorX": 0,
                                                            "anchorY": 0,
                                                            "skew": {
                                                                "x": -0.23,
                                                                "y": 0
                                                            },
                                                            "scale": {
                                                                "x": 1,
                                                                "y": 1
                                                            },
                                                            "mask": null,
                                                            "visible": true
                                                        },
                                                        {
                                                            "children": [],
                                                            "uuid": "25dc4040-b02e-49f9-a4b1-022e5ace25c5",
                                                            "type": "TEXT",
                                                            "name": "QuestonTEXT",
                                                            "x": 58,
                                                            "y": 957,
                                                            "control": {
                                                                "id": 0,
                                                                "name": ""
                                                            },
                                                            "width": 650,
                                                            "height": 73,
                                                            "alpha": 1,
                                                            "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}",
                                                            "maxWidth": 0,
                                                            "anchor": {
                                                                "x": 0,
                                                                "y": 0
                                                            },
                                                            "skew": {
                                                                "x": 0,
                                                                "y": 0
                                                            },
                                                            "scale": {
                                                                "x": 0.9984639016897081,
                                                                "y": 1
                                                            },
                                                            "blendMode": 0,
                                                            "mask": null,
                                                            "visible": true,
                                                            "style": {
                                                                "styleID": 56,
                                                                "_align": "left",
                                                                "_breakWords": false,
                                                                "_dropShadow": false,
                                                                "_dropShadowAlpha": 1,
                                                                "_dropShadowAngle": 0.5235987755982988,
                                                                "_dropShadowBlur": 0,
                                                                "_dropShadowColor": "black",
                                                                "_dropShadowDistance": 5,
                                                                "_fill": "white",
                                                                "_fillGradientType": 0,
                                                                "_fillGradientStops": [],
                                                                "_fontFamily": "Gotham-Bold.otf",
                                                                "_fontSize": 67,
                                                                "_fontStyle": "normal",
                                                                "_fontVariant": "normal",
                                                                "_fontWeight": "normal",
                                                                "_letterSpacing": 0,
                                                                "_lineHeight": 0,
                                                                "_lineJoin": "miter",
                                                                "_miterLimit": 10,
                                                                "_padding": 5,
                                                                "_stroke": "black",
                                                                "_strokeThickness": 0,
                                                                "_textBaseline": "alphabetic",
                                                                "_trim": false,
                                                                "_whiteSpace": "pre",
                                                                "_wordWrap": false,
                                                                "_wordWrapWidth": 100,
                                                                "_leading": 0
                                                            },
                                                            "text": "GOT A QUESTION?",
                                                            "text_transform": {
                                                                "uppercase": false
                                                            },
                                                            "maxHeight": 0
                                                        }
                                                    ],
                                                    "uuid": "b463054f-402f-4cbc-b8c7-4f07568a5031",
                                                    "type": "GROUP",
                                                    "name": "QuestionGRP",
                                                    "x": 0,
                                                    "y": 0,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 696.3262917886743,
                                                    "height": 83,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [
                                                        {
                                                            "children": [],
                                                            "uuid": "3608ac56-4870-41d3-9c91-559e6bf67aff",
                                                            "type": "RECTANGLE",
                                                            "name": "Background",
                                                            "x": 83,
                                                            "y": 959,
                                                            "control": {
                                                                "id": 0,
                                                                "name": ""
                                                            },
                                                            "width": 551,
                                                            "height": 76,
                                                            "alpha": 1,
                                                            "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}\n// Executed when graphic\n// updated each frame\nconst padding = 30; \nconst text_node_name = 'PhoneTEXT'\n\nfunction update(item, frame, scene) { \nlet text = hyperAPI.getItemByName(scene.name, text_node_name); \nitem.width = (text.x - item.x) + text.width + padding; }\n",
                                                            "graphicsData": {
                                                                "x": 0,
                                                                "y": 0,
                                                                "width": 551,
                                                                "height": 76,
                                                                "radius": 0,
                                                                "type": 4
                                                            },
                                                            "fillColor": 16777215,
                                                            "maxWidth": 0,
                                                            "anchorX": 1,
                                                            "anchorY": 0,
                                                            "skew": {
                                                                "x": -0.23,
                                                                "y": 0
                                                            },
                                                            "scale": {
                                                                "x": 1,
                                                                "y": 1
                                                            },
                                                            "mask": null,
                                                            "visible": true
                                                        },
                                                        {
                                                            "children": [],
                                                            "uuid": "042d04fb-841f-4b2d-90eb-d3f600e06e60",
                                                            "type": "TEXT",
                                                            "name": "PhoneTEXT",
                                                            "x": 67,
                                                            "y": 957,
                                                            "control": {
                                                                "id": 0,
                                                                "name": ""
                                                            },
                                                            "width": 537,
                                                            "height": 73,
                                                            "alpha": 1,
                                                            "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}",
                                                            "maxWidth": 0,
                                                            "anchor": {
                                                                "x": "1",
                                                                "y": 0
                                                            },
                                                            "skew": {
                                                                "x": 0,
                                                                "y": 0
                                                            },
                                                            "scale": {
                                                                "x": 0.9981412639405205,
                                                                "y": 1
                                                            },
                                                            "blendMode": 0,
                                                            "mask": null,
                                                            "visible": true,
                                                            "style": {
                                                                "styleID": 57,
                                                                "_align": "left",
                                                                "_breakWords": false,
                                                                "_dropShadow": false,
                                                                "_dropShadowAlpha": 1,
                                                                "_dropShadowAngle": 0.5235987755982988,
                                                                "_dropShadowBlur": 0,
                                                                "_dropShadowColor": "black",
                                                                "_dropShadowDistance": 5,
                                                                "_fill": "#00285e",
                                                                "_fillGradientType": 0,
                                                                "_fillGradientStops": [],
                                                                "_fontFamily": "Gotham-Bold.otf",
                                                                "_fontSize": 67,
                                                                "_fontStyle": "normal",
                                                                "_fontVariant": "normal",
                                                                "_fontWeight": "normal",
                                                                "_letterSpacing": 0,
                                                                "_lineHeight": 0,
                                                                "_lineJoin": "miter",
                                                                "_miterLimit": 10,
                                                                "_padding": 5,
                                                                "_stroke": "black",
                                                                "_strokeThickness": 0,
                                                                "_textBaseline": "alphabetic",
                                                                "_trim": false,
                                                                "_whiteSpace": "pre",
                                                                "_wordWrap": false,
                                                                "_wordWrapWidth": 100,
                                                                "_leading": 0
                                                            },
                                                            "text": "+447537186538",
                                                            "text_transform": {
                                                                "uppercase": false
                                                            },
                                                            "maxHeight": 0
                                                        }
                                                    ],
                                                    "uuid": "0b06b41b-a7ae-4919-989b-07a6c096a187",
                                                    "type": "GROUP",
                                                    "name": "Group",
                                                    "x": 1770,
                                                    "y": 0,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 568.3262917886743,
                                                    "height": 83,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "82ad8aed-5473-41ba-bb0c-728e02da5462",
                                                    "type": "IMAGE",
                                                    "name": "SocialLOGO",
                                                    "tint": 16777215,
                                                    "x": 761,
                                                    "y": 933,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 470,
                                                    "height": 126.9,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.7833333333333333,
                                                        "y": 0.7833333333333333
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "src": "WhatsApp-Emblem.png"
                                                }
                                            ],
                                            "uuid": "a349c8e9-8710-45f2-a966-cfae2ca7d175",
                                            "type": "GROUP",
                                            "name": "ActionGRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 1821.3262917886743,
                                            "height": 126.9000244140625,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": false
                                        }
                                    ],
                                    "uuid": "68ae100a-2497-46a4-9d0a-ccc52d460c49",
                                    "type": "GROUP",
                                    "name": "BaseGRP",
                                    "x": 0,
                                    "y": 0,
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 2105.848968160377,
                                    "height": 270.9925141400495,
                                    "alpha": 1,
                                    "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}",
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": true
                                }
                            ],
                            "uuid": "47732648-5889-4b21-a446-6b0cdc889072",
                            "type": "GROUP",
                            "name": "Root",
                            "x": 0,
                            "y": 0,
                            "control": {
                                "id": 0,
                                "name": ""
                            },
                            "width": 2105.848968160377,
                            "height": 270.9925141400495,
                            "alpha": 1,
                            "maxWidth": 0,
                            "anchorX": 0,
                            "anchorY": 0,
                            "skew": {
                                "x": 0,
                                "y": 0
                            },
                            "scale": {
                                "x": 1,
                                "y": 1
                            },
                            "mask": null,
                            "visible": true
                        },
                        {
                            "children": [
                                {
                                    "children": [],
                                    "uuid": "8fe85de5-b507-42d6-a75f-cf005adf9581",
                                    "type": "IMAGE",
                                    "name": "Shadow",
                                    "tint": 16777215,
                                    "x": 33,
                                    "y": 831,
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1852,
                                    "height": 216,
                                    "alpha": "1",
                                    "maxWidth": 0,
                                    "anchor": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 0.9914346895074947,
                                        "y": 1
                                    },
                                    "blendMode": "0",
                                    "mask": "1f10480d-37c6-4b7d-bd3d-4d4175416277",
                                    "visible": true,
                                    "src": "L3rd-SHADOW.png"
                                },
                                {
                                    "children": [],
                                    "uuid": "1c82b6b3-c094-47fb-8206-f0046faecf1c",
                                    "type": "RECTANGLE",
                                    "name": "Line2",
                                    "x": 1853,
                                    "y": 846,
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 6,
                                    "height": 172,
                                    "alpha": 1,
                                    "graphicsData": {
                                        "x": 0,
                                        "y": 0,
                                        "width": 6,
                                        "height": 172,
                                        "radius": 0,
                                        "type": 4
                                    },
                                    "fillColor": 10334,
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": -0.28,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": false
                                },
                                {
                                    "children": [],
                                    "uuid": "1f10480d-37c6-4b7d-bd3d-4d4175416277",
                                    "type": "RECTANGLE",
                                    "name": "ShadowMASK",
                                    "x": 32,
                                    "y": 873,
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1900,
                                    "height": 200,
                                    "alpha": 1,
                                    "graphicsData": {
                                        "x": 0,
                                        "y": 0,
                                        "width": 1900,
                                        "height": 200,
                                        "radius": 0,
                                        "type": 4
                                    },
                                    "fillColor": 14355009,
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": true
                                },
                                {
                                    "children": [
                                        {
                                            "children": [],
                                            "uuid": "0175e461-d852-466e-9951-e97645a1903e",
                                            "type": "RECTANGLE",
                                            "name": "PopUpBG",
                                            "x": 299,
                                            "y": 794,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 288,
                                            "height": 50,
                                            "alpha": 1,
                                            "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}\n// Executed when graphic\n// updated each frame\nconst padding = 30; \nconst text_node_name = 'PopUpTEXT'\n\nfunction update(item, frame, scene) { \nlet text = hyperAPI.getItemByName(scene.name, text_node_name); \nitem.width = (text.x - item.x) + text.width + padding; }\n",
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 288,
                                                "height": 50,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 10334,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.28,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "5a98d645-31a7-42e9-bd7c-2538a61a48f8",
                                            "type": "TEXT",
                                            "name": "PopUpTEXT",
                                            "x": 317,
                                            "y": 794,
                                            "control": {
                                                "id": "11",
                                                "name": "PopUpTEXT",
                                                "data": {
                                                    "variables": [
                                                        {
                                                            "template": "NEVERNO_TWITTER",
                                                            "field": "Username"
                                                        }
                                                    ]
                                                }
                                            },
                                            "width": 240,
                                            "height": 43,
                                            "alpha": 1,
                                            "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}",
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 0.995850622406639,
                                                "y": 1
                                            },
                                            "blendMode": 0,
                                            "mask": null,
                                            "visible": true,
                                            "style": {
                                                "styleID": 55,
                                                "_align": "left",
                                                "_breakWords": false,
                                                "_dropShadow": false,
                                                "_dropShadowAlpha": 1,
                                                "_dropShadowAngle": 0.5235987755982988,
                                                "_dropShadowBlur": 0,
                                                "_dropShadowColor": "black",
                                                "_dropShadowDistance": 5,
                                                "_fill": "#6caddf",
                                                "_fillGradientType": 0,
                                                "_fillGradientStops": [],
                                                "_fontFamily": "Gotham-Medium.otf",
                                                "_fontSize": 40,
                                                "_fontStyle": "normal",
                                                "_fontVariant": "normal",
                                                "_fontWeight": "normal",
                                                "_letterSpacing": 0,
                                                "_lineHeight": 0,
                                                "_lineJoin": "miter",
                                                "_miterLimit": 10,
                                                "_padding": 5,
                                                "_stroke": "black",
                                                "_strokeThickness": 0,
                                                "_textBaseline": "alphabetic",
                                                "_trim": false,
                                                "_whiteSpace": "pre",
                                                "_wordWrap": false,
                                                "_wordWrapWidth": 100,
                                                "_leading": 0,
                                                "original_size": 40
                                            },
                                            "text": "@MrRobo19",
                                            "text_transform": {
                                                "uppercase": false
                                            },
                                            "maxHeight": 0
                                        }
                                    ],
                                    "uuid": "6d94cbb5-4c70-448c-96e9-a3c39849b1d7",
                                    "type": "GROUP",
                                    "name": "PopUpGRP",
                                    "x": 0,
                                    "y": 0,
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 301.8177824282057,
                                    "height": 53.05277191553853,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": true
                                },
                                {
                                    "children": [
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "d254656c-2305-45ec-8963-4541dd927d1a",
                                                    "type": "RECTANGLE",
                                                    "name": "LogoBG",
                                                    "x": 83,
                                                    "y": 846,
                                                    "control": {
                                                        "id": "0",
                                                        "name": ""
                                                    },
                                                    "width": 193.95744680851064,
                                                    "height": 172,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 193.95744680851064,
                                                        "height": 172,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 7122399,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "7b088ee9-4149-46a1-aa47-1c195c7bf4fe",
                                                    "type": "IMAGE",
                                                    "name": "SocialLogoLEFT",
                                                    "tint": 16777215,
                                                    "x": 82,
                                                    "y": 855,
                                                    "control": {
                                                        "id": "21",
                                                        "name": "SocialLOGO"
                                                    },
                                                    "width": 155,
                                                    "height": 155,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.31,
                                                        "y": 0.31
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "src": "TwitterWhite.png"
                                                }
                                            ],
                                            "uuid": "b6b316f3-882a-40a5-9b1e-8e53b5ed8037",
                                            "type": "GROUP",
                                            "name": "SocialLogoLeftGRP",
                                            "x": "0",
                                            "y": 0,
                                            "control": {
                                                "id": "20",
                                                "name": "SocialLogoLeft ON/OFF"
                                            },
                                            "width": 241.49061836153822,
                                            "height": 165.30153538945262,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "769c01f1-ed10-430d-a162-21fcf92eaaa2",
                                                    "type": "RECTANGLE",
                                                    "name": "ImageMASK",
                                                    "x": 83,
                                                    "y": 846,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 193.95744680851064,
                                                    "height": 172,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 193.95744680851064,
                                                        "height": 172,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 7122399,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "167b6ca7-32da-4828-8293-0c8d0efbc434",
                                                    "type": "IMAGE",
                                                    "name": "IMAGE",
                                                    "tint": 16777215,
                                                    "x": 43,
                                                    "y": 833,
                                                    "control": {
                                                        "id": "31",
                                                        "name": "ProfileIMAGE"
                                                    },
                                                    "width": 231.00000000000014,
                                                    "height": 231,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.5775000000000003,
                                                        "y": 0.5775
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "769c01f1-ed10-430d-a162-21fcf92eaaa2",
                                                    "visible": true,
                                                    "src": "example_twitter_profile_pic.jpg"
                                                }
                                            ],
                                            "uuid": "c464d6b4-5f3f-41dc-a337-bccde8652966",
                                            "type": "GROUP",
                                            "name": "ProfileImageGRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": "30",
                                                "name": "ProfileImage ON/OFF"
                                            },
                                            "width": 231,
                                            "height": 165.30153538945262,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "1ddc3a99-d52d-4b6e-b4d0-050e7ac53eb3",
                                                    "type": "RECTANGLE",
                                                    "name": "LogoBG",
                                                    "x": 83,
                                                    "y": 846,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 193.95744680851064,
                                                    "height": 172,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 193.95744680851064,
                                                        "height": 172,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 7122399,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "ed0ab644-ea28-432d-aeb4-ba1ac1d6fb85",
                                                    "type": "IMAGE",
                                                    "name": "BADGE",
                                                    "tint": 16777215,
                                                    "x": -109,
                                                    "y": 919,
                                                    "control": {
                                                        "id": "26",
                                                        "name": "TeamBADGE"
                                                    },
                                                    "width": 399.00000000000097,
                                                    "height": 399,
                                                    "alpha": 1,
                                                    "rotation": -7,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.5955223880597029,
                                                        "y": 0.5955223880597015
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "96bf4d38-9b30-4adf-9222-de70a85c0c16",
                                                    "visible": true,
                                                    "src": "Manchester_City.png"
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "96bf4d38-9b30-4adf-9222-de70a85c0c16",
                                                    "type": "RECTANGLE",
                                                    "name": "BadgeMASK",
                                                    "x": 83,
                                                    "y": 846,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 193.95744680851064,
                                                    "height": 172,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 193.95744680851064,
                                                        "height": 172,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 7122399,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                }
                                            ],
                                            "uuid": "18d402c1-eb98-41d9-8a98-64361c2e6a1a",
                                            "type": "GROUP",
                                            "name": "BadgeGRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": "25",
                                                "name": "TeamBadge ON/OFF"
                                            },
                                            "width": 241.49061836153822,
                                            "height": 165.30153538945262,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        }
                                    ],
                                    "uuid": "aac0ef78-a14c-49a4-b3a2-fb973e6b0b3d",
                                    "type": "GROUP",
                                    "name": "ImagesLeftGRP",
                                    "x": 0,
                                    "y": 0,
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 241.49061836153822,
                                    "height": 165.30153538945262,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": true
                                },
                                {
                                    "children": [
                                        {
                                            "children": [],
                                            "uuid": "1fe69be9-07c6-4739-a012-202e6d865a57",
                                            "type": "RECTANGLE",
                                            "name": "ContentBG",
                                            "x": 279,
                                            "y": 846,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 1577,
                                            "height": 172,
                                            "alpha": 1,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 1577,
                                                "height": 172,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 16777215,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.28,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "b64dd6b5-a593-41c4-ac80-8ea12a5a822f",
                                                    "type": "TEXT",
                                                    "name": "Body TEXT",
                                                    "x": 313,
                                                    "y": 905,
                                                    "control": {
                                                        "id": "12",
                                                        "name": "MainTEXT",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "NEVERNO_TWITTER",
                                                                    "field": "Message"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 1224,
                                                    "height": 114,
                                                    "alpha": 1,
                                                    "maxWidth": "1320",
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": "0.5"
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9991836734693877,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "b139fab1-ab60-41c4-9ba6-4d4cfc01bfba",
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 5255,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#00285e",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Medium.otf",
                                                        "_fontSize": 35,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": true,
                                                        "_wordWrapWidth": 1320,
                                                        "_leading": 0,
                                                        "original_size": 43
                                                    },
                                                    "text": "Look how LOW de Bruyne drops to open a pass for Ederson...just incredible reading of the game! #1 midfielder in the world! KDB is just unmatched!",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 115
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "fb090655-bdcc-4b22-bba0-79793b9b5799",
                                                    "type": "TEXT",
                                                    "name": "LowerTEXT",
                                                    "x": 310,
                                                    "y": 961,
                                                    "control": {
                                                        "id": "13",
                                                        "name": "LowerTEXT",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "NEVERNO_TWITTER",
                                                                    "field": "Hashtags"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 426,
                                                    "height": 46,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9976580796252927,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 53,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#6caddf",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Medium.otf",
                                                        "_fontSize": 42,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0,
                                                        "original_size": 42
                                                    },
                                                    "text": "#MUNMCI #ManCity",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                }
                                            ],
                                            "uuid": "2a9adc88-cbaf-4d4c-ab14-b1b747581222",
                                            "type": "GROUP",
                                            "name": "TextGRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 1236.9842529296875,
                                            "height": 161,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "b139fab1-ab60-41c4-9ba6-4d4cfc01bfba",
                                            "type": "RECTANGLE",
                                            "name": "BodyTextMASK",
                                            "x": 279,
                                            "y": 846,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 1577,
                                            "height": 172,
                                            "alpha": 1,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 1577,
                                                "height": 172,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 16711680,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.28,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        }
                                    ],
                                    "uuid": "0de80bbe-f4a7-4012-9c87-8d50cfdd01b1",
                                    "type": "GROUP",
                                    "name": "ContentGRP",
                                    "x": 0,
                                    "y": 0,
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1624.5331715530276,
                                    "height": 166,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": true
                                },
                                {
                                    "children": [
                                        {
                                            "children": [],
                                            "uuid": "4fd6269c-f266-4b69-a51c-788dc9644752",
                                            "type": "IMAGE",
                                            "name": "SocialLogoRIGHT",
                                            "tint": 16777215,
                                            "x": 1657,
                                            "y": 855,
                                            "control": {
                                                "id": "35",
                                                "name": "SocialLOGO Right"
                                            },
                                            "width": 155,
                                            "height": 155,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 0.31,
                                                "y": 0.31
                                            },
                                            "blendMode": 0,
                                            "mask": null,
                                            "visible": true,
                                            "src": "Twitter.png"
                                        }
                                    ],
                                    "uuid": "bd62125a-041e-4f7f-94d7-144a6a21a423",
                                    "type": "GROUP",
                                    "name": "SocialLogoRightGRP",
                                    "x": 0,
                                    "y": 0,
                                    "control": {
                                        "id": "0",
                                        "name": ""
                                    },
                                    "width": 155,
                                    "height": 155,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": true
                                },
                                {
                                    "children": [],
                                    "uuid": "cb421cf4-f870-4eb1-affd-24bc59211f38",
                                    "type": "RECTANGLE",
                                    "name": "Line1",
                                    "x": 278,
                                    "y": 846,
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 4,
                                    "height": 172,
                                    "alpha": 1,
                                    "graphicsData": {
                                        "x": 0,
                                        "y": 0,
                                        "width": 4,
                                        "height": 172,
                                        "radius": 0,
                                        "type": 4
                                    },
                                    "fillColor": 10334,
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": -0.28,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": true
                                }
                            ],
                            "uuid": "07367462-ac69-428c-85eb-86d4ee10e7c7",
                            "type": "GROUP",
                            "name": "L3rdGRP",
                            "x": 0,
                            "y": "5",
                            "control": {
                                "id": 0,
                                "name": ""
                            },
                            "width": 1852,
                            "height": 258,
                            "alpha": 1,
                            "maxWidth": 0,
                            "anchorX": 0,
                            "anchorY": 0,
                            "skew": {
                                "x": 0,
                                "y": 0
                            },
                            "scale": {
                                "x": 1,
                                "y": 1
                            },
                            "mask": null,
                            "visible": true
                        }
                    ],
                    "uuid": "075f978e-716c-4026-bf05-3477d880ce64",
                    "type": "SCENE",
                    "name": "Root",
                    "x": 0,
                    "y": 0,
                    "control": {
                        "id": 0,
                        "name": ""
                    },
                    "alpha": 1,
                    "maxWidth": 0,
                    "skew": {
                        "x": 0,
                        "y": 0
                    },
                    "scale": {
                        "x": 1,
                        "y": 1
                    },
                    "mask": null,
                    "visible": true
                }
            }
        },
        {
            "_id": "61828978a4737fae26881166",
            "name": "ScoreBug",
            "project": "6182817751b7a0dd4843297a",
            "__v": 0,
            "data": {
                "name": "ScoreBug",
                "football": {
                    "templates": [
                        {
                            "name": "Clock"
                        }
                    ]
                },
                "width": 1920,
                "height": 1080,
                "folder": "UNIVERSAL",
                "aspect": "16x9",
                "timelines": [
                    {
                        "name": "IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": [
                                    {
                                        "property": "TIMELINE",
                                        "keyframes": [
                                            {
                                                "frame": 5,
                                                "name": "CLOCK_IN",
                                                "action": "PLAY"
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "d2a0c7f6-0442-433b-ba50-b65eae7725d4",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -692,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 24,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.20703125,
                                                    0.9771875
                                                ],
                                                "type": "ease-out",
                                                "time": 480
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "eeee67e5-dcad-459d-8222-2e3fadcd9fae",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -160,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "4466a5a3-c80c-4a56-8b13-1eb043c408f1",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -103,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "7652410c-f849-4649-aa2a-9985a649c695",
                                "animations": [
                                    {
                                        "property": "width",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 337,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "a02cacc2-889a-4694-a6d4-74e1fd356892",
                                "animations": [
                                    {
                                        "property": "width",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 344.95677233429393,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    },
                                    {
                                        "property": "height",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 66,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": -1,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 25,
                        "loop": false,
                        "fps": 50,
                        "id": "2202fc8e-a224-4d84-bfad-23cc0d8e39a1",
                        "prevFrame": 25
                    },
                    {
                        "name": "OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "d2a0c7f6-0442-433b-ba50-b65eae7725d4",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 24,
                                                "value": -704,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.16703125,
                                                    0.8171875
                                                ],
                                                "type": "ease-out",
                                                "time": 480
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 25,
                        "loop": false,
                        "fps": 50,
                        "id": "4067bc5c-a366-4cc3-882a-12c9a0c9306f"
                    },
                    {
                        "name": "CLOCK_IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "eeee67e5-dcad-459d-8222-2e3fadcd9fae",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -160,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 18,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 360
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "7652410c-f849-4649-aa2a-9985a649c695",
                                "animations": [
                                    {
                                        "property": "width",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 337,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 21,
                                                "value": 510,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 420
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "4466a5a3-c80c-4a56-8b13-1eb043c408f1",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -103,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "a02cacc2-889a-4694-a6d4-74e1fd356892",
                                "animations": [
                                    {
                                        "property": "width",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 344.95677233429393,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 18,
                                                "value": 496,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.713580322265625,
                                                    0.673948974609375
                                                ],
                                                "type": "ease-out",
                                                "time": 360
                                            }
                                        ]
                                    },
                                    {
                                        "property": "height",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 66,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": -1,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 25,
                        "loop": false,
                        "fps": 50,
                        "id": "c5220b2a-6986-4d13-9110-062311a4869f",
                        "prevFrame": 25
                    },
                    {
                        "name": "CLOCK_OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "eeee67e5-dcad-459d-8222-2e3fadcd9fae",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 17,
                                                "value": -167,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.86703125,
                                                    0.20718749999999997
                                                ],
                                                "type": "ease-out",
                                                "time": 340
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "7652410c-f849-4649-aa2a-9985a649c695",
                                "animations": [
                                    {
                                        "property": "width",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 510,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 17,
                                                "value": 337,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 340
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "a02cacc2-889a-4694-a6d4-74e1fd356892",
                                "animations": [
                                    {
                                        "property": "width",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 496,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 17,
                                                "value": 345,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.853580322265625,
                                                    0.26394897460937505
                                                ],
                                                "type": "ease-out",
                                                "time": 340
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 25,
                        "loop": false,
                        "fps": 50,
                        "id": "9e4a2cb9-b67a-4de8-9be9-d43c64f00160"
                    },
                    {
                        "name": "ADDEDTIME_IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "4466a5a3-c80c-4a56-8b13-1eb043c408f1",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -98,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.30703125,
                                                    0.8571875
                                                ],
                                                "type": "ease-out",
                                                "time": 300
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "a02cacc2-889a-4694-a6d4-74e1fd356892",
                                "animations": [
                                    {
                                        "property": "width",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 496.5585065485111,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 586,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.353580322265625,
                                                    0.803948974609375
                                                ],
                                                "type": "ease-out",
                                                "time": 300
                                            }
                                        ]
                                    },
                                    {
                                        "property": "height",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 66,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 25,
                        "loop": false,
                        "fps": 50,
                        "id": "4cab7da3-1c66-4829-8776-9e36105f2536"
                    },
                    {
                        "name": "ADDEDTIME_OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "4466a5a3-c80c-4a56-8b13-1eb043c408f1",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 16,
                                                "value": -103,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.76703125,
                                                    0.18718749999999995
                                                ],
                                                "type": "ease-out",
                                                "time": 320
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "a02cacc2-889a-4694-a6d4-74e1fd356892",
                                "animations": [
                                    {
                                        "property": "width",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 586,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 16,
                                                "value": 496.86731391585755,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.693580322265625,
                                                    0.24394897460937504
                                                ],
                                                "type": "ease-out",
                                                "time": 320
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 25,
                        "loop": false,
                        "fps": 50,
                        "id": "f1c53b10-569c-48a4-93db-6c824faf1ff3"
                    },
                    {
                        "name": "RCH1_ON",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "4c4ccf32-259a-42b8-8dca-8b9d1bca3302",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 20,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 25,
                        "loop": false,
                        "fps": 50,
                        "id": "15bd4a6c-e1f4-4ba4-b990-93c27c9c8253"
                    },
                    {
                        "name": "RCH2_ON",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "da34da2e-efc8-41c9-aeb5-d6c0dd40c350",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 20,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 25,
                        "loop": false,
                        "fps": 50,
                        "id": "a76432b9-123f-4032-9265-ee282da16ef9"
                    },
                    {
                        "name": "RCH3_ON",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "2a3c5f38-6694-4416-ada3-2a0d5305e73a",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 20,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 25,
                        "loop": false,
                        "fps": 50,
                        "id": "01b7c776-ed4a-45a5-9d9d-364cbbf9f531"
                    },
                    {
                        "name": "RCA1_ON",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "9597dc72-ca05-480c-afd3-dd7213a4156f",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 20,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 25,
                        "loop": false,
                        "fps": 50,
                        "id": "89767d3a-5154-4dbd-a904-6d67ccefcf8d"
                    },
                    {
                        "name": "RCA2_ON",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "361bfab5-75af-4c85-8459-52db7c0d1618",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 20,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 25,
                        "loop": false,
                        "fps": 50,
                        "id": "e7bcad10-55a5-4024-bd9d-ed0968de9fcb"
                    },
                    {
                        "name": "RCA3_ON",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "07c0573f-1b65-4d98-b2f2-71b520552667",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 20,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 25,
                        "loop": false,
                        "fps": 50,
                        "id": "bc7df276-6a7f-4a71-a3dc-051f2629e8a1"
                    },
                    {
                        "name": "RCH1_OFF",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "4c4ccf32-259a-42b8-8dca-8b9d1bca3302",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 20,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 300
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 25,
                        "loop": false,
                        "fps": 50,
                        "id": "9dc5ee31-108a-49cf-8aa2-70de8263bb47"
                    },
                    {
                        "name": "RCH2_OFF",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "da34da2e-efc8-41c9-aeb5-d6c0dd40c350",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 20,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 300
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 25,
                        "loop": false,
                        "fps": 50,
                        "id": "cff35a2e-87d6-4963-9339-22ee055125df"
                    },
                    {
                        "name": "RCH3_OFF",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "2a3c5f38-6694-4416-ada3-2a0d5305e73a",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 20,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 300
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 25,
                        "loop": false,
                        "fps": 50,
                        "id": "b1dd575e-5ba4-49e3-a0c4-03e28751f5e9"
                    },
                    {
                        "name": "RCA1_OFF",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "9597dc72-ca05-480c-afd3-dd7213a4156f",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 20,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 300
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 25,
                        "loop": false,
                        "fps": 50,
                        "id": "58e37dc2-09d5-42b0-a653-88901410d57a"
                    },
                    {
                        "name": "RCA2_OFF",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "361bfab5-75af-4c85-8459-52db7c0d1618",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 20,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 300
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 25,
                        "loop": false,
                        "fps": 50,
                        "id": "26d30d29-739f-4d0a-bc96-17be00fe280a"
                    },
                    {
                        "name": "RCA3_OFF",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "07c0573f-1b65-4d98-b2f2-71b520552667",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 20,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 300
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 25,
                        "loop": false,
                        "fps": 50,
                        "id": "fee66f34-9970-4f95-9124-4cacf66d7958"
                    }
                ],
                "scene": {
                    "children": [
                        {
                            "children": [
                                {
                                    "children": [],
                                    "uuid": "35d42fa7-5ead-4eaf-9e2e-40c4ad908181",
                                    "type": "IMAGE",
                                    "name": "REF",
                                    "tint": 16777215,
                                    "x": "0",
                                    "y": "0",
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1920,
                                    "height": 1080,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchor": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "blendMode": 0,
                                    "mask": null,
                                    "visible": false,
                                    "src": "SCoreBugREF.jpg"
                                },
                                {
                                    "children": [],
                                    "uuid": "45f041c6-464b-44e1-ab1f-77119a69d13c",
                                    "type": "RECTANGLE",
                                    "name": "Background",
                                    "x": "0",
                                    "y": "0",
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1986,
                                    "height": 1080,
                                    "alpha": 1,
                                    "graphicsData": {
                                        "x": 0,
                                        "y": 0,
                                        "width": 1986,
                                        "height": 1080,
                                        "radius": 0,
                                        "type": 4
                                    },
                                    "fillColor": 14355009,
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": false
                                },
                                {
                                    "children": [
                                        {
                                            "children": [],
                                            "uuid": "d4c8cc85-bc1b-438f-b1ea-03ef4dbc428d",
                                            "type": "IMAGE",
                                            "name": "Stroke",
                                            "tint": 16777215,
                                            "x": 265,
                                            "y": 64,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 167.19230769230774,
                                            "height": 63,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 0.8076923076923079,
                                                "y": 0.8076923076923077
                                            },
                                            "blendMode": 0,
                                            "mask": null,
                                            "visible": true,
                                            "src": "L3Stroke3.png"
                                        },
                                        {
                                            "children": [],
                                            "uuid": "b8125381-9d65-499d-9ec5-db36e8af7bd5",
                                            "type": "IMAGE",
                                            "name": "Grad",
                                            "tint": 16777215,
                                            "x": 54,
                                            "y": 70,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 485.8064516129032,
                                            "height": 60,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 0.9677419354838709,
                                                "y": 0.967741935483871
                                            },
                                            "blendMode": 0,
                                            "mask": "7652410c-f849-4649-aa2a-9985a649c695",
                                            "visible": true,
                                            "src": "ScoreBug_Grad2.png"
                                        },
                                        {
                                            "children": [],
                                            "uuid": "7652410c-f849-4649-aa2a-9985a649c695",
                                            "type": "RECTANGLE",
                                            "name": "GradMASK",
                                            "x": 69,
                                            "y": 71,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 510,
                                            "height": 61.00000000000001,
                                            "alpha": 1,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 510,
                                                "height": 61.00000000000001,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 8311585,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.28,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "a02cacc2-889a-4694-a6d4-74e1fd356892",
                                            "type": "RECTANGLE",
                                            "name": "ScoreBugOutline",
                                            "x": "88",
                                            "y": "52",
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 496,
                                            "height": 66,
                                            "alpha": 1,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 496.00000000000006,
                                                "height": 66,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 16777215,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.28,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 0.9999999999999999,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": false
                                        },
                                        {
                                            "children": [],
                                            "uuid": "4b44899e-a758-4206-82b3-2bb21ba932b3",
                                            "type": "RECTANGLE",
                                            "name": "ClockMASK",
                                            "x": 427,
                                            "y": "55",
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 154,
                                            "height": 60.00000000000001,
                                            "alpha": 1,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 154,
                                                "height": 60.00000000000001,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 8311585,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.28,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "e238ece5-f7bf-46d9-89c7-b48e135dff08",
                                            "type": "RECTANGLE",
                                            "name": "AddedTimeMASK",
                                            "x": 579,
                                            "y": "55",
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 90,
                                            "height": 60.00000000000001,
                                            "alpha": 1,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 90,
                                                "height": 60.00000000000001,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 8311585,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.28,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "4c4ccf32-259a-42b8-8dca-8b9d1bca3302",
                                                    "type": "RECTANGLE",
                                                    "name": "RedCardHome1",
                                                    "x": 105,
                                                    "y": 20,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 28,
                                                    "height": 31,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 28,
                                                        "height": 31,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 13632027,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "da34da2e-efc8-41c9-aeb5-d6c0dd40c350",
                                                    "type": "RECTANGLE",
                                                    "name": "RedCardHome2",
                                                    "x": 140,
                                                    "y": 20,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 28,
                                                    "height": 31,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 28,
                                                        "height": 31,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 13632027,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "2a3c5f38-6694-4416-ada3-2a0d5305e73a",
                                                    "type": "RECTANGLE",
                                                    "name": "RedCardHome3",
                                                    "x": "175",
                                                    "y": 20,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 28,
                                                    "height": 31,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 28,
                                                        "height": 31,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 13632027,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "9597dc72-ca05-480c-afd3-dd7213a4156f",
                                                    "type": "RECTANGLE",
                                                    "name": "RedCardAway1",
                                                    "x": "325",
                                                    "y": 20,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 28,
                                                    "height": 31,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 28,
                                                        "height": 31,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 13632027,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "361bfab5-75af-4c85-8459-52db7c0d1618",
                                                    "type": "RECTANGLE",
                                                    "name": "RedCardAway2",
                                                    "x": "360",
                                                    "y": 20,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 28,
                                                    "height": 31,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 28,
                                                        "height": 31,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 13632027,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "07c0573f-1b65-4d98-b2f2-71b520552667",
                                                    "type": "RECTANGLE",
                                                    "name": "RedCardAway3",
                                                    "x": "395",
                                                    "y": 20,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 28,
                                                    "height": 31,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 28,
                                                        "height": 31,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 13632027,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                }
                                            ],
                                            "uuid": "b3af1618-1771-468c-b1f9-ff4b1f6788b1",
                                            "type": "GROUP",
                                            "name": "RedCardGRP",
                                            "x": 0,
                                            "y": 40,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 326.5670251054875,
                                            "height": 29.7927185876339,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [
                                                        {
                                                            "children": [],
                                                            "uuid": "392daeff-eb3a-4d6c-9fdb-408402c3d6e5",
                                                            "type": "RECTANGLE",
                                                            "name": "AddedTimeBase",
                                                            "x": 579,
                                                            "y": "55",
                                                            "control": {
                                                                "id": 0,
                                                                "name": ""
                                                            },
                                                            "width": 90,
                                                            "height": 60.00000000000001,
                                                            "alpha": 1,
                                                            "graphicsData": {
                                                                "x": 0,
                                                                "y": 0,
                                                                "width": 90,
                                                                "height": 60.00000000000001,
                                                                "radius": 0,
                                                                "type": 4
                                                            },
                                                            "fillColor": 7122399,
                                                            "maxWidth": 0,
                                                            "anchorX": 0,
                                                            "anchorY": 0,
                                                            "skew": {
                                                                "x": -0.28,
                                                                "y": 0
                                                            },
                                                            "scale": {
                                                                "x": 1,
                                                                "y": 1
                                                            },
                                                            "mask": null,
                                                            "visible": true
                                                        },
                                                        {
                                                            "children": [],
                                                            "uuid": "6cfc9632-c913-4fd3-a5ba-eedc6c428700",
                                                            "type": "TEXT",
                                                            "name": "Added Time",
                                                            "x": 615,
                                                            "y": "61",
                                                            "control": {
                                                                "id": "7",
                                                                "name": "Added Time"
                                                            },
                                                            "width": 30,
                                                            "height": 36,
                                                            "alpha": 1,
                                                            "maxWidth": 0,
                                                            "anchor": {
                                                                "x": ".5",
                                                                "y": 0
                                                            },
                                                            "skew": {
                                                                "x": 0,
                                                                "y": 0
                                                            },
                                                            "scale": {
                                                                "x": 0.967741935483871,
                                                                "y": 1
                                                            },
                                                            "blendMode": 0,
                                                            "mask": null,
                                                            "visible": true,
                                                            "style": {
                                                                "styleID": 56,
                                                                "_align": "left",
                                                                "_breakWords": false,
                                                                "_dropShadow": false,
                                                                "_dropShadowAlpha": 1,
                                                                "_dropShadowAngle": 0.5235987755982988,
                                                                "_dropShadowBlur": 0,
                                                                "_dropShadowColor": "black",
                                                                "_dropShadowDistance": 5,
                                                                "_fill": "#00285e",
                                                                "_fillGradientType": 0,
                                                                "_fillGradientStops": [],
                                                                "_fontFamily": "GothamNarrow-Medium.otf",
                                                                "_fontSize": 34,
                                                                "_fontStyle": "normal",
                                                                "_fontVariant": "normal",
                                                                "_fontWeight": "normal",
                                                                "_letterSpacing": 0,
                                                                "_lineHeight": 0,
                                                                "_lineJoin": "miter",
                                                                "_miterLimit": 10,
                                                                "_padding": 5,
                                                                "_stroke": "black",
                                                                "_strokeThickness": 0,
                                                                "_textBaseline": "alphabetic",
                                                                "_trim": false,
                                                                "_whiteSpace": "pre",
                                                                "_wordWrap": false,
                                                                "_wordWrapWidth": 100,
                                                                "_leading": 0
                                                            },
                                                            "text": "+1",
                                                            "text_transform": {
                                                                "uppercase": false
                                                            },
                                                            "maxHeight": 0
                                                        }
                                                    ],
                                                    "uuid": "4466a5a3-c80c-4a56-8b13-1eb043c408f1",
                                                    "type": "GROUP",
                                                    "name": "AddedTime_GRP",
                                                    "x": -103,
                                                    "y": 0,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 106.58133891384682,
                                                    "height": 57.66332629864627,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": "e238ece5-f7bf-46d9-89c7-b48e135dff08",
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "907b3d40-e486-4131-b790-0a60a5f49e70",
                                                    "type": "RECTANGLE",
                                                    "name": "ClockBase",
                                                    "x": 427,
                                                    "y": "55",
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 154,
                                                    "height": 60.00000000000001,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 154,
                                                        "height": 60.00000000000001,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 10334,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": "4b44899e-a758-4206-82b3-2bb21ba932b3",
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "4de9b831-d624-4079-8f61-c8fd567c4007",
                                                    "type": "TEXT",
                                                    "name": "Game Clock",
                                                    "x": 496,
                                                    "y": "63",
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 104,
                                                    "height": 36,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": ".5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9904761904761905,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "4b44899e-a758-4206-82b3-2bb21ba932b3",
                                                    "visible": false,
                                                    "style": {
                                                        "styleID": 59,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#ffffff",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Medium.otf",
                                                        "_fontSize": 34,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "190:00",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "5f6dd178-7639-4011-a916-ade127a703b4",
                                                    "type": "CLOCK",
                                                    "name": "Clock",
                                                    "x": 451,
                                                    "y": 63,
                                                    "control": {
                                                        "id": "999",
                                                        "name": "GAME CLOCK",
                                                        "editables": [],
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Clock",
                                                                    "field": "Game Clock"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 90,
                                                    "height": 40,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.989010989010989,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 31,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "white",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Arial",
                                                        "_fontSize": 36,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 0,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0,
                                                        "original_size": 36
                                                    },
                                                    "text": "00:00",
                                                    "format": "mm:ss",
                                                    "start_from": 0,
                                                    "clock_type": "COUNTUP",
                                                    "stop_at_zero": true
                                                }
                                            ],
                                            "uuid": "eeee67e5-dcad-459d-8222-2e3fadcd9fae",
                                            "type": "GROUP",
                                            "name": "Clock_GRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 170.58133891384682,
                                            "height": 57.66332629864627,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "4277a19d-7544-4b53-b08d-79b44f4e50ed",
                                                    "type": "RECTANGLE",
                                                    "name": "TeamsBG",
                                                    "x": 91,
                                                    "y": "55",
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 337,
                                                    "height": 60.00000000000001,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 337,
                                                        "height": 60.00000000000001,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 7122399,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "d50bacac-291e-43ab-af86-9ccb05702e8f",
                                                    "type": "TEXT",
                                                    "name": "Home Team",
                                                    "x": 143,
                                                    "y": 61,
                                                    "control": {
                                                        "id": "1",
                                                        "name": "Home Team",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Clock",
                                                                    "field": "Home Team Code"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 83,
                                                    "height": 42,
                                                    "alpha": 1,
                                                    "maxWidth": "95",
                                                    "anchor": {
                                                        "x": "0.5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9880952380952381,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 55,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "white",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Bold.otf",
                                                        "_fontSize": 40,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "CITY",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "f0e24053-fb60-4b1d-91d0-ef2a2e16e086",
                                                    "type": "TEXT",
                                                    "name": "Away Team",
                                                    "x": 363,
                                                    "y": 61,
                                                    "control": {
                                                        "id": "4",
                                                        "name": "Away Team",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Clock",
                                                                    "field": "Away Team Code"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 75,
                                                    "height": 42,
                                                    "alpha": 1,
                                                    "maxWidth": "95",
                                                    "anchor": {
                                                        "x": "0.5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9868421052631579,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 47,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "white",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Bold.otf",
                                                        "_fontSize": 40,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "UTD",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                }
                                            ],
                                            "uuid": "6a0475f7-9221-4d42-9b81-59caa3152cd9",
                                            "type": "GROUP",
                                            "name": "Teams_GRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 353.5813389138468,
                                            "height": 57.66332629864627,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "9ee2c50e-03dd-4731-b4e5-be098bad80de",
                                                    "type": "RECTANGLE",
                                                    "name": "ScoreBG",
                                                    "x": 208,
                                                    "y": "55",
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 104,
                                                    "height": 60.00000000000001,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 104,
                                                        "height": 60.00000000000001,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "01d5fc4b-4d82-4af6-8d17-5c8c3b26f6e1",
                                                    "type": "TEXT",
                                                    "name": "ScoreDot",
                                                    "x": 252,
                                                    "y": 63,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 13,
                                                    "height": 36,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": ".5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9285714285714286,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 40,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#00285e",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Bold.otf",
                                                        "_fontSize": 34,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "-",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "3e41034d-2ac9-42ed-b585-4e2f5edc3761",
                                                    "type": "TEXT",
                                                    "name": "Home Score",
                                                    "x": 226,
                                                    "y": "61",
                                                    "control": {
                                                        "id": "2",
                                                        "name": "Home Score",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Clock",
                                                                    "field": "Home Score"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 25,
                                                    "height": 42,
                                                    "alpha": 1,
                                                    "maxWidth": "36",
                                                    "anchor": {
                                                        "x": ".5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9615384615384616,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 49,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#00285e",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Bold.otf",
                                                        "_fontSize": 40,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "0",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "524f8739-01ae-48e2-bb9b-4ab644040a12",
                                                    "type": "TEXT",
                                                    "name": "Away Score",
                                                    "x": 280,
                                                    "y": "61",
                                                    "control": {
                                                        "id": "3",
                                                        "name": "Away Score",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Clock",
                                                                    "field": "Away Score"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 25,
                                                    "height": 42,
                                                    "alpha": 1,
                                                    "maxWidth": "36",
                                                    "anchor": {
                                                        "x": ".5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9615384615384616,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 50,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#00285e",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Bold.otf",
                                                        "_fontSize": 40,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "0",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                }
                                            ],
                                            "uuid": "4c6b0433-bada-4e62-869e-f671cbcb2f73",
                                            "type": "GROUP",
                                            "name": "Score_GRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 120.58133891384682,
                                            "height": 57.66332629864627,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        }
                                    ],
                                    "uuid": "d2a0c7f6-0442-433b-ba50-b65eae7725d4",
                                    "type": "GROUP",
                                    "name": "ScoreBugGrp",
                                    "x": 0,
                                    "y": 0,
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 527,
                                    "height": 74.62438173695705,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": true
                                }
                            ],
                            "uuid": "a9e8397b-aac8-4236-a5a8-15aa1b75f67a",
                            "type": "GROUP",
                            "name": "Root",
                            "x": 0,
                            "y": 0,
                            "control": {
                                "id": 0,
                                "name": ""
                            },
                            "width": 527,
                            "height": 74.62438173695705,
                            "alpha": 1,
                            "maxWidth": 0,
                            "anchorX": 0,
                            "anchorY": 0,
                            "skew": {
                                "x": 0,
                                "y": 0
                            },
                            "scale": {
                                "x": 1,
                                "y": 1
                            },
                            "mask": null,
                            "visible": true
                        }
                    ],
                    "uuid": "c53a7164-0af3-422e-ada0-64adfd10f829",
                    "type": "SCENE",
                    "name": "Root",
                    "x": 0,
                    "y": 0,
                    "control": {
                        "id": 0,
                        "name": ""
                    },
                    "alpha": 1,
                    "maxWidth": 0,
                    "skew": {
                        "x": 0,
                        "y": 0
                    },
                    "scale": {
                        "x": 1,
                        "y": 1
                    },
                    "mask": null,
                    "visible": true
                }
            }
        },
        {
            "_id": "6182c40a09d9590850d9ec43",
            "name": "L3_MatchInfo_V",
            "data": {
                "name": "L3_MatchInfo_V",
                "football": {
                    "template": "Versus",
                    "templates": [
                        {
                            "name": "Versus"
                        }
                    ]
                },
                "width": 1920,
                "height": 1080,
                "folder": "UNIVERSAL",
                "aspect": "16x9",
                "timelines": [
                    {
                        "name": "IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": [
                                    {
                                        "property": "TIMELINE",
                                        "keyframes": [
                                            {
                                                "frame": 5,
                                                "action": "PLAY",
                                                "name": "T3"
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "938f89bb-e7fa-4ae0-abd8-9d7b10f79eec",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": -1629,
                                                "curve": [
                                                    0.65703125,
                                                    0.40718750000000004,
                                                    1,
                                                    1
                                                ],
                                                "type": "ease-in",
                                                "time": 20
                                            },
                                            {
                                                "frame": 25,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.43703125,
                                                    1.0271875
                                                ],
                                                "type": "ease-out",
                                                "time": 500
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "8c31881b-8aab-436b-8c9e-31d36903e883",
                                "animations": [
                                    {
                                        "property": "width",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0.00980392156862745,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 19,
                                                "value": 0.00980392156862745,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 380
                                            },
                                            {
                                                "frame": 33,
                                                "value": 102,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 660
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 492,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "a2827d9e-46ce-445a-b993-e2bc4d06d0a6",
                                "animations": [
                                    {
                                        "property": "width",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0.00980392156862745,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 19,
                                                "value": 0.00980392156862745,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 380
                                            },
                                            {
                                                "frame": 33,
                                                "value": 102,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 660
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1450,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "d19a4852-4e24-4ac3-a0b8-c2ae6f8850ec",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 49,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 19,
                                                "value": 49,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 380
                                            },
                                            {
                                                "frame": 33,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.316978759765625,
                                                    0.93107666015625
                                                ],
                                                "type": "ease-out",
                                                "time": 660
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "e7cc1160-af45-468a-b896-fb4de06f6869",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1316,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 19,
                                                "value": 1316,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 380
                                            },
                                            {
                                                "frame": 33,
                                                "value": 886,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.209000244140625,
                                                    0.8135003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 660
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "668c8d56-1229-4fdc-867c-bdc8281fce3c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    },
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 603,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 19,
                                                "value": 603,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 380
                                            },
                                            {
                                                "frame": 33,
                                                "value": 1035,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.229000244140625,
                                                    0.8635003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 660
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "75a22d85-b516-4f6d-a8c8-fa61b5d79649",
                                "animations": [
                                    {
                                        "property": "width",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 9,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 180
                                            },
                                            {
                                                "frame": 21,
                                                "value": 128,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 420
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "c7370d80-7884-42dc-91e4-9d719fc114de",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "34187d8f-13cb-4eb1-b75f-93ec63044393",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 774,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": -1,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 34,
                        "loop": false,
                        "fps": 50,
                        "id": "80c640cf-d177-4d97-aefe-2516588adfcf",
                        "seeking": false,
                        "prevFrame": 34
                    },
                    {
                        "name": "OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "938f89bb-e7fa-4ae0-abd8-9d7b10f79eec",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 7,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.039000244140625,
                                                    0.9035003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 140
                                            },
                                            {
                                                "frame": 25,
                                                "value": 1609,
                                                "curve": [
                                                    1,
                                                    0.06350036621093746,
                                                    1,
                                                    1
                                                ],
                                                "type": "ease-in",
                                                "time": 500
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "a2827d9e-46ce-445a-b993-e2bc4d06d0a6",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1450,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 10,
                                                "value": 1343,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 200
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "d19a4852-4e24-4ac3-a0b8-c2ae6f8850ec",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 49,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "e7cc1160-af45-468a-b896-fb4de06f6869",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 19,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 380
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "668c8d56-1229-4fdc-867c-bdc8281fce3c",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 19,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 380
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "8c31881b-8aab-436b-8c9e-31d36903e883",
                                "animations": [
                                    {
                                        "property": "width",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 102,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 10,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 200
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "75a22d85-b516-4f6d-a8c8-fa61b5d79649",
                                "animations": [
                                    {
                                        "property": "width",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 128,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": -1,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 30,
                        "loop": false,
                        "fps": 50,
                        "id": "434ffc9a-6d67-4971-8824-f1d8cdb5846a",
                        "seeking": false,
                        "prevFrame": 30
                    },
                    {
                        "name": "T3",
                        "playingState": 1,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "0c1c41a0-e90f-4ee6-b746-5738e21e849b",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1380,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 150,
                                                "value": 1351,
                                                "curve": [
                                                    0.42,
                                                    0,
                                                    0.58,
                                                    1
                                                ],
                                                "type": "ease-in-out",
                                                "time": 3000
                                            },
                                            {
                                                "frame": 300,
                                                "value": 1380,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 6000
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "af7d68fe-572c-4e1f-8058-2986005852ef",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 340,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 150,
                                                "value": 375,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 3000
                                            },
                                            {
                                                "frame": 300,
                                                "value": 340,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 6000
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 1800.6000001430511,
                        "frameNumber": 90,
                        "delta": 0,
                        "duration": 300,
                        "loop": true,
                        "fps": 50,
                        "id": "1074c466-961c-4715-ac23-322ce2ae2889",
                        "prevFrame": 90
                    },
                    {
                        "name": "UPDATE-IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "c7370d80-7884-42dc-91e4-9d719fc114de",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "34187d8f-13cb-4eb1-b75f-93ec63044393",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 905,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 8,
                                                "value": 1039,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 160
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "e7cc1160-af45-468a-b896-fb4de06f6869",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1256,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 17,
                                                "value": 886,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.199000244140625,
                                                    0.7035003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 340
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "668c8d56-1229-4fdc-867c-bdc8281fce3c",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 604,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 17,
                                                "value": 1035,
                                                "curve": [
                                                    0,
                                                    0,
                                                    0.229000244140625,
                                                    0.8035003662109375
                                                ],
                                                "type": "ease-out",
                                                "time": 340
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "d19a4852-4e24-4ac3-a0b8-c2ae6f8850ec",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 55,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 17,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 340
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 25,
                        "loop": false,
                        "fps": 50,
                        "id": "26197c07-ecf2-4bae-a824-07ac423be70d"
                    },
                    {
                        "name": "UPDATE-OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "c7370d80-7884-42dc-91e4-9d719fc114de",
                                "animations": [
                                    {
                                        "property": "alpha",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 1,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "34187d8f-13cb-4eb1-b75f-93ec63044393",
                                "animations": [
                                    {
                                        "property": "position.x",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 774,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 10,
                                                "value": 774,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 200
                                            },
                                            {
                                                "frame": 14,
                                                "value": 905,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "object": "d19a4852-4e24-4ac3-a0b8-c2ae6f8850ec",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 14,
                                                "value": 55,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 280
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 14,
                        "loop": false,
                        "fps": 50,
                        "id": "f0eee6dd-906e-4bfd-bf1e-52695e0b48e3"
                    },
                    {
                        "name": "ALT-UPDATE-IN",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "d19a4852-4e24-4ac3-a0b8-c2ae6f8850ec",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 49,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 1,
                                                "value": 49,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 300
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 15,
                        "loop": false,
                        "fps": 50,
                        "id": "898c7135-6d8c-4f89-8be2-3abccfd2a72c"
                    },
                    {
                        "name": "ALT-UPDATE-OUT",
                        "playingState": 0,
                        "tracks": [
                            {
                                "object": "Events",
                                "animations": []
                            },
                            {
                                "object": "d19a4852-4e24-4ac3-a0b8-c2ae6f8850ec",
                                "animations": [
                                    {
                                        "property": "position.y",
                                        "keyframes": [
                                            {
                                                "frame": 1,
                                                "value": 0,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 20
                                            },
                                            {
                                                "frame": 15,
                                                "value": 49,
                                                "curve": [
                                                    0,
                                                    0,
                                                    1,
                                                    1
                                                ],
                                                "type": "linear",
                                                "time": 300
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                        "currentTime": 0,
                        "frameNumber": -1,
                        "delta": 0,
                        "duration": 15,
                        "loop": false,
                        "fps": 50,
                        "id": "70e09d81-da41-4e4a-9436-c50a48fe8ba7"
                    }
                ],
                "scene": {
                    "children": [
                        {
                            "children": [
                                {
                                    "children": [],
                                    "uuid": "3cce9d50-7839-4fea-8f89-dce0cbcac446",
                                    "type": "RECTANGLE",
                                    "name": "Background",
                                    "x": "0",
                                    "y": "0",
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1921,
                                    "height": 1189,
                                    "alpha": 1,
                                    "graphicsData": {
                                        "x": 0,
                                        "y": 0,
                                        "width": 1921,
                                        "height": 1189,
                                        "radius": 0,
                                        "type": 4
                                    },
                                    "fillColor": 14355009,
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": false
                                },
                                {
                                    "children": [],
                                    "uuid": "bb681416-7e05-480f-84a5-285c14f9137d",
                                    "type": "IMAGE",
                                    "name": "REFERENCE",
                                    "tint": 16777215,
                                    "x": "0",
                                    "y": "0",
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1920,
                                    "height": 1080,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchor": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "blendMode": 0,
                                    "mask": null,
                                    "visible": false,
                                    "src": "MatchInfoL3.png"
                                },
                                {
                                    "children": [
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "adbc9fa6-1971-4fe1-a124-bac26d321c9c",
                                                    "type": "RECTANGLE",
                                                    "name": "DarkBlue_Bar",
                                                    "x": 972,
                                                    "y": 836,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 224,
                                                    "height": 43,
                                                    "alpha": 1,
                                                    "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}\n// Executed when graphic\n// updated each frame\nconst padding = 40; \nconst text_node_name = 'TopTEXT'\n\nfunction update(item, frame, scene) { \nlet text = hyperAPI.getItemByName(scene.name, text_node_name); \nitem.width = (text.x - item.x) + text.width + padding; }",
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 253,
                                                        "height": 43,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 10334,
                                                    "maxWidth": 0,
                                                    "anchorX": 0.5,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.8853754940711462,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "738fbb2b-80cc-4566-af44-b6553df7fce2",
                                                    "type": "TEXT",
                                                    "name": "TopTEXT",
                                                    "x": 965,
                                                    "y": 843,
                                                    "control": {
                                                        "id": "6",
                                                        "name": "TopText",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Versus",
                                                                    "field": "Competition"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 191,
                                                    "height": 26,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": ".5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9947916666666666,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 60,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#ffffff",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "Gotham-Bold.otf",
                                                        "_fontSize": 24,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0,
                                                        "original_size": 24
                                                    },
                                                    "text": "Premier League",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                }
                                            ],
                                            "uuid": "d19a4852-4e24-4ac3-a0b8-c2ae6f8850ec",
                                            "type": "GROUP",
                                            "name": "TopTab GRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 235.88329288825685,
                                            "height": 41.32538384736313,
                                            "alpha": 1,
                                            "code": "// Executed when graphic loaded\nfunction loaded() {\n\n}\n\n// Executed when graphic\n// updated each frame\nfunction update(frame) {\n  \n}",
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": "9ddff7d1-eb40-4d72-856d-4421ed0a195d",
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "0c1c41a0-e90f-4ee6-b746-5738e21e849b",
                                            "type": "IMAGE",
                                            "name": "Stroke",
                                            "tint": 16777215,
                                            "x": 1360.5527377420656,
                                            "y": 889,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 207,
                                            "height": 78,
                                            "alpha": "0.8",
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "blendMode": 0,
                                            "mask": null,
                                            "visible": true,
                                            "src": "L3Stroke3.png"
                                        },
                                        {
                                            "children": [],
                                            "uuid": "af7d68fe-572c-4e1f-8058-2986005852ef",
                                            "type": "IMAGE",
                                            "name": "Grad_Bar",
                                            "tint": 16777215,
                                            "x": 361.0543918935834,
                                            "y": 895,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 1186,
                                            "height": 77,
                                            "alpha": "1",
                                            "maxWidth": 0,
                                            "anchor": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "blendMode": 0,
                                            "mask": null,
                                            "visible": true,
                                            "src": "L3_Grad.png"
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "c6cbe865-f84b-4789-b8ee-9b0b72fb6e99",
                                                    "type": "RECTANGLE",
                                                    "name": "WhiteBG",
                                                    "x": 1450,
                                                    "y": 878,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 102,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 102,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "ef41d0ff-cb16-456f-a5b9-3433cc1c69ed",
                                                    "type": "IMAGE",
                                                    "name": "Away Badge",
                                                    "tint": 16777215,
                                                    "x": 1357,
                                                    "y": 855,
                                                    "control": {
                                                        "id": "5",
                                                        "name": "Away Badge",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Versus",
                                                                    "field": "Away Team Badge"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 222,
                                                    "height": 222,
                                                    "alpha": 1,
                                                    "rotation": "-0.27",
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.33134328358208953,
                                                        "y": 0.33134328358208953
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "src": "CREST_ManUTD.png"
                                                }
                                            ],
                                            "uuid": "ed5c39a3-d2c2-4666-88bb-d2a838a3d415",
                                            "type": "GROUP",
                                            "name": "AwayBadge_GRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 273.1715087890625,
                                            "height": 273.1715087890625,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": "a2827d9e-46ce-445a-b993-e2bc4d06d0a6",
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "ca1512c9-1173-4c3d-b9b0-0e84d8e66adb",
                                                    "type": "RECTANGLE",
                                                    "name": "WhiteBG",
                                                    "x": "390",
                                                    "y": 878,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 102,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 102,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "ce60f33e-bd0b-4b5b-ab01-23b8facc59cf",
                                                    "type": "IMAGE",
                                                    "name": "Home Badge",
                                                    "tint": 16777215,
                                                    "x": 315,
                                                    "y": 852,
                                                    "control": {
                                                        "id": "4",
                                                        "name": "Home Badge",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Versus",
                                                                    "field": "Home Team Badge"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 199.99999999999997,
                                                    "height": 199.99999999999997,
                                                    "alpha": 1,
                                                    "rotation": "-0.27",
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.29850746268656714,
                                                        "y": 0.29850746268656714
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "src": "Manchester_City.png"
                                                }
                                            ],
                                            "uuid": "9f62c58d-cb22-4ca2-9921-50c321d2cd07",
                                            "type": "GROUP",
                                            "name": "HomeBadge_GRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 246.1004638671875,
                                            "height": 246.1004638671875,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": "8c31881b-8aab-436b-8c9e-31d36903e883",
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "8c31881b-8aab-436b-8c9e-31d36903e883",
                                            "type": "RECTANGLE",
                                            "name": "Logo_ChipL_MASK",
                                            "x": 492,
                                            "y": 878,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 102,
                                            "height": 80,
                                            "alpha": 1,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 102,
                                                "height": 80,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 16777215,
                                            "maxWidth": 0,
                                            "anchorX": 1,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.28,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "a2827d9e-46ce-445a-b993-e2bc4d06d0a6",
                                            "type": "RECTANGLE",
                                            "name": "Logo_ChipR_MASK",
                                            "x": 1450,
                                            "y": 878,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 102,
                                            "height": 80,
                                            "alpha": 1,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 102,
                                                "height": 80,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 16777215,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.28,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "9ddff7d1-eb40-4d72-856d-4421ed0a195d",
                                            "type": "RECTANGLE",
                                            "name": "TopTabMASK",
                                            "x": 521,
                                            "y": 819,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 878,
                                            "height": 60,
                                            "alpha": 1,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 878,
                                                "height": 60,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 14355009,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [],
                                            "uuid": "86435b68-6113-4ef1-b44d-e4e671d14009",
                                            "type": "RECTANGLE",
                                            "name": "LightBlue_Bar",
                                            "x": 492,
                                            "y": 878,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 958,
                                            "height": 80,
                                            "alpha": 1,
                                            "graphicsData": {
                                                "x": 0,
                                                "y": 0,
                                                "width": 958,
                                                "height": 80,
                                                "radius": 0,
                                                "type": 4
                                            },
                                            "fillColor": 7122399,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": -0.28,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "5a320a0e-fca7-4910-bd3e-ff332597fc16",
                                                    "type": "RECTANGLE",
                                                    "name": "White_BG",
                                                    "x": 905,
                                                    "y": 878,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 127,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 127,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 16777215,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "113df672-0131-4af0-9756-e6c0c11b8ce9",
                                                    "type": "TEXT",
                                                    "name": "Home Score",
                                                    "x": 929,
                                                    "y": 894,
                                                    "control": {
                                                        "id": "3",
                                                        "name": "Home Score Text",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Versus",
                                                                    "field": "Home Score"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 21,
                                                    "height": 42,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": ".5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9545454545454546,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": false,
                                                    "style": {
                                                        "styleID": 107,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#00285e",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Bold.otf",
                                                        "_fontSize": 40,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0,
                                                        "original_size": 40
                                                    },
                                                    "text": "2",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "a9423443-b960-4016-8c74-af64507db236",
                                                    "type": "TEXT",
                                                    "name": "Away Score",
                                                    "x": 990,
                                                    "y": 894,
                                                    "control": {
                                                        "id": "4",
                                                        "name": "Away Score Text",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Versus",
                                                                    "field": "Home Score"
                                                                },
                                                                {
                                                                    "template": "Versus",
                                                                    "field": "Away Score"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 25,
                                                    "height": 42,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": ".5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9615384615384616,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": false,
                                                    "style": {
                                                        "styleID": 107,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#00285e",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Bold.otf",
                                                        "_fontSize": 40,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0,
                                                        "original_size": 40
                                                    },
                                                    "text": "0",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "b89b2d1b-f995-4f8b-a8b1-fa9a943c5b13",
                                                    "type": "TEXT",
                                                    "name": "ScoreDot",
                                                    "x": 960,
                                                    "y": 894,
                                                    "control": {
                                                        "id": "5",
                                                        "name": "ScoreDot/Versus"
                                                    },
                                                    "width": 25,
                                                    "height": 42,
                                                    "alpha": 1,
                                                    "maxWidth": 0,
                                                    "anchor": {
                                                        "x": ".5",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9615384615384616,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": null,
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 106,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#00285e",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Medium.otf",
                                                        "_fontSize": 40,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0
                                                    },
                                                    "text": "V",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "34187d8f-13cb-4eb1-b75f-93ec63044393",
                                                    "type": "RECTANGLE",
                                                    "name": "BlueWIPE",
                                                    "x": 774,
                                                    "y": 878,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 127,
                                                    "height": 80,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 127,
                                                        "height": 80,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 10334,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": -0.28,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                }
                                            ],
                                            "uuid": "175a2b85-ac05-4085-9b58-b100a573ffce",
                                            "type": "GROUP",
                                            "name": "Score_GRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 280.10845188512906,
                                            "height": 76.88443506486169,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": "75a22d85-b516-4f6d-a8c8-fa61b5d79649",
                                            "visible": true
                                        },
                                        {
                                            "children": [
                                                {
                                                    "children": [],
                                                    "uuid": "e7cc1160-af45-468a-b896-fb4de06f6869",
                                                    "type": "TEXT",
                                                    "name": "Home Team",
                                                    "x": 886,
                                                    "y": 895,
                                                    "control": {
                                                        "id": "1",
                                                        "name": "HomeTeamName",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Versus",
                                                                    "field": "Home Team Name"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 266,
                                                    "height": 41,
                                                    "alpha": 1,
                                                    "maxWidth": "394",
                                                    "anchor": {
                                                        "x": "1",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9962546816479401,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "937e5cba-eed9-41c3-a1d6-9a01e1916cf6",
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 69,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#ffffff",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Medium.otf",
                                                        "_fontSize": 38,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0,
                                                        "original_size": 38
                                                    },
                                                    "text": "Manchester City",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "937e5cba-eed9-41c3-a1d6-9a01e1916cf6",
                                                    "type": "RECTANGLE",
                                                    "name": "HomeTeamMASK",
                                                    "x": 481,
                                                    "y": 888,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 420,
                                                    "height": 60,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 420,
                                                        "height": 60,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 14355009,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "668c8d56-1229-4fdc-867c-bdc8281fce3c",
                                                    "type": "TEXT",
                                                    "name": "Away Team",
                                                    "x": 1035,
                                                    "y": 895,
                                                    "control": {
                                                        "id": "2",
                                                        "name": "AwayTeamName",
                                                        "data": {
                                                            "variables": [
                                                                {
                                                                    "template": "Versus",
                                                                    "field": "Away Team Name"
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    "width": 290,
                                                    "height": 41,
                                                    "alpha": 1,
                                                    "maxWidth": "394",
                                                    "anchor": {
                                                        "x": "0",
                                                        "y": 0
                                                    },
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 0.9965635738831615,
                                                        "y": 1
                                                    },
                                                    "blendMode": 0,
                                                    "mask": "a3149530-12fa-4a40-8ef0-0c13320caf95",
                                                    "visible": true,
                                                    "style": {
                                                        "styleID": 72,
                                                        "_align": "left",
                                                        "_breakWords": false,
                                                        "_dropShadow": false,
                                                        "_dropShadowAlpha": 1,
                                                        "_dropShadowAngle": 0.5235987755982988,
                                                        "_dropShadowBlur": 0,
                                                        "_dropShadowColor": "black",
                                                        "_dropShadowDistance": 5,
                                                        "_fill": "#ffffff",
                                                        "_fillGradientType": 0,
                                                        "_fillGradientStops": [],
                                                        "_fontFamily": "GothamNarrow-Medium.otf",
                                                        "_fontSize": 38,
                                                        "_fontStyle": "normal",
                                                        "_fontVariant": "normal",
                                                        "_fontWeight": "normal",
                                                        "_letterSpacing": 0,
                                                        "_lineHeight": 0,
                                                        "_lineJoin": "miter",
                                                        "_miterLimit": 10,
                                                        "_padding": 5,
                                                        "_stroke": "black",
                                                        "_strokeThickness": 0,
                                                        "_textBaseline": "alphabetic",
                                                        "_trim": false,
                                                        "_whiteSpace": "pre",
                                                        "_wordWrap": false,
                                                        "_wordWrapWidth": 100,
                                                        "_leading": 0,
                                                        "original_size": 38
                                                    },
                                                    "text": "West Ham United",
                                                    "text_transform": {
                                                        "uppercase": false
                                                    },
                                                    "maxHeight": 0
                                                },
                                                {
                                                    "children": [],
                                                    "uuid": "a3149530-12fa-4a40-8ef0-0c13320caf95",
                                                    "type": "RECTANGLE",
                                                    "name": "AwayTeamMASK",
                                                    "x": 1023,
                                                    "y": 888,
                                                    "control": {
                                                        "id": 0,
                                                        "name": ""
                                                    },
                                                    "width": 420,
                                                    "height": 60,
                                                    "alpha": 1,
                                                    "graphicsData": {
                                                        "x": 0,
                                                        "y": 0,
                                                        "width": 420,
                                                        "height": 60,
                                                        "radius": 0,
                                                        "type": 4
                                                    },
                                                    "fillColor": 14355009,
                                                    "maxWidth": 0,
                                                    "anchorX": 0,
                                                    "anchorY": 0,
                                                    "skew": {
                                                        "x": 0,
                                                        "y": 0
                                                    },
                                                    "scale": {
                                                        "x": 1,
                                                        "y": 1
                                                    },
                                                    "mask": null,
                                                    "visible": true
                                                }
                                            ],
                                            "uuid": "c7370d80-7884-42dc-91e4-9d719fc114de",
                                            "type": "GROUP",
                                            "name": "Text_GRP",
                                            "x": 0,
                                            "y": 0,
                                            "control": {
                                                "id": 0,
                                                "name": ""
                                            },
                                            "width": 714.9640502929688,
                                            "height": 51,
                                            "alpha": 1,
                                            "maxWidth": 0,
                                            "anchorX": 0,
                                            "anchorY": 0,
                                            "skew": {
                                                "x": 0,
                                                "y": 0
                                            },
                                            "scale": {
                                                "x": 1,
                                                "y": 1
                                            },
                                            "mask": null,
                                            "visible": true
                                        }
                                    ],
                                    "uuid": "938f89bb-e7fa-4ae0-abd8-9d7b10f79eec",
                                    "type": "GROUP",
                                    "name": "MainBar GRP",
                                    "x": 0,
                                    "y": 0,
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 1206.4983520507812,
                                    "height": 136,
                                    "alpha": 1,
                                    "maxWidth": 0,
                                    "anchorX": 0,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": 0,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": true
                                },
                                {
                                    "children": [],
                                    "uuid": "75a22d85-b516-4f6d-a8c8-fa61b5d79649",
                                    "type": "RECTANGLE",
                                    "name": "ScoreMask",
                                    "x": 968,
                                    "y": 878,
                                    "control": {
                                        "id": 0,
                                        "name": ""
                                    },
                                    "width": 128,
                                    "height": 80,
                                    "alpha": 1,
                                    "graphicsData": {
                                        "x": 0,
                                        "y": 0,
                                        "width": 128,
                                        "height": 80,
                                        "radius": 0,
                                        "type": 4
                                    },
                                    "fillColor": 8311585,
                                    "maxWidth": 0,
                                    "anchorX": 0.5,
                                    "anchorY": 0,
                                    "skew": {
                                        "x": -0.28,
                                        "y": 0
                                    },
                                    "scale": {
                                        "x": 1,
                                        "y": 1
                                    },
                                    "mask": null,
                                    "visible": true
                                }
                            ],
                            "uuid": "cde7635a-7f8b-4863-b83d-6c7f5c221095",
                            "type": "GROUP",
                            "name": "Root",
                            "x": 0,
                            "y": 0,
                            "control": {
                                "id": 0,
                                "name": ""
                            },
                            "width": 1206.4983520507812,
                            "height": 136,
                            "alpha": 1,
                            "maxWidth": 0,
                            "anchorX": 0,
                            "anchorY": 0,
                            "skew": {
                                "x": 0,
                                "y": 0
                            },
                            "scale": {
                                "x": 1,
                                "y": 1
                            },
                            "mask": null,
                            "visible": true
                        }
                    ],
                    "uuid": "dfe2fe9e-26a2-4264-8e83-4ed0edeeb980",
                    "type": "SCENE",
                    "name": "Root",
                    "x": 0,
                    "y": 0,
                    "control": {
                        "id": 0,
                        "name": ""
                    },
                    "alpha": 1,
                    "maxWidth": 0,
                    "skew": {
                        "x": 0,
                        "y": 0
                    },
                    "scale": {
                        "x": 1,
                        "y": 1
                    },
                    "mask": null,
                    "visible": true
                }
            },
            "project": "6182817751b7a0dd4843297a",
            "__v": 0
        }
    ]
}

export default data;