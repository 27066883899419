import React from 'react';
import styled from 'styled-components';
const Main = styled.div`
  display: flex;
  background-color: #fff;
  box-shadow: 0 2px 0 rgb(90 97 105 / 11%), 0 4px 8px rgb(90 97 105 / 12%),
    0 10px 10px rgb(90 97 105 / 6%), 0 7px 70px rgb(90 97 105 / 10%);
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  min-height: 1px;
  background-color: #fff;
  border: none;
  border-radius: 0.625rem;
  flex-direction: column;
  margin-bottom: 1.5rem !important;
`;

const Title = styled.div`
  border-radius: 0.625rem 0.625rem 0 0;
  padding: 1rem;
  border-bottom: ${(props) => (props.border_title ? '1px solid #e1e5eb' : '')};
`;

const Body = styled.div`
  display: flex;
  padding-bottom: 1rem;
`;
function Panel({ title, border_title, children }) {
  return (
    <Main>
      <Title border_title={border_title}>
        <h6>{title}</h6>
      </Title>
      <Body>{children}</Body>
    </Main>
  );
}

export default Panel;
